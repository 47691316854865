import React from 'react';
import Icon from './../../../../../../generals/svgiconos/svgiconosenreact.jsx';
import "./Resultados.css";
import ResultadosTable from './ResultadosTable.jsx'

const ResultadosModal = ({ mostrarResultados , mostrarUltimasSeis, ultimasSeis, carrerasGanadas, carrerasSecundon, totalCarreras, t }) => {
    return (
        <div className="races_runs-GanadasTotal">
            <ResultadosTable
                carrerasGanadas={carrerasGanadas}
                carrerasSecundon={carrerasSecundon}
                totalCarreras={totalCarreras}
                t={t}
                mostrarResultados={mostrarResultados}
            />

        </div>
    );
};

export default ResultadosModal;
