import React, { useEffect,useState } from 'react';

import ballo11 from'../../../../assets/ballo11.png'
import ballo12 from'../../../../assets/ballo12.png'
import ballo13 from'../../../../assets/ballo13.png'
import ballo14 from'../../../../assets/ballo14.png'
import ballo15 from'../../../../assets/ballo15.png'
import ballo16 from'../../../../assets/ballo16.png'
import ballo17 from'../../../../assets/ballo17.png'
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';
import './presales.css'
import Especial from './ventaespecial/ventaspecial.jsx';


function Presale() {

   const [equinosPoblacion, setEquinosPoblacion] = useState({});
   const [equinosPoblacionL, setEquinosPoblacionL] = useState({});
   const [equinosPoblacionM, setEquinosPoblacionM] = useState({});
   const [equinosPoblacionE, setEquinosPoblacionE] = useState({});
   const [equinosPoblacionS, setEquinosPoblacionS] = useState({});
   const [equinosPoblacionR, setEquinosPoblacionR] = useState({});
   const [equinosPoblacionU, setEquinosPoblacionU] = useState({});
   const [equinosPoblacionC, setEquinosPoblacionC] = useState({});
  

  useEffect(() => {
    const fetchEquinos = async () => {
      try {
        const response = await fetch(`${linkApp}/poblacion`);
        const equinos = await response.json();
        console.log("equinos", equinos);
        
        // Actualiza el estado con los datos obtenidos
        setEquinosPoblacion(equinos);
        processEquinosPoblacion(equinos)
      } catch (error) {
        console.error('Error fetching equinos:', error);
      }
    };

    fetchEquinos();
  }, []); // El segundo parámetro (una matriz vacía) indica que este efecto se ejecutará solo una vez al montar el componente.
 



console.log("equinosPoblacion",equinosPoblacion)



function processEquinosPoblacion(equinosPoblacion) {
   const legendaryEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Legendary");
   const mythicEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Mythic");
   const epicEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Epic");
   const spectralEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Spectral");
   const rareEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Rare");
   const uncommonEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Uncommon");
   const commonEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Common");
   // ... Repite para otros tipos de caballo

    setEquinosPoblacionL(legendaryEquinos);
     setEquinosPoblacionM(mythicEquinos);
      setEquinosPoblacionE(epicEquinos);
       setEquinosPoblacionS(spectralEquinos);
        setEquinosPoblacionR(rareEquinos);
         setEquinosPoblacionU(uncommonEquinos);
          setEquinosPoblacionC(commonEquinos);

    const poblacionfL=legendaryEquinos[0].cantidad
    const poblacionmL=legendaryEquinos[1].cantidad
   console.log("equinosPoblacionL", legendaryEquinos);
   console.log("equinosPoblacionL", legendaryEquinos[0].cantidad);
   console.log("equinosPoblacionL", legendaryEquinos[1].cantidad);
   console.log("equinosPoblacionM", mythicEquinos);
   console.log("equinosPoblacionE", epicEquinos);
}

 //   const legendaryEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Legendary");
 //   const mythicEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Mythic");
 //   const epicEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Epic");
 //   // ... Repite para otros tipos de caballo

 // console.log("equinosPoblacionL",legendaryEquinos)
 // console.log("equinosPoblacionL",legendaryEquinos[0].cantidad)
 // console.log("equinosPoblacionL",legendaryEquinos[1].cantidad)
 // console.log("equinosPoblacionM",mythicEquinos)
 // console.log("equinosPoblacionE",epicEquinos)


 return (

    <div className="wrappPresale">
    <Especial/>
  <div className="tableroPresale"> 

   {equinosPoblacionL.length > 0 && (
      <Card
        img={ballo11}
        className="cardPs__image"
        title="Legendary Horse"
        description="Legendary Horse"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="SWAP.HIVE,HIVE,HBD,HUESO,SPS,DEC,STARBITS,CROPS"
        amount="125.000"
        item_name="Legendary Equine"
        item_image="https://ipfs.io/ipfs/bafkreibhbtvsudsfakujuujrjatvcopmkmandcpsujiikdr3wrxu7cerpq"
        quantity="1"
        poblacionF={equinosPoblacionL[0].cantidad}
        poblacionM={equinosPoblacionL[1].cantidad}
        total="208"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
          />
      )}

    {equinosPoblacionM.length > 0 && (
      <Card
        img={ballo12}
        className="nidea"
        title="Mythic"
        description="Mythic Horse"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="SWAP.HIVE,HIVE,HBD,HUESO,SPS,DEC,STARBITS,,CROPS"
        amount="90.000"
        item_name="Mythic Horse"
        item_image="https://ipfs.io/ipfs/bafkreibhbtvsudsfakujuujrjatvcopmkmandcpsujiikdr3wrxu7cerpq"
        quantity="1"
        poblacionF={equinosPoblacionM[0].cantidad}
        poblacionM={equinosPoblacionM[1].cantidad}
        total="384"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
         
      />
)}
   {equinosPoblacionE.length > 0 && (
      <Card
        img={ballo13}
        className="cardPs__image"
        title="Epic"
        description="Epic Horse"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="SWAP.HIVE,HIVE,HBD,HUESO,SPS,DEC,STARBITS"
        amount="75.000"
        item_name="Epic Equine"
        item_image="https://ipfs.io/ipfs/bafkreibhbtvsudsfakujuujrjatvcopmkmandcpsujiikdr3wrxu7cerpq"
        quantity="1"
        poblacionF={equinosPoblacionE[0].cantidad}
        poblacionM={equinosPoblacionE[1].cantidad}
        total="640"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
         
      />
)}
   {equinosPoblacionS.length > 0 && (
      <Card
        img={ballo14}
        className="cardPs__image"
        title="Spectral Horse"
        description="Spectral Horse"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="SWAP.HIVE,HIVE,HBD,SPS,DEC"
        amount="40.000"
        item_name="Spectral Equine"
        item_image="https://ipfs.io/ipfs/bafkreibhbtvsudsfakujuujrjatvcopmkmandcpsujiikdr3wrxu7cerpq"
        quantity="1"
        poblacionF={equinosPoblacionS[0].cantidad}
        poblacionM={equinosPoblacionS[1].cantidad}
        total="960"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
         
      />

)}

   {equinosPoblacionR.length > 0 && (
          <Card
        img={ballo15}
        className="cardPs__image"
        title="Rare Horse"
        description="Rare"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="SWAP.HIVE,HIVE,HBD,HUESO,SPS,DEC,STARBITS,WOO,CROPS"
        amount="25.000"
        item_name="Rare Horse"
        item_image="https://ipfs.io/ipfs/bafkreibhbtvsudsfakujuujrjatvcopmkmandcpsujiikdr3wrxu7cerpq"
        quantity="1"
        poblacionF={equinosPoblacionR[0].cantidad}
        poblacionM={equinosPoblacionR[1].cantidad}
        total="960"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
         
      />
)}
   {equinosPoblacionU.length > 0 && (
          <Card
        img={ballo16}
        className="cardPs__image"
        title="Uncommon Horse"
        description="Uncommon Horse"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="SWAP.HIVE,HIVE,HBD,HUESO,SPS,DEC,CROPS"
        amount="15.000"
        item_name="UnCommon Horse"
        item_image="https://ipfs.io/ipfs/bafkreibhbtvsudsfakujuujrjatvcopmkmandcpsujiikdr3wrxu7cerpq"
        quantity="1"
        poblacionF={equinosPoblacionU[0].cantidad}
        poblacionM={equinosPoblacionU[1].cantidad}
        total="1200"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
         
      />
)}
   {equinosPoblacionC.length > 0 && (
          <Card
        img={ballo17}
        className="cardPs__image"
        title="Common Horse"
        description="Common Horse"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="SWAP.HIVE,HIVE,HBD,BUDS,SOULS,HUESO,SPS,DEC,STARBITS,CROPS"
        amount="7.500"
        item_name="Common Horse"
        item_image="https://ipfs.io/ipfs/bafkreibhbtvsudsfakujuujrjatvcopmkmandcpsujiikdr3wrxu7cerpq"
        quantity="1"
        poblacionF={equinosPoblacionC[0].cantidad}
        poblacionM={equinosPoblacionC[1].cantidad}
        total="1200"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
         
      />
  )}  
      
   </div></div>
  );
}


function Card(props) {



    return (
     
 <div className="card">
 <div className="box">
 <div className="content">
 
 <img src={props.img} alt="Horses"  />
  
  <h5 style={{ color: '#222', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontSize: '1.4em',fonWweight: 'bold', backgroundColor: '#B8860B' }}>{props.description}</h5>


  <form method="post" action="https://hivepay.io/pay/">
  <input type="hidden" name="merchant" value="blockhorseracing"/>
  <input type="hidden" name="merchant_name" value="blockhorseracing"/>
  <input type="hidden" name="merchant_image" value={props.merchant_image}/>
  <input type="hidden" name="merchant_email" value="ihalangel@gmail.com"/>
  <input type="hidden" name="merchant_memo" value={props.merchant_memo}/>
  <input type="hidden" name="base_currency" value={props.base_currency}/>
  <input type="hidden" name="pay_currency" value={props.pay_currency}/>
  <input type="hidden" name="amount" value={props.amount}/>
  <input type="hidden" name="discount_token" value="SWAP.HIVE"/>
  <input type="hidden" name="discount_amount" value="10"/>
  <input type="hidden" name="item_name" value={props.item_name}/>
  <input type="hidden" name="item_image" value={props.item_image}/>
  <input type="hidden" name="item_tax" value={props.item_tax}/>
  <input type="hidden" name="quantity" value={props.quantity}/>
  <input type="hidden" name="description" value={props.description}/>
  <input type="hidden" name="notify_url"  value="https://www.blockhorseracing.com/payhive" />
  <input type="hidden" name="return_url"  value="https://www.blockhorseracing.com/"/>
  <input type="hidden" name="cancel_url"  value="https://www.blockhorseracing.com/"/>
  <input type="hidden" name="cancel_url_name" value="BHR"/>
  <input type="hidden" name="background"  value="medium"/>

<h4 style={{ color: '#2196f3', textShadow: '0.1em 0.1em 0.2em rgba(0, 0, 0, 0.5)', fontSize: '1em', backgroundColor: '#222' }}> ♀️ {props.poblacionF} / ♂️ {props.poblacionM}</h4>

 <h5 style={{ color: '#2196f3', textShadow: '0.1em 0.1em 0.2em rgba(0, 0, 0, 0.5)', fontSize: '1em', backgroundColor: '#222' }}>{props.poblacionF + props.poblacionM} / {props.total} </h5>

  <input type="submit" name="background"  value="BUY"/>
  </form>
    </div>
    </div>
    </div>
    
  );
}

export default Presale