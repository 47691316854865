import React, { createContext, useState } from 'react';

export const RaceScreenContext = createContext();

export const RaceScreenProvider = ({ children }) => {
  const [raceDetails, setRaceDetails] = useState({
    schedule: '', // Horario de salida
    totalRecords: 0, // Cantidad de registros
    // Otros detalles relacionados con las carreras...
  });

  const updateRaceDetails = (newDetails) => {
    setRaceDetails({ ...raceDetails, ...newDetails });
  };

  return (
    <RaceScreenContext.Provider value={{ raceDetails, updateRaceDetails }}>
      {children}
    </RaceScreenContext.Provider>
  );
};
