import React, { useEffect, useState } from "react";
import RacePositionTable from "./RacePositionTable"; // Importa el componente RacePositionTable
import startSound from "./../../../../../../../assets/music/race/comienzorace.mp4"; // Importa el sonido de inicio
import progressSound from "./../../../../../../../assets/music/race/galloping.mp3"; // Importa el sonido de progreso
import finishSound from "./../../../../../../../assets/music/race/final.mp3"; // Importa el sonido de finalización

// Importa lo necesario

const RaceLane = ({ horses, raceInProgress, raceResult, positions }) => {
  const [stage, setStage] = useState(-1);
  const [maxPosition, setMaxPosition] = useState(null);
  const [barriersMoving, setBarriersMoving] = useState(false); 
  const [audioMuted, setAudioMuted] = useState(false); 

  const [startAudio] = useState(new Audio(startSound));
  const [progressAudio] = useState(new Audio(progressSound));
  const [finishAudio] = useState(new Audio(finishSound));
  console.log("RESULTADO DEL jueGO",raceResult)

  useEffect(() => {
    const calculateMaxPosition = () => {
      let max = 0;
      for (let i = 0; i < positions.length; i++) {
        const maxInStage = Math.max(...positions[i]);
        if (maxInStage > max) {
          max = maxInStage;
        }
      }
      setMaxPosition(max);
    };

    calculateMaxPosition();

    if (raceInProgress) {
      setStage(-1); 
      setBarriersMoving(true); 
      startAudio.play(); 
      animateHorses();
    }
  }, [raceInProgress]);

  const animateHorses = () => {
    const interval = setInterval(() => {
      setStage((prevStage) => {
        if (prevStage < positions.length - 1) {
          if (positions[prevStage]) {
            for (let i = 0; i < positions[prevStage].length; i++) {
              if ((positions[prevStage][i] / maxPosition) > 0.5) {
                finishAudio.play(); 
                break; 
              }
            }
          }
          if (!audioMuted) progressAudio.play(); 
          return prevStage + 1;
        } else {
          clearInterval(interval);
          setBarriersMoving(false); 
          progressAudio.pause(); 
          finishAudio.play(); 
          return prevStage;
        }
      });
    }, 200); 

    return () => clearInterval(interval);
  };

  const getLaneColor = (position) => {
    if (maxPosition !== null && position === maxPosition) {
      return "transparent"; 
    }
    return "transparent"; 
  };

  // const toggleAudio = () => {
  //   if (audioMuted) {
  //     startAudio.play(); 
  //     progressAudio.play(); 
  //     finishAudio.play(); 
  //   } else {
  //     startAudio.src = null; 
  //     progressAudio.src = null; 
  //     finishAudio.src = null; 
  //   }
  //   setAudioMuted(!audioMuted); 
  // };


  const toggleAudio = () => {
  if (audioMuted) {
    startAudio.src = startSound;
    progressAudio.src = progressSound;
    finishAudio.src = finishSound;
     startAudio.play(); 
      progressAudio.play(); 
      finishAudio.play(); 
  } else {
    startAudio.src = null;
    progressAudio.src = null;
    finishAudio.src = null;
  }
  setAudioMuted(!audioMuted);
};

return (
  <div>
    <RacePositionTable horses={horses} positions={positions} currentStage={stage} maxPosition={maxPosition} />
    <table className="race-table">
      <tbody>
        <tr>
          <td className="race-lane">
            <div className={`race-lane-barriers ${barriersMoving ? "moving" : ""}`}></div>
            <div className={`race-lane-arbol-tronco ${barriersMoving ? "moving" : ""}`}></div>
            {raceInProgress && stage >= 0 && (
              horses.map((horse, index) => (
                <div
                  key={horse.name}
                  className={`horse-dot horse-${index + 1} ${
                    raceResult && raceResult.length > 0 && raceResult[0] === horse.name ? "winner" : ""
                  }`}
                  style={{
                    left: `${(positions[stage][index] / maxPosition) * 100}%`, 
                    backgroundImage: `url(./image/equinos/${horse.points}.png)`, 
                    backgroundSize: "cover", 
                    backgroundColor: getLaneColor(positions[stage][index]), 
                  }}
                ></div>
              ))
            )}
            <div className={`race-lane-arbol-copa ${barriersMoving ? "moving" : ""}`}></div>
          </td>
        </tr>  
      </tbody>
    </table>
    <a href="#" onClick={toggleAudio}>{audioMuted ? "🔊" : "🔇"}</a>
  </div>
);

};

export default RaceLane;


