import  React from 'react'
import{NavLink} from'react-router-dom'
import { useState } from "react"
// import Logo from './navbar/Logo.jsx';
import logos from'../../../assets/logoblockhorseracing/logoBlock.png'
import "./navbar.css"
import { useTranslation } from 'react-i18next';






  let activeStyle = {
    textDecoration: "underline",
  };

  // let activeClassName = "underline";






export default function Navbar(props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const { t } = useTranslation();
   //console.log(isNavExpanded)


  const closeMenu = () => {
    setIsNavExpanded(false);
  };


 
  return (         
   
    <nav className={[props.theme, "navigation"].join(' ')}>
<NavLink to="/"className="brand-name">
            
      </NavLink>
         <img src={logos} alt="Kings"  className="logo" />
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
            <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >

        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd" />
        </svg>
      </button>
      <div
       
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >




        <ul className={props.theme}>
  
<li>
  <NavLink end to="/" onClick={closeMenu} style={({ isActive }) =>
    isActive ? {...activeStyle, textDecoration: "none"} : {textDecoration: "none"}
  }>
    <button className="nav-button">
      <span className="nav-button-icon">🚀</span>
      {t('navbar.presale')}
    </button>
  </NavLink>
</li>

<li>
 <NavLink end to="/market" onClick={closeMenu} style={({ isActive }) =>
    isActive ? {...activeStyle, textDecoration: "none"} : {textDecoration: "none"}
  }>
    <button className="nav-button">
      <span className="nav-button-icon">🛒</span>
      {t('navbar.market')}
    </button>
  </NavLink>
</li>

<li>
  <NavLink end to="/races" onClick={closeMenu} style={({ isActive }) =>
    isActive ? {...activeStyle, textDecoration: "none"} : {textDecoration: "none"}
  }>
    <button className="nav-button">
      <span className="nav-button-icon">🏁</span>
      {t('navbar.resultados')}
    </button>
  </NavLink>
</li>

<li>
 <NavLink end to="/dashboard" onClick={closeMenu} style={({ isActive }) =>
    isActive ? {...activeStyle, textDecoration: "none"} : {textDecoration: "none"}
  }>
    <button className="nav-button">
      <span className="nav-button-icon">🖥️</span>
      {t('navbar.dashboard')}
    </button>
  </NavLink>
</li>
            
      {/*                 <li>
           <NavLink end to="/statistics" onClick={closeMenu} style={({ isActive }) =>
  isActive ? activeStyle : undefined
}>
  {t('navbar.statistics')}
</NavLink>
          </li>*/}
               

{/*                 <li>
           <NavLink end to="/market"  onClick={closeMenu} style={({ isActive }) =>
  isActive ? activeStyle : undefined
}>
    {t('navbar.market')}
</NavLink>
        </li>

                        <li>
           <NavLink end to="/races"  onClick={closeMenu} style={({ isActive }) =>
  isActive ? activeStyle : undefined
}>
  {t('navbar.resultados')}
</NavLink>
        </li>


          <li>
            <NavLink end  to="/dashboard"  onClick={closeMenu} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>{t('navbar.dashboard')}</NavLink>
          </li>*/}
          

<li>
 
    <button className="nav-button">
     <a href="https://discord.gg/d4QVKQG7Kp"  target="_blank"  rel="noreferrer" >   
      <svg xmlns="http://www.w3.org/2000/svg" className="icon-l-em icon-tabler icon-tabler-brand-discord-filled"  viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M14.983 3l.123 .006c2.014 .214 3.527 .672 4.966 1.673a1 1 0 0 1 .371 .488c1.876 5.315 2.373 9.987 1.451 12.28c-1.003 2.005 -2.606 3.553 -4.394 3.553c-.94 0 -2.257 -1.596 -2.777 -2.969l-.02 .005c.838 -.131 1.69 -.323 2.572 -.574a1 1 0 1 0 -.55 -1.924c-3.32 .95 -6.13 .95 -9.45 0a1 1 0 0 0 -.55 1.924c.725 .207 1.431 .373 2.126 .499l.444 .074c-.477 1.37 -1.695 2.965 -2.627 2.965c-1.743 0 -3.276 -1.555 -4.267 -3.644c-.841 -2.206 -.369 -6.868 1.414 -12.174a1 1 0 0 1 .358 -.49c1.392 -1.016 2.807 -1.475 4.717 -1.685a1 1 0 0 1 .938 .435l.063 .107l.652 1.288l.16 -.019c.877 -.09 1.718 -.09 2.595 0l.158 .019l.65 -1.287a1 1 0 0 1 .754 -.54l.123 -.01zm-5.983 6a2 2 0 0 0 -1.977 1.697l-.018 .154l-.005 .149l.005 .15a2 2 0 1 0 1.995 -2.15zm6 0a2 2 0 0 0 -1.977 1.697l-.018 .154l-.005 .149l.005 .15a2 2 0 1 0 1.995 -2.15z" strokeWidth="0" fill="currentColor"></path>
</svg>
        </a>
    </button>
 


            <button className="nav-button">
              <a href="https://docs-en.blockhorseracing.com/"  target="_blank"  rel="noreferrer" >  
     
         <svg xmlns="http://www.w3.org/2000/svg" className="icon-l-em icon-tabler icon-tabler-file-text" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
   <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
   <path d="M9 9l1 0"></path>
   <path d="M9 13l6 0"></path>
   <path d="M9 17l6 0"></path>
</svg>
   </a>
  </button>


</li>

     
         
            
        </ul>
      </div>
    </nav>
     
  );
}