import React, { useState, useEffect } from 'react';
import { RaceScreenProvider } from './components/RaceScreenContext';
import { linkApp } from '../../../../../generals/configuracion/variablesPublicas.jsx';
import './racescreen.css';
import hivelogo from './../../../../../../assets/logoblockhorseracing/logoHive.png';
import logoBHR from './../../../../../../assets/logoblockhorseracing/logo2ra2s.png';
import gift_deposita  from './../../../../../../assets/gif/instructivo/depositarWalletIngles.gif';
import gift_depositaEspa  from './../../../../../../assets/gif/instructivo/depositarWalletEspa.gif';

import banner1 from "./../../../../../../assets/banner/pantalla_izq/banner1iG.png";
import raven from "./../../../../../../assets/banner/pantalla_izq/raven1.gif";
import nftgamela from"./../../../../../../assets/banner/pantalla_izq/nftgamela.gif";
import banner_wellcome from "./../../../../../../assets/banner/pantalla_izq/wellcome.gif";
import banner3 from "./../../../../../../assets/banner/dashboard/banner1.png";
import hive_games from"./../../../../../../assets/banner/pantalla_izq/Game_Hive_Mundo.gif";
import bannerNS from"./../../../../../../assets/banner/pantalla_izq/banner-NS.jpg";


import { useTranslation } from 'react-i18next';
       

const RaceScreen = () => {
  const [races, setRaces] = useState([]);
  const [patrocinio, setPatrocinio] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselData, setCarouselData] = useState([]);
  const [registradosCarouselIndex, setRegistradosCarouselIndex] = useState(0);
  const [imagenRegistradoSrc,setImagenRegistradoSrc]=useState(null)
  const [fraseAleatoria, setFraseAleatoria] = useState('');
  // const [imagenAleatoria, setImagenAleatoria] = useState('');
   const [imagenIndex, setImagenIndex] = useState(0);
  const { t } = useTranslation();
  

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        const response = await fetch(`${linkApp}/race`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ status: 'create' }),
        });

        const data = await response.json();
        const racing = data.body;
        const objetosFiltrados = racing.filter(objeto => objeto.registrados.length > 1);
        setRaces(objetosFiltrados);

        const raceIds = objetosFiltrados.map(objeto => objeto.raceid);
        try {
          const raceIdParams = raceIds.join(',');
          const patrocinioResponse = await fetch(`${linkApp}/bets?race=${raceIdParams}`);
          const dataso = await patrocinioResponse.json();
          
     // console.log("PATROCINIO",dataso.body)
          setPatrocinio(dataso.body);
        } catch (error) {
          console.error('Error al obtener información adicional para las carreras:', error);
        }
      } catch (error) {
        console.error('Error al obtener las carreras:', error);
      }
    };

    fetchRaces();
  }, []);



 useEffect(() => {
  const combinedArray = races.map((race) => {
    const matchingPatrocinio = Array.isArray(patrocinio) ? patrocinio.find((item) => item.race === race.raceid) : null;


    return {
      ...race,
      patrocinioData: matchingPatrocinio,
    };
  });

  setCombinedData(combinedArray);
    setCarouselData(combinedArray);
//  console.log("ARRAY COMBINADOS",combinedArray)
}, [races, patrocinio]);


 useEffect(() => {
    // Inicializa el temporizador para cambiar los datos del carrusel cada 3 segundos (3000 ms)
    const interval = setInterval(() => {
      setCarouselIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    }, 18000);

    return () => clearInterval(interval); // Limpia el temporizador al desmontar el componente
  }, [carouselData]);


useEffect(() => {
  const interval = setInterval(() => {
    setRegistradosCarouselIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex >= carouselData[carouselIndex]?.registrados.length ? 0 : nextIndex;
    });
  }, 3000);

  return () => clearInterval(interval);
}, [carouselData, carouselIndex]);




useEffect(() => {
  const checkImageExistence = () => {
    const imagenRegistrado = `./image/equinos/${carouselData[carouselIndex]?.registrados[registradosCarouselIndex]}.png`;
   const imagenPredeterminada=`./image/equinos/000.png`;

    const img = new Image();
    img.onload = () => {
      // Si la carga de la imagen es exitosa, establece la imagen como src
      setImagenRegistradoSrc(imagenRegistrado);
    };
    img.onerror = () => {
      // console.error('Error al cargar la imagen');
      // Si hay un error al cargar la imagen, establece la imagen predeterminada
      setImagenRegistradoSrc(imagenPredeterminada);
    };
    img.src = imagenRegistrado;
  };

  checkImageExistence();
}, [carouselData, carouselIndex, registradosCarouselIndex]);


// Array con varias frases predeterminadas
const frasesPredeterminadas = [
  'Gracias por tu apoyo', 'Thanks for your support',
  'La propiedad de un caballo de carrera en tus manos',  'Racehorse ownership in your hands',
  'BHR mejora tus probabilidades estadísticas',  'BHR improves your statistical odds',
  'La emoción de las carreras',  'The thrill of the races',
  'Clicks en las ? amarilla para ampliar el punto',
  'Eres el proximo en ganar mas de 30X en el sistema de patrocinio?',
  'Patrocina y gana como nunca lo habías experimentado',  'Sponsor and win like you ve never experienced before',
  'Patrocina un Nft y recoge beneficio si este llega dentro de los primeros 4 puestos',
  
  
];

const imagenesPredeterminadas = [
   gift_deposita,
   gift_deposita,
     raven,
   hive_games,
   nftgamela,
   gift_depositaEspa,
   gift_depositaEspa,
   banner1,
   bannerNS,
   banner3,
   banner_wellcome,
  
  
];




 useEffect(() => {
    const cambiarFrase = () => {
      const indiceAleatorio = Math.floor(Math.random() * frasesPredeterminadas.length);
      setFraseAleatoria(frasesPredeterminadas[indiceAleatorio]);
    };

    const cambiarImagen = () => {
      setImagenIndex((prevIndex) => (prevIndex + 1) % imagenesPredeterminadas.length);
    };

    cambiarFrase();
    cambiarImagen();

    const fraseInterval = setInterval(cambiarFrase, 5000);
    const imagenInterval = setInterval(cambiarImagen, 15000);

    // Limpia los intervalos cuando el componente se desmonta
    return () => {
      clearInterval(fraseInterval);
      clearInterval(imagenInterval);
    };
  }, []);

  const imagenAleatoria = imagenesPredeterminadas[imagenIndex];





return (
  <RaceScreenProvider>
    <div className="advertising-screens">
   
     {/* Pantalla izquierda */}
      <div className={`screen left-screen`}>
        <div className="neon-border-pixel-left">
          {imagenAleatoria.endsWith('.gif') ? (
            <div className="gif-container">
              <img
                src={imagenAleatoria}
                alt="Token"
                className="banner-pantalla-izquierda"
              />
            </div>
          ) : (
            
              <img
                src={imagenAleatoria}
                alt="Token"
                className="banner-pantalla-izquierda"
              />
          )}
        </div>
      </div>




   


      {/* Pantalla central */}
      <div className={`screen center-screen`} key={carouselData[carouselIndex]?.raceid} >
    
 
        {/* Mostrar los datos del carrusel en la pantalla central */}
        <div className="neon-border">
          <h1>{carouselData[carouselIndex]?.raceid}(🏇{carouselData[carouselIndex]?.registrados.length}) 
</h1>
         
          {/* Mostrar un registro a la vez */}
 <div className="carousel-registrados">
  <div className="actives">
 
  <button className="pantalla-neon-boton"><a href={`./races#raceId=${carouselData[carouselIndex]?.raceid}&name=${carouselData[carouselIndex]?.name}`}>{t('pPixel.llevame')}</a></button>
 
 <div className="OwnerCard">
  <p >👑{carouselData[carouselIndex]?.registrados_owners[registradosCarouselIndex]}</p>
 <p>{carouselData[carouselIndex]?.registrados[registradosCarouselIndex]}</p>
   </div>
<div className="imagen-caballos">
<div>
<img src={imagenRegistradoSrc} alt=""/>

</div>

<div>
<h3> <span>💰</span>{carouselData[carouselIndex]?.patrocinioData ? `${carouselData[carouselIndex]?.patrocinioData.Total_Pote.toFixed(2)}` : '...'}<img src={hivelogo} alt="Token" className="icon" style={{ width: '0.75em', height: '0.75em' }} />
          </h3>
<h1>  </h1>
  
</div>
</div>
 
  </div>
</div>
        </div>
      </div>

      {/* Pantalla derecha */}
    <div className={`screen right-screen`} key={carouselData[carouselIndex + 1]?.raceid}>
        {/* Mostrar los datos para la pantalla derecha */}
        <div className="neon-border-pixel">
        {/* Por ejemplo */}
        {/*<a href={`./races#raceId=${carouselData[carouselIndex + 1]?.raceid}&name=${carouselData[carouselIndex + 1]?.name }`}><h1>{carouselData[carouselIndex + 1]?.raceid}</h1></a>*/}

        {/*<p><a href={`./races#raceId=${carouselData[carouselIndex + 1]?.raceid}&name=${carouselData[carouselIndex + 1]?.name }`}><h1>{carouselData[carouselIndex + 1]?.raceid}</h1></a></p>*/}
{/*<p>*/}
  <div>
   <h5>{t("screen.RaceOpem")}</h5>
    {carouselData.map((data, index) => (
<div>
<h1>
  <a key={index} href={`./races#raceId=${data.raceid}&name=${data.name}`}>
    <column-span>{data.raceid} (🏇{data.registrados.length}) 💰</column-span>
    <row-span>
      {
        data.patrocinioData
          ? `${data.patrocinioData.Total_Pote.toFixed(2)}`
          : '...'
      }
      <img src={hivelogo} alt="Token" className="icon" style={{ width: '1em', height: '1em' }} />
    </row-span>
  </a></h1>
  </div>
))}
  </div>
{/*</p>*/}
   

 </div>
 </div>

    </div>
  </RaceScreenProvider>
);

}
export default RaceScreen;


