import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
<div className="loading">

<span className="loader"></span>
  
</div>

  );
};

export default Loader;
