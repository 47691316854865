import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

const FoodSelector = ({
  utilityNfts,
  alimentosConsumidos,
  alimentosPuntos,
  alimentoStatus,
  selectedItem,
  showItemsAlimentos,
  imagenAlimentosUrls,
  handleSelectChangeAlimento,
  handleAplicarItem1Click,
  setSelectedItem,
  setShowItemsAlimentos,
  handleClickAgregarImplemento
}) => {
  const { t } = useTranslation();

  if (!showItemsAlimentos) return null;

  return (
    <div>
      <div className="img-container-info">
        {alimentosConsumidos.map((numero, index) => (
          <div key={index} className="image-wrapper">
            <img
              src={imagenAlimentosUrls[numero]}
              alt={`Imagen ${numero}`}
              style={{ width: '150px' }}
            />
          </div>
        ))}
        {alimentosPuntos && typeof alimentosPuntos === 'number' && alimentosPuntos > 0 && (
          <h2 className="ptos_implementos" style={{ color: '#FF7F00' }}>
            +{alimentosPuntos}❤️
            <span className="ptos_implemento">Pts</span>
          </h2>
        )}
      </div>

      {alimentosConsumidos.length < 3 && alimentoStatus === "free" && (
        // <div>
        //   <select value={selectedItem?.id || ""} onChange={handleSelectChange}>
        //     <option value="">{t('info.Seleccionar')}</option>
        //     {utilityNfts.map(item => (
        //       <option key={item.id} value={item.name}>
        //         {item.name} - {item.id}
        //       </option>
        //     ))}
        //   </select>
        //   <button onClick={handleAplicarItem1Click}>{t('info.aplicarItem')}</button>
        // </div>
        <div>
  <select value={selectedItem?.id || ""} onChange={handleSelectChangeAlimento}>
    <option value="">{t('info.Seleccionar')}</option>
    {utilityNfts.map(item => (
      <option key={item.id} value={item.id}>
        {item.name} - {item.id}
      </option>
    ))}
  </select>
  <button onClick={handleAplicarItem1Click}>{t('info.aplicarItem')}</button>
</div>

      )}
    </div>
  );
};






FoodSelector.propTypes = {
  utilityNfts: PropTypes.array.isRequired,
  alimentosConsumidos: PropTypes.array.isRequired,
  alimentosPuntos: PropTypes.number.isRequired,
  alimentoStatus: PropTypes.string.isRequired,
  selectedItem: PropTypes.object,
  showItemsAlimentos: PropTypes.bool.isRequired,
  imagenAlimentosUrls: PropTypes.object.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleAplicarItem1Click: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setShowItemsAlimentos: PropTypes.func.isRequired,
  handleClickAgregarImplemento: PropTypes.func.isRequired
};

export default FoodSelector;
