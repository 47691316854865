import React, { createContext, useState, useContext,useEffect} from 'react';
import { linkApp } from './../generals/configuracion/variablesPublicas.jsx';

// Creamos el contexto
const SistemasActivosContext = createContext();

// Creamos un provider para envolver la aplicación y proporcionar los datos de los sistemas activos
export const SistemasActivosProvider = ({ children }) => {
  const [sistemasActivos, setSistemasActivos] = useState(null);

  // Función para obtener los datos de los sistemas activos
  const obtenerSistemasActivos = async () => {
    try {
      const response = await fetch(`${linkApp}/sistemas_activos`);
      const data = await response.json();
      setSistemasActivos(data[0]);
    } catch (error) {
      console.error('Error al obtener los datos de los sistemas activos:', error);
    }
  };

  // Llamamos a la función para obtener los datos una vez al cargar la aplicación
  useEffect(() => {
    obtenerSistemasActivos();
  }, []);

  return (
    <SistemasActivosContext.Provider value={sistemasActivos}>
      {children}
    </SistemasActivosContext.Provider>
  );
};

// Función para consumir el contexto de los sistemas activos
export const useSistemasActivos = () => useContext(SistemasActivosContext);
