import React, { useState, useEffect } from "react";
import './components/utility_nfts.css';
import './css/cofresPatro.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ModalPatro from './components/modals/cofrepatroclaim.jsx';
import { linkApp } from "./../generals/configuracion/variablesPublicas.jsx";

function CofresPatro(props) {
  console.log("cofres patro props", props);

  const cofres_disponible_p = props.cofres_p_disponibles;
  console.log("cofres patro", cofres_disponible_p);

  const { t } = useTranslation();

  const cofresAReclamar1 = 1;

  return (
    <div className="wrapp_inventario_cofres_p">
      <div className="container-inventario-cofres-p">
        <Card
          link={t('wp.cofres')}
          img="image/cofres/patrocinio.png"
          className="cardPs__image"
          title=""
          description="Sponsor Chest"
          merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
          memo="Purchase Made By HIVEBUYER"
          base_currency="USD"
          pay_currency="BUDS,SOULS,HUESO,SWAP.HIVE,SWAP.HBD,HIVE,HBD"
          amount="1.25"
          amount_bhrt="15000.000"
          amount_bhr="1.875"
          item_name="Cofre Patrocinio"
          item_image="https://www.blockhorseracing.com/image/cofres/patrocinio.png"
          quantity={cofres_disponible_p}
          cancel_url_name="Decline Buy"
          background="medium"
          cofresareclamar="1"
          reclamo={cofresAReclamar1}
        />
      </div>
    </div>
  );
}

function Card(props) {
  const [isOpenP, setIsOpenP] = useState(false);
  const [cofresPatroProcesando, setCofresPatroProcesando] = useState({});
  const { t } = useTranslation();

  const onCloseP = () => {
    setIsOpenP(!isOpenP);
  };

function handleClaim_patro(reclamo, cofresAReclamar) {
  console.log("reclamo mo", reclamo, cofresAReclamar);
  let username = localStorage.getItem("user");
  if (cofresAReclamar >= reclamo) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify( { user_claim: username, cofres_patro: reclamo })
    };
    fetch(`${linkApp}/chest_claim_patro`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.error) {
          alert(data.error);
        } else {
          setCofresPatroProcesando(data.body.cofres_patro_obtenido);
          setIsOpenP(!isOpenP);
        }
      })
      .catch(error => console.log(error));
  } else {
    alert(`${t("cofres.AlertaNoTienes")} ${reclamo} ${t("cofres.cofres")}`);
  }
}


  return (
    <>
      <div className="card_cofres_p_inventario">
        <div className="box-nft_cofres_p_inventario">
          <span>
            <a href={props.link} style={{ background: 'gold', borderRadius: '5px', color: 'black', padding: '5px', display: 'inline-block', float: 'right' }} title="More Info" target="_blank">?</a>
          </span>
          <div className="content_cofres_p_inventario">
            <p>{props.description}</p>
            <img src={props.img} alt="Horses" className="cajas_cofres_p_inventario" />
            <h4>available: <span>{props.quantity}</span></h4>
            <form method="post" action="https://hivepay.io/pay/">
              <input type="hidden" name="merchant_memo" value={props.merchant_memo} />
              <input type="hidden" name="base_currency" value={props.base_currency} />
              <input type="hidden" name="pay_currency" value={props.pay_currency} />
              <input type="hidden" name="amount" value={props.amount} />
              <input type="hidden" name="description" value={props.description} />
              <input type="hidden" name="cancel_url_name" value="BHR" />
              <input type="hidden" name="background" value="medium" />
            </form>
            <button type="submit" onClick={() => handleClaim_patro(props.reclamo, props.quantity)}>
              <span>Open</span>
            </button>
          </div>
        </div>
      </div>
      <ModalPatro isOpenP={isOpenP} onCloseP={onCloseP} cofresPatroProcesando={cofresPatroProcesando} />
    </>
  );
}

export default CofresPatro;
