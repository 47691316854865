import React, { useState, useEffect } from 'react';
import './modalinFoRace.css';

import Actuaciones from"./components/Actuaciones.jsx";






const ModalinFoRace = ({  isOpen, closeModalInf, raceId }) => {
const [error, setError] = useState('');
  



  
 console.log("RAAAAACEID",raceId,raceId.raceid)
  const handleModalContainerClicke = (e) => e.stopPropagation();


  
 return ( 
<article className={`modal ${isOpen && "is-open"}`} onClick={closeModalInf}>
  <div className="modal-container-race-results" onClick={handleModalContainerClicke}>
  {/*  <button className="modal-close" onClick={closeModalInf}>X</button>
    */}<div className="card-info-resultados">
      
<Actuaciones raceId={raceId}/>

        
        
      
    </div>
  </div>
</article>
  );
};


export default ModalinFoRace;

