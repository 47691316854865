import { useState, useEffect } from 'react';
import axios from 'axios';

const TimeAPI = () => {
  const [timeActual, setTimeActual] = useState();
  console.log("Time Actual" ,timeActual)

  useEffect(() => {
    const getTime = async () => {
      try {
        const response = await axios.get('/time');
        setTimeActual(response.data.body[0].updatedAt);
      } catch (error) {
        console.log(error);
      }
    };
    getTime();
  }, []);

  useEffect(() => {
    if (timeActual) {
      const intervalId = setInterval(() => {
        setTimeActual(timeActual => new Date(new Date(timeActual).getTime() + 10000));
      }, 100000000000000000);
      return () => clearInterval(intervalId);
    }
  }, [timeActual]);

  return { timeActual, setTimeActual };
};
export default TimeAPI;