import React, { useState } from 'react';
import EquineImage from './EquineImage';

import { useTranslation } from 'react-i18next';
import Apuestas from './Apuestas.jsx';
import Loader from './../../../../../../generals/loders/Loader.jsx'; // Asegúrate de importar tu componente de loader
import hivelogo from '../../../../../../../assets/logoblockhorseracing/logoHive.png';
import LastRacesEq from'./equinostabla/LastRacesEq.jsx'
import"./css/equinostable.css";

const EquinosTable = ({ equinos , fetchApuestas }) => {
   const { equinosT, raceId, apuestas } = equinos;
   const { t } = useTranslation();
   let apuestasH = apuestas && apuestas.apuestas ? apuestas.apuestas : [];
   const [isLoadingPatrocinio, setIsLoadingPatrocinio] = useState(true); // Estado para controlar la carga

console.log("equinosC-Table",equinosT)
   // Supongamos que aquí realizas alguna operación asincrónica para cargar los datos
   // Una vez que los datos se carguen, cambia isLoading a false

   // Simulación de carga de datos
   setTimeout(() => {
     setIsLoadingPatrocinio(false); // Cambia isLoading a false una vez que los datos se cargan
   }, 2);


   return (
     <div>
       {isLoadingPatrocinio ? ( // Muestra el loader mientras se cargan los datos
         <>
      <Loader />
      <p>Loading...</p>
      </>
       ) : (
         <table className="equinos">
    <thead>
      <tr>
        {  //<th><td> {t('resultados.propietario')}</td></th>
       //         <th>
       //  <td> 🐴 </td>
       //  </th> 
       //         <th><td>{t('resultados.estadisticas')}  </td></th>
       //        <th style={{ display: 'flex', alignItems: 'center' }}>
       //   💰Pool: {(apuestas.Total_Pote).toFixed(2)}
       //   <img src={hivelogo} alt="Token" className="icon" style={{ width: '5px', height: '25px', marginLeft: '0em' }} />
       // </th>
       // 
     }
      </tr>
    </thead>
    <tbody>





    {equinosT.reduce((uniqueEquinos, eq) => {
  const existingEquino = uniqueEquinos.find((uniqueEq) => uniqueEq.equineId === eq.equineId);
  if (!existingEquino) {
    uniqueEquinos.push(eq);
  }
  return uniqueEquinos;
}, []).map((eq, index) => {
        // Calcular sumaTicketsPorEquino para el caballo actual
        const sumaTicketsPorEquino = apuestasH.reduce((accumulator, apuesta) => {
          if (apuesta.equinoId === eq.equineId) {
            return accumulator + apuesta.cantidadTickets;
          }
          return accumulator;
        }, 0);

        return (
          <tr key={eq.equineId}>
            <td>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                👑
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {eq.account} {index}
             
              </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               
                <p>{t('apuestas.ESupport')} {(sumaTicketsPorEquino/20).toFixed(4)}</p>
                
              </div>
            </td>

            <td>
      
 
   
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {eq.sexo === 'M' ? '♂️' : '♀️'} <span style={{ fontSize: '0.7rem' }}>&nbsp;&nbsp;{eq.tipo}&nbsp;&nbsp;</span>
                {eq.equineId}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                 <EquineImage
          mainImageSrc={`./image/equinos/${eq.equineId}.png`}
          backupImageSrc={`./image/equinos/000.png`}
          isHovered={false}
        
        />
       
     <td>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: '0.7rem' }}>{t('resultados.agilidad')}</span><p>&nbsp; {eq.agilidad}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: '0.7rem' }}>{t('resultados.resistencia')}</span><p>&nbsp; {eq.resistencia}+</p><span>{isNaN(parseInt(eq.resistencia_add)) ? 0 : parseInt(eq.resistencia_add)}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: '0.7rem' }}>{t('resultados.velocidad')}</span><p>&nbsp; {eq.velocidad}+</p><span>{isNaN(parseInt(eq.velocidad_add)) ? 0 : parseInt(eq.velocidad_add)}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: '0.7rem' }}> {t('resultados.salud')}</span>❤️<p>&nbsp;&nbsp;{parseInt(eq.animo) + parseInt(eq.alimentos_ptos)}
              </p></div>
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: '0.7rem' }}> {t('resultados.implementos')}</span>🏇<p>&nbsp;&nbsp;{isNaN(parseInt(eq.implementos_ptos)) ? 0 : parseInt(eq.implementos_ptos)}
              </p></div>
            </td>
       

              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       
        
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h5>{eq.nameBhr}</h5>
              </div>
       
         


            </td>

       
       <td>  
             
        
        <LastRacesEq caballo={eq} />

        


        </td>

            <td>
             

             {/*  */}
              <Apuestas equino={{ equineId: eq.equineId, name: eq.name, raceId: raceId, apuestas: apuestas }} fetchApuestas={fetchApuestas} />
            
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
       )}
     </div>
   );
};

export default EquinosTable;
