import React, { useState, useEffect } from "react";
import axios from 'axios';
import './food.css'
import Loader from "./../../../../generals/loders/Loader.jsx";
import UtilityModal from "./food/Utility_Modal";
import MyUtilityModal from "./food/MyUtility_Modal";

export default function Food() {
  const [MyUtility_Nfts, setMyUtility_Nfts] = useState([]);
  const [Utility_Nfts, setUtility_Nfts] = useState([]);

  const [breeding_nft, setBreeding_nft] = useState([]);
  const [tack_nft, setTack_nft] = useState([]);
  const [boosters_nft, setBooster_nft] = useState([]);
  const [recover_nft, setRecover_nft] = useState([]);




  const [isLoaded, setisLoaded] = useState(false);
   const [isMyLoaded, setisMyLoaded] = useState(false);
     const [showUtilityModal, setShowUtilityModal] = useState(true);
  const [showMyUtilityModal, setShowMyUtilityModal] = useState(false);








//   useEffect(() => {
//   const getTackNfts = async () => {
//     // ... Código para obtener los NFTs de tipo "tack"
//   };

//   getTackNfts("https://engine.rishipanthee.com/contracts");
// }, []);

// useEffect(() => {



//   const getRecoverNfts = async () => {
//     // ... Código para obtener los NFTs de tipo "recover"
//   };

//   getRecoverNfts("https://engine.rishipanthee.com/contracts");
// }, []);



useEffect(() => {



  const getTackNfts = async (url) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    let query = {};
     
     query['grouping.type']="tack";
    //query.account = localStorage.getItem("user");

    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nftmarket',
          table: "BHREQTsellBook",
          query: query,
          limit: 400,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

    try {
      const response = await axios.post(url, body, config);
      const data = response.data[0].result;
      console.log("DATA DE MARKET",data)

      let groupedUtilityNfts = {};

      data.forEach((data) => {
        let identidad = data._id;
        let nft_id = data.nftId;
        let groupingName = data.grouping.name;
        let groupingTipo = data.grouping.type;
        let price = data.price;
        let account = data.account;
        let symbol = data.priceSymbol;
        let fee = data.fee;

        let Utility_Nft = {
          identidad: identidad,
          nftId: nft_id,
          account: account,
          name: groupingName,
          tipo: groupingTipo,
          price: price,
          symbol: symbol,
          fee: fee
        };

        if (groupingName in groupedUtilityNfts) {
          groupedUtilityNfts[groupingName].push(Utility_Nft);
        } else {
          groupedUtilityNfts[groupingName] = [Utility_Nft];
        }
      });

setTack_nft(prevUtilityNfts => {
  // Hacer una copia del estado actual para mantener su contenido
  const updatedUtilityNfts = { ...prevUtilityNfts };

  // Agregar la nueva información al objeto groupedUtilityNfts
  Object.keys(groupedUtilityNfts).forEach(groupingName => {
    if (groupingName in updatedUtilityNfts) {
      // Si la clave ya existe, agregar los nuevos datos al array existente
      updatedUtilityNfts[groupingName] = [
        ...updatedUtilityNfts[groupingName],
        ...groupedUtilityNfts[groupingName]
      ];
    } else {
      // Si es una clave nueva, simplemente asignar el array
      updatedUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
    }
  });

  // Devolver el estado actualizado
  return updatedUtilityNfts;
});
;
    } catch (error) {
      console.log("error fetching", error);
    } finally {
      setisLoaded(true);
    }
  };

  getTackNfts("https://engine.rishipanthee.com/contracts");
}, []);


///////////
/////
/////
/////
///////////


//////////////////















useEffect(() => {
  const getRecover = async (url) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    let query = {};
     
     query['grouping.type']="recover";
    //query.account = localStorage.getItem("user");

    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nftmarket',
          table: "BHREQTsellBook",
          query: query,
          limit: 1000,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

    try {
      const response = await axios.post(url, body, config);
      const data = response.data[0].result;
      console.log("DATA DE MARKET",data)

      let groupedUtilityNfts = {};

      data.forEach((data) => {
        let identidad = data._id;
        let nft_id = data.nftId;
        let groupingName = data.grouping.name;
        let groupingTipo = data.grouping.type;
        let price = data.price;
        let account = data.account;
        let symbol = data.priceSymbol;
        let fee = data.fee;

        let Utility_Nft = {
          identidad: identidad,
          nftId: nft_id,
          account: account,
          name: groupingName,
          tipo: groupingTipo,
          price: price,
          symbol: symbol,
          fee: fee
        };

        if (groupingName in groupedUtilityNfts) {
          groupedUtilityNfts[groupingName].push(Utility_Nft);
        } else {
          groupedUtilityNfts[groupingName] = [Utility_Nft];
        }
      });

setRecover_nft(prevUtilityNfts => {
  // Hacer una copia del estado actual para mantener su contenido
  const updatedUtilityNfts = { ...prevUtilityNfts };

  // Agregar la nueva información al objeto groupedUtilityNfts
  Object.keys(groupedUtilityNfts).forEach(groupingName => {
    if (groupingName in updatedUtilityNfts) {
      // Si la clave ya existe, agregar los nuevos datos al array existente
      updatedUtilityNfts[groupingName] = [
        ...updatedUtilityNfts[groupingName],
        ...groupedUtilityNfts[groupingName]
      ];
    } else {
      // Si es una clave nueva, simplemente asignar el array
      updatedUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
    }
  });

  // Devolver el estado actualizado
  return updatedUtilityNfts;
});

    } catch (error) {
      console.log("error fetching", error);
    } finally {
      setisLoaded(true);
    }
  };

  getRecover("https://engine.rishipanthee.com/contracts");
}, []);







//////
//
//
//
//////

////////

useEffect(() => {
  const getBooster = async (url) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    let query = {};
     
     query['grouping.type']="booster";
    //query.account = localStorage.getItem("user");

    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nftmarket',
          table: "BHREQTsellBook",
          query: query,
          limit: 1000,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

    try {
      const response = await axios.post(url, body, config);
      const data = response.data[0].result;
      console.log("DATA DE MARKET",data)

      let groupedUtilityNfts = {};

      data.forEach((data) => {
        let identidad = data._id;
        let nft_id = data.nftId;
        let groupingName = data.grouping.name;
        let groupingTipo = data.grouping.type;
        let price = data.price;
        let account = data.account;
        let symbol = data.priceSymbol;
        let fee = data.fee;

        let Utility_Nft = {
          identidad: identidad,
          nftId: nft_id,
          account: account,
          name: groupingName,
          tipo: groupingTipo,
          price: price,
          symbol: symbol,
          fee: fee
        };

        if (groupingName in groupedUtilityNfts) {
          groupedUtilityNfts[groupingName].push(Utility_Nft);
        } else {
          groupedUtilityNfts[groupingName] = [Utility_Nft];
        }
      });

setBooster_nft(prevUtilityNfts => {
  // Hacer una copia del estado actual para mantener su contenido
  const updatedUtilityNfts = { ...prevUtilityNfts };

  // Agregar la nueva información al objeto groupedUtilityNfts
  Object.keys(groupedUtilityNfts).forEach(groupingName => {
    if (groupingName in updatedUtilityNfts) {
      // Si la clave ya existe, agregar los nuevos datos al array existente
      updatedUtilityNfts[groupingName] = [
        ...updatedUtilityNfts[groupingName],
        ...groupedUtilityNfts[groupingName]
      ];
    } else {
      // Si es una clave nueva, simplemente asignar el array
      updatedUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
    }
  });

  // Devolver el estado actualizado
  return updatedUtilityNfts;
});

    } catch (error) {
      console.log("error fetching", error);
    } finally {
      setisLoaded(true);
    }
  };

  getBooster("https://engine.rishipanthee.com/contracts");
}, []);





useEffect(() => {
  const getBreeding = async (url) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    let query = {};
     
     query['grouping.type']="breeding";
    //query.account = localStorage.getItem("user");

    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nftmarket',
          table: "BHREQTsellBook",
          query: query,
          limit: 1000,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

    try {
      const response = await axios.post(url, body, config);
      const data = response.data[0].result;
      console.log("DATA DE MARKET",data)

      let groupedUtilityNfts = {};

      data.forEach((data) => {
        let identidad = data._id;
        let nft_id = data.nftId;
        let groupingName = data.grouping.name;
        let groupingTipo = data.grouping.type;
        let price = data.price;
        let account = data.account;
        let symbol = data.priceSymbol;
        let fee = data.fee;

        let Utility_Nft = {
          identidad: identidad,
          nftId: nft_id,
          account: account,
          name: groupingName,
          tipo: groupingTipo,
          price: price,
          symbol: symbol,
          fee: fee
        };

        if (groupingName in groupedUtilityNfts) {
          groupedUtilityNfts[groupingName].push(Utility_Nft);
        } else {
          groupedUtilityNfts[groupingName] = [Utility_Nft];
        }
      });

setBreeding_nft(prevUtilityNfts => {
  // Hacer una copia del estado actual para mantener su contenido
  const updatedUtilityNfts = { ...prevUtilityNfts };

  // Agregar la nueva información al objeto groupedUtilityNfts
  Object.keys(groupedUtilityNfts).forEach(groupingName => {
    if (groupingName in updatedUtilityNfts) {
      // Si la clave ya existe, agregar los nuevos datos al array existente
      updatedUtilityNfts[groupingName] = [
        ...updatedUtilityNfts[groupingName],
        ...groupedUtilityNfts[groupingName]
      ];
    } else {
      // Si es una clave nueva, simplemente asignar el array
      updatedUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
    }
  });

  // Devolver el estado actualizado
  return updatedUtilityNfts;
});

    } catch (error) {
      console.log("error fetching", error);
    } finally {
      setisLoaded(true);
    }
  };

  getBreeding("https://engine.rishipanthee.com/contracts");
}, []);


















// useEffect(() => {
//   const getMyUtility_Nfts = async (url) => {
//     let config = {
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*',
//       },
//     };

//     let query = {};
     
//      query['grouping.type']="tack";
//      query.account = localStorage.getItem("user");

//     let body = JSON.stringify([
//       {
//         method: "find",
//         jsonrpc: "2.0",
//         params: {
//           contract: 'nftmarket',
//           table: "BHREQTsellBook",
//           query: query,
//           limit: 200,
//           offset: 0,
//           indexes: [],
//         },
//         id: 1,
//       },
//     ]);

//     try {
//       const response = await axios.post(url, body, config);
//       const data = response.data[0].result;
//       console.log("DATA DE MARKET",data)

//       let groupedUtilityNfts = {};

//       data.forEach((data) => {
//         let identidad = data._id;
//         let nft_id = data.nftId;
//         let groupingName = data.grouping.name;
//         let groupingTipo = data.grouping.type;
//         let price = data.price;
//         let account = data.account;
//         let symbol = data.priceSymbol;
//         let fee = data.fee;

//         let MyUtility_Nft = {
//           identidad: identidad,
//           nftId: nft_id,
//           account: account,
//           name: groupingName,
//           tipo: groupingTipo,
//           price: price,
//           symbol: symbol,
//           fee: fee
//         };

//         if (groupingName in groupedUtilityNfts) {
//           groupedUtilityNfts[groupingName].push(MyUtility_Nft);
//         } else {
//           groupedUtilityNfts[groupingName] = [MyUtility_Nft];
//         }
//       });

//       setMyUtility_Nfts(groupedUtilityNfts);
//     } catch (error) {
//       console.log("error fetching", error);
//     } finally {
//       setisMyLoaded(true);
//     }
//   };

//   getMyUtility_Nfts("https://engine.rishipanthee.com/contracts");
// }, []);

























useEffect(() => {
  const getMyTacks = async () => {
    const groupingTypes = ["tack"];

    for (let i = 0; i < groupingTypes.length; i++) {
      const groupingType = groupingTypes[i];
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      let query = {};
      query['grouping.type'] = groupingType;
      query.account = localStorage.getItem("user");

      let body = JSON.stringify([
        {
          method: "find",
          jsonrpc: "2.0",
          params: {
            contract: 'nftmarket',
            table: "BHREQTsellBook",
            query: query,
            limit: 200,
            offset: 0,
            indexes: [],
          },
          id: 1,
        },
      ]);

      try {
        const response = await axios.post("https://engine.rishipanthee.com/contracts", body, config);
        const data = response.data[0].result;

        console.log(`DATA DE MARKET para ${groupingType}`, data);

        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.grouping.name;
          let groupingTipo = data.grouping.type;
          let price = data.price;
          let account = data.account;
          let symbol = data.priceSymbol;
          let fee = data.fee;

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
            account: account,
            name: groupingName,
            tipo: groupingTipo,
            price: price,
            symbol: symbol,
            fee: fee
          };

          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });

      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });

      } catch (error) {
        console.log(`Error fetching para ${groupingType}`, error);
      }
    }

    // Puedes realizar alguna acción adicional después de obtener todos los datos
    // setisMyLoaded(true);
  };

  getMyTacks();
}, []);



useEffect(() => {
  const getMyBooster = async () => {
    const groupingTypes = ["booster"];

    for (let i = 0; i < groupingTypes.length; i++) {
      const groupingType = groupingTypes[i];
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      let query = {};
      query['grouping.type'] = groupingType;
      query.account = localStorage.getItem("user");

      let body = JSON.stringify([
        {
          method: "find",
          jsonrpc: "2.0",
          params: {
            contract: 'nftmarket',
            table: "BHREQTsellBook",
            query: query,
            limit: 200,
            offset: 0,
            indexes: [],
          },
          id: 1,
        },
      ]);

      try {
        const response = await axios.post("https://engine.rishipanthee.com/contracts", body, config);
        const data = response.data[0].result;

        console.log(`DATA DE MARKET para ${groupingType}`, data);

        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.grouping.name;
          let groupingTipo = data.grouping.type;
          let price = data.price;
          let account = data.account;
          let symbol = data.priceSymbol;
          let fee = data.fee;

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
            account: account,
            name: groupingName,
            tipo: groupingTipo,
            price: price,
            symbol: symbol,
            fee: fee
          };

          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });



      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });


        // Agregar los resultados al estado o procesarlos según sea necesario
        // setMyUtility_Nfts(groupedUtilityNfts);
      } catch (error) {
        console.log(`Error fetching para ${groupingType}`, error);
      }
    }

    // Puedes realizar alguna acción adicional después de obtener todos los datos
    // setisMyLoaded(true);
  };

  getMyBooster();
}, []);


useEffect(() => {
  const getMyRecover = async () => {
    const groupingTypes = ["recover"];

    for (let i = 0; i < groupingTypes.length; i++) {
      const groupingType = groupingTypes[i];
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      let query = {};
      query['grouping.type'] = groupingType;
      query.account = localStorage.getItem("user");

      let body = JSON.stringify([
        {
          method: "find",
          jsonrpc: "2.0",
          params: {
            contract: 'nftmarket',
            table: "BHREQTsellBook",
            query: query,
            limit: 200,
            offset: 0,
            indexes: [],
          },
          id: 1,
        },
      ]);

      try {
        const response = await axios.post("https://engine.rishipanthee.com/contracts", body, config);
        const data = response.data[0].result;

        console.log(`DATA DE MARKET para ${groupingType}`, data);

        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.grouping.name;
          let groupingTipo = data.grouping.type;
          let price = data.price;
          let account = data.account;
          let symbol = data.priceSymbol;
          let fee = data.fee;

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
            account: account,
            name: groupingName,
            tipo: groupingTipo,
            price: price,
            symbol: symbol,
            fee: fee
          };

          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });



      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });


        // Agregar los resultados al estado o procesarlos según sea necesario
        // setMyUtility_Nfts(groupedUtilityNfts);
      } catch (error) {
        console.log(`Error fetching para ${groupingType}`, error);
      }
    }

    // Puedes realizar alguna acción adicional después de obtener todos los datos
    // setisMyLoaded(true);
  };

  getMyRecover();
}, []);




useEffect(() => {
  const getMyBreding = async () => {
    const groupingTypes = ["breeding"];

    for (let i = 0; i < groupingTypes.length; i++) {
      const groupingType = groupingTypes[i];
      
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      let query = {};
      query['grouping.type'] = groupingType;
      query.account = localStorage.getItem("user");

      let body = JSON.stringify([
        {
          method: "find",
          jsonrpc: "2.0",
          params: {
            contract: 'nftmarket',
            table: "BHREQTsellBook",
            query: query,
            limit: 200,
            offset: 0,
            indexes: [],
          },
          id: 1,
        },
      ]);

      try {
        const response = await axios.post("https://engine.rishipanthee.com/contracts", body, config);
        const data = response.data[0].result;

        console.log(`DATA DE MARKET para ${groupingType}`, data);

        let groupedUtilityNfts = {};

        data.forEach((data) => {
          let identidad = data._id;
          let nft_id = data.nftId;
          let groupingName = data.grouping.name;
          let groupingTipo = data.grouping.type;
          let price = data.price;
          let account = data.account;
          let symbol = data.priceSymbol;
          let fee = data.fee;

          let MyUtility_Nft = {
            identidad: identidad,
            nftId: nft_id,
            account: account,
            name: groupingName,
            tipo: groupingTipo,
            price: price,
            symbol: symbol,
            fee: fee
          };

          if (groupingName in groupedUtilityNfts) {
            groupedUtilityNfts[groupingName].push(MyUtility_Nft);
          } else {
            groupedUtilityNfts[groupingName] = [MyUtility_Nft];
          }
        });



      setMyUtility_Nfts(prevMyUtilityNfts => {
        const updatedMyUtilityNfts = { ...prevMyUtilityNfts };

        // Agregar la nueva información al objeto groupedUtilityNfts
        Object.keys(groupedUtilityNfts).forEach(groupingName => {
          if (groupingName in updatedMyUtilityNfts) {
            updatedMyUtilityNfts[groupingName] = [
              ...updatedMyUtilityNfts[groupingName],
              ...groupedUtilityNfts[groupingName]
            ];
          } else {
            updatedMyUtilityNfts[groupingName] = groupedUtilityNfts[groupingName];
          }
        });

        // Devolver el estado actualizado
        return updatedMyUtilityNfts;
      });


        // Agregar los resultados al estado o procesarlos según sea necesario
        // setMyUtility_Nfts(groupedUtilityNfts);
      } catch (error) {
        console.log(`Error fetching para ${groupingType}`, error);
      }
    }

    // Puedes realizar alguna acción adicional después de obtener todos los datos
    // setisMyLoaded(true);
  };

  getMyBreding();
}, []);






























  const handleBuyOrder = (selectedItems) => {
    console.log("Buy Order:", selectedItems);

  let username=localStorage.getItem("user");
  // Obtener el array de IDs
  const ids = selectedItems.map((item) => item.id);
console.log("IIDS",ids);

  // Calcular el total de los precios sumados
  const total =selectedItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
console.log("totales",total);

  // Obtener el símbolo
  const symbol = selectedItems.length > 0 ? selectedItems[0].symbol : '';

console.log("symbol",symbol);



    // Lógica para realizar la compra de los elementos seleccionados
if (window.hive_keychain) {
  const keychain = window.hive_keychain;

    const customJson = [
  {
    "contractName": "nftmarket",
    "contractAction": "buy",
    "contractPayload": {
      "symbol": "BHREQT",
      "nfts": ids,
      "expPrice": total.toFixed(3),
      "expPriceSymbol": symbol,
      "marketAccount": "bhr-market"
    }
  }
];

  keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Buy NFTs', response => {
    console.log('Response from Hive Keychain:', response);
    // axios.post('./keychaintrx_chest', response)
    //   .then(res => {
    //     console.log('Response from server:', res);
    //   })
    //   .catch(error => {
    //     console.log('Error:', error);
    //   });
  });
} else {
  alert('You do not have hive keychain installed');
}

 
  };

  



     const handleCancelOrder = (selectedItems) => {
    console.log("Buy Order:", selectedItems);

  let username=localStorage.getItem("user");
  // Obtener el array de IDs
  const ids = selectedItems.map((item) => item.id);
console.log("IIDS",ids);

  // Calcular el total de los precios sumados
  const total =selectedItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
console.log("totales",total);

  // Obtener el símbolo
  const symbol = selectedItems.length > 0 ? selectedItems[0].symbol : '';

console.log("symbol",symbol);



    // Lógica para realizar la compra de los elementos seleccionados
if (window.hive_keychain) {
  const keychain = window.hive_keychain;

    const customJson = [
  {
    "contractName": "nftmarket",
    "contractAction": "cancel",
    "contractPayload": {
      "symbol": "BHREQT",
      "nfts": ids,
   
    }
  }
];

  keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Cancel Sell', response => {
    console.log('Response from Hive Keychain:', response);
    // axios.post('./keychaintrx_chest', response)
    //   .then(res => {
    //     console.log('Response from server:', res);
    //   })
    //   .catch(error => {
    //     console.log('Error:', error);
    //   });
  });
} else {
  alert('You do not have hive keychain installed');
}

 
  };


 const handleMarketClick = () => {
    setShowUtilityModal(true);
    setShowMyUtilityModal(false);
  };

  const handleMyItemsClick = () => {
    setShowMyUtilityModal(true);
    setShowUtilityModal(false);
  };


  return (
    <>
      <div className="wrapp">
        <div className="container-market-implementos">

              {tack_nft.length === 0 && isLoaded === false ? (
          <Loader />
        ) : (
          <button onClick={handleMarketClick} className="Nav-Market"><h1>Market</h1></button>
        )}

        {MyUtility_Nfts.length === 0 && isLoaded === false ? (
          <></>
        ) : (
          <button onClick={handleMyItemsClick} className="Nav-Market"><h1>Your Items</h1></button>
        )}


           {showUtilityModal && (
            <>
        
          <UtilityModal nfts={tack_nft} onBuy={handleBuyOrder} nft_tipo="Tacks"/>
           <UtilityModal nfts={recover_nft} onBuy={handleBuyOrder}  nft_tipo="Recover"/>
           <UtilityModal nfts={boosters_nft} onBuy={handleBuyOrder}  nft_tipo="Boosters"/>
           <UtilityModal nfts={breeding_nft} onBuy={handleBuyOrder}  nft_tipo="Breeding"/>
        

       
        </>
        )}

        {showMyUtilityModal && (
          <MyUtilityModal nfts={MyUtility_Nfts} onBuy={handleCancelOrder} />
        )}
        {/*
  {Utility_Nfts.length === 0 && isLoaded === false ? (
  <Loader />
) : (
  <UtilityModal nfts={Utility_Nfts} onBuy={handleBuyOrder} />
)}

  {MyUtility_Nfts.length === 0 && isMyLoaded === false ? (
  <Loader />
) : (
  <MyUtilityModal nfts={MyUtility_Nfts} onBuy={handleCancelOrder} />
)}*/}
        </div>
      </div>
    </>
  );
}
