import RaceTrack from "./components/races_track/racetrack.jsx";

function RaceSimulator({ visual, corredores }) {
  // console.log("ACTUACI Visual", visual.visual)
  console.log("ACTUACI Visual Corredores", corredores);
  const raceInProgress = false;

  return (
    <div>
      {raceInProgress ? (
        <p>Carrera en progreso...</p>
      ) : (
        <>
          <RaceTrack visual={visual.visual} horses={corredores.corredores} />
        </>
      )}
    </div>
  );
}

export default RaceSimulator;
