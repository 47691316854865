import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TablaCarreras from './../tables/TablasCarrera.jsx';//NUEVA FORMA
import "./RaceBalloModal.css"
import TablaCrr from './../tables/TablaCrr.jsx';
import { useTranslation } from 'react-i18next';
import Icon from './../../../generals/svgiconos/svgiconosenreact.jsx';
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';
import ChangeHorseName from'./components/raceballo/ChangeHorseName.jsx'
import EquineImage from'./../../../generals/herramientas/imagenesCaballos.jsx';





function traducirTipo(tipo) {
  switch (tipo) {
    case 'F':
      return 'Ready';
    case 'R':
      return 'Resting';
    case 'S':
      return 'Waiting Sell';
    case 'I':
      return 'Registered';
    case 'P':
      return 'Pending registration';
    default:
      return 'Unknown';
  }
}

function carreras_list(T,W,C){
 let carreras=""
 if(T>0){
    if(W>0){
     if(W===1){carreras="G1"}
     if(W===2){carreras="G2"}
     if(W===3){carreras="G3"}
     if(W===4){carreras="G4"}
     if(W===5){carreras="G5"}
     if(W>5){
      if(C===0){carreras="PC"}
      if(C===1){carreras="C1"}
      if(C===2){carreras="C2"}
      if(C===3){carreras="C3"}
      if(C===4){carreras="C4"}
      if(C===5){carreras="C5"}
      if(C> 5){carreras="C6"} } 
      }else if(W===0){
        carreras="P0"
    }
  }else{
    carreras="D0"
  }

return carreras 

}


const ModalRace = ({ children, isOpen,openModal, closeModal, propsi }) => {
  const [data, setData] = useState(null);
   const [isLoadingData, setIsLoadingData] = useState(true);
  const [isCancelledData, setIsCancelledData] = useState(false);
   const [equino, setEquino] = useState(null);
   const [isLoadingEquino, setIsLoadingEquino] = useState(true);
   const [isCancelledEq, setIsCancelledEq] = useState(false);
  const [loading_race_status_create, setLoading_race_status_create] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [showChangeName, setShowChangeName] = useState(false);

   const [races, setRaces] = useState([]);
  const [showData, setShowData] = useState(false);
  const [timeActual, setTimeActual] = useState(null);
   const { t } = useTranslation();
  let identidad=propsi.identidad;


  
  const toggleChangeName = () => {
    setShowChangeName(!showChangeName);
  };







 useEffect(() => {
 if (isOpen){
  axios.get(`${linkApp}/race?status=create`)
  .then(response => setData(response.data.body))

}else{
  setData(null);
  
}
  
  }, [isOpen]);






  
    
  const handleModalContainerClick = (e) => {
    e.stopPropagation();
    closeModal();
  };

    
const handleFetchRace = async (propsi) => {
  if (!showData) {
    let c_totales = propsi.carrerasTotals || 0;
    let c_win = propsi.ganadasTotals|| 0;
    let c_win_c = propsi.ganadasCon || 0;
    let carreras = carreras_list(c_totales, c_win, c_win_c);
    let Schedule_race = propsi.Schedule;


    try {
      if (propsi.estado === "registrado") {
        Schedule_race = propsi.schedule;
        // console.log("shedul", Schedule_race);

        // Buscar la carrera en data que coincide con Schedule_race
        const scheduledRace = data.find((race) => race.raceid === Schedule_race);

        if (scheduledRace) {
          setSchedule([scheduledRace]);
          setRaces(null);
        }
      } else if (propsi.estado === "Ready" || propsi.estado === "Procesando Inscripcion") {
        // Filtrar las carreras que coinciden con la categoría del caballo (carreras)
        const filteredRaces = data.filter((race) => {
          let tip1 = race.tipo.substr(0, 2);
          let tip2 = race.tipo.substr(3, 2);
          let tip3 = race.tipo.substr(6, 2);
          return tip1 === carreras || tip2 === carreras || tip3 === carreras;
        });

        setRaces(filteredRaces);
        setSchedule(null);
      }
    } catch (error) {
      console.log("soy EL CATCH");
      console.log("error de axios", error);
    }
  } else {
    setRaces([]);
    setSchedule([]);
  }

  setShowData(!showData); // Alternar la visibilidad de la tabla de carreras y el horario
};




function getClassName(tipo) {
  switch (tipo) {
    case 'Common':
      return 'common';
    case 'Uncommon':
      return 'uncommon';
    case 'Rare':
      return 'rare';
    case 'Spectral':
      return 'spectral';
    case 'Epic':
      return 'epic';
    case 'Mithic':
      return 'mithic';
    case 'Legendary':
      return 'legendary';
      case 'XCommon':
      return 'Xcommon';
    case 'XUncommon':
      return 'Xuncommon';
    case 'XRare':
      return 'rare';
    case 'XSpectral':
      return 'spectral';
    case 'XEpic':
      return 'epic';
    case 'XMithic':
      return 'mithic';
    case 'XLegendary':
      return 'legendary';

    default:
      return '';
  }
}



  return (
    <article className={`modal ${isOpen && "is-open"}`}>
      <div className="modal-race-container" onClick={handleModalContainerClick}>
      
        </div>
        <div className="card-race-info">
   <button className="modal-race-close" onClick={closeModal}>
          X
        </button>
       
       <div className="content-race"> 
       

       { propsi && propsi.status ? (
                    <p className="Status">
                        {propsi.status.split(',').map(traducirTipo).join(', ')}{' '}
                        
                    </p>
                ) : (
                    <p className="Status">
                        {propsi.status.split(',').map(traducirTipo).join(', ')}
                    </p>
                )}

 
        <div className={`content-race-img-${getClassName(propsi.tipo)}`}>
         <div className="rareza-race">
         <h2>{propsi.tipo}

           <span style={{ fontSize: '1.65rem' }}> {propsi.sexo === 'M' ? '♂️' : '♀️'} </span>
              
</h2>


{/*
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <span style={{ fontSize: '1rem' }}> {propsi.sexo === 'M' ? '♂️' : '♀️'} </span>
              
              </div>*/}
         </div>
         {/*<img src={propsi.IPFS} alt={propsi.name} className="caballoimg"  />*/}
            <EquineImage
          mainImageSrc={propsi.IPFS}
          backupImageSrc={`/image/equinos/${propsi.identidad}.png`}
          isHovered={false}
          className="caballoimg"
        />


         <div className="trapecio-inforace">
      

 { propsi && propsi.alimentos_ptos ? (
                   <div>     
      <Icon name="animo" width="2rem" height="2rem" />
      <p>&nbsp;{parseInt(propsi.animo) + parseInt(propsi.alimentos_ptos)}</p>

        </div> 
                ) : (
                      <div>     
      <Icon name="animo" width="2rem" height="2rem" />
      <p>&nbsp;{propsi.animo} 
      
 </p>
        </div> 
                )}




        <div>       
  <Icon name="velocidad" width="2rem" height="2rem" />
          <p>  &nbsp;{parseInt(propsi.velocidad) + parseInt(propsi.velocidad_add)}</p>
      </div>
<div>
  <Icon name="resistencia" width="2rem" height="2rem"  className="svg" />    
  <p>{parseInt(propsi.resistencia) + parseInt(propsi.resistencia_add)}</p>
</div>        <div>  
         <Icon name="agilidad" width="2rem" height="2rem" />
          <p> &nbsp;{propsi.agilidad}</p>
</div>









         </div>

  
        </div>   
         
           








           <div className="minteo-race">
           <div className="circle-container">
                            <p className="Mint">
                        Mint <h2>{propsi.identidad}</h2>
                    </p>
           </div>  </div>
          <div className="name">
         
      <p>{propsi.name}</p> <button onClick={toggleChangeName}>🔄</button>
      
    </div>
      <div className="name">
{showChangeName && <ChangeHorseName props={propsi.identidad}/>}
     </div>
 <div className="data-container" style={{ display: showData ? 'grid' : 'none' }}>

{data && (

   schedule ?(
 <>   
 <TablaCrr data={schedule} propsi={propsi} fecha={timeActual} />
      
   </>
  ) : (
      <TablaCarreras data={races} propsi={propsi} handlee={handleFetchRace}fecha={timeActual} />
      
  )
)}
        </div>
        
{propsi &&  (propsi.estado === "registrado" || propsi.estado === "Ready" || typeof propsi.estado === "undefined") && (
 
  <input
  type="submit"
  name="background"
  value={t('race.carreras-disponibles')}
  onClick={(e) => {
    e.stopPropagation(); // Detener la propagación del evento
    handleFetchRace(propsi);
  }}
  style={{
    cursor: "pointer",
    backgroundColor: "yellow",
    border: "none",
    color: "black",
    padding: "0.2rem"
  }}
  onMouseOver={(e) => (e.target.style.backgroundColor = "gold")}
  onMouseOut={(e) => (e.target.style.backgroundColor = "#5ba7e7a3")}
/>


  
)}

         <div className="actions-race">



</div>
       </div>
       </div>
        

    </article>
  );
};

export default ModalRace;
