




import React from 'react';
import './lastrace.css';
import { useTranslation } from 'react-i18next';

// Código React con nombres de clases
const LastRacesEq = ({ caballo }) => {
  console.log("caballos", caballo);
  const { t } = useTranslation();

  return (
    <div className="last-races-table">
      <table className="last-races-table">
        <thead>
          <tr>
            <th><p>{t("tablainfoCarrera.carraraypuesto")}</p>🎌</th>
          </tr>
        </thead>
        <tbody>
          {caballo.carreras.slice(-4).reverse().map((carrera, index) => (
            <tr key={index}>
              <td>
                <a className="race-link" href={`/races#raceId=${carrera.race}`}>
                  {carrera.race}/{carrera.Lugar}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LastRacesEq;
