import React from 'react';
import { changeLanguage } from './../../../i18n';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LoginButton from './LoginButton.jsx';
import './initbar.css';
import './ButtonLogin.css';
import LanguageContext from '../../context/LanguageContext';
import ThemeContext from '../../context/ThemeContext';
import i18n from '../../../i18n'; // <-- Importa el archivo i18n aquí

export default function Initbar(props) {
  const { theme, handleTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { handleLanguage } = useContext(LanguageContext);

  function toggleLanguage() {
    const lang = i18n.language === 'en' ? 'es' : 'en';
    changeLanguage(lang);
  }

  return (
    <nav className={theme}>
      <div className={'link-init' + theme}>
        <ul className={props.theme}>
          <li className="switche">
            <input
              id="language-toggle"
              className="check-toggle check-toggle-round-flat"
              type="checkbox"
              onClick={toggleLanguage}
            />
            <label htmlFor="language-toggle"></label>
            <span className="on">{t('sp')}</span>
            <span className="off">{t('en')}</span>
          </li>
          <li>
            <label className={'switch'}>
              <input type="checkbox" value={theme} onClick={handleTheme} />
              <span className={'slidersun' + theme}></span>
            </label>
          </li>

          <li>
            <LoginButton />
          </li>
        </ul>
      </div>
    </nav>
  );
}
