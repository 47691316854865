// import React from 'react';
// import EquineImage from'./../../../../../../generals/herramientas/imagenesCaballos.jsx';
// import './EquineDetails.css'; // Asegúrate de tener un archivo CSS para este componente


// const EquineDetails = ({ tipo, generacion, IPFS, identidad, name }) => {
//   return (
//     <div className="content-img-equino">
//       <h4 className="rareza">{tipo}</h4>
//       <h4 className="generacion">Gen: {Number(generacion)}</h4>
//       <EquineImage
//         mainImageSrc={IPFS}
//         backupImageSrc={`/image/equinos/${identidad}.png`}
//         isHovered={false}
//         className="Caballo"
//       />
//       <div style={{ 
//         display: 'flex', 
//         justifyContent: 'space-between', 
//         width: '100%', 
//         fontSize: '1em', // 1em = 16px por defecto
//         flexWrap: 'wrap'
//       }}>
//         <p style={{ width: '40%' }}>{name ? name : 'noname'}</p>
//         <p>Mint: {identidad}</p>
//       </div>
//     </div>
//   );
// };

// export default EquineDetails;

import React from 'react';
import EquineImage from './../../../../../../generals/herramientas/imagenesCaballos.jsx';
import './tarjetacaballocss/EquineDetails.css'; // Asegúrate de tener este archivo CSS

const EquineDetails = ({ tipo, generacion, IPFS, identidad, name }) => {
  return (
    <section className="equine-details">
      <div className="image-container-ballo">
        <EquineImage
          mainImageSrc={IPFS}
          backupImageSrc={`/image/equinos/${identidad}.png`}
          isHovered={false}
          className="caballo-image"
        />
        <div className="overlay">
          <p>{tipo}</p>
          <p>Gen: {Number(generacion)}</p>
        </div>
      </div>
      <footer className="equine-footer">
        <h4>{name ? name : 'noname'}</h4>
        <p>Mint: {identidad}</p>
      </footer>
    </section>
  );
};

export default EquineDetails;
