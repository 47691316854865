import React from 'react'
import Card from './components/card.jsx'
import ballo from'../../../../assets/ballo.png'
import '../presales/presales.css'


function Home() {
	return (
		<div>
		
	    <Card description="Go! For Presale" login="Login" imagen={ballo}/>
	    </div>
	)
}

export default Home


















