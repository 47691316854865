import React from 'react';

import { useContext } from 'react';
import{NavLink} from'react-router-dom'

import './../../../dashboard/components/navbar_nft/nav_bar_nft.css';
import LanguageContext from '../../../../../context/LanguageContext';
import ThemeContext from '../../../../../context/ThemeContext';
import Icon from './../../../../../generals/svgiconos/svgiconosenreact.jsx';
import caballo from './../../../../../../assets/gif/bottons/caballo.jpg';
import manzana from './../../../../../../assets/gif/bottons/manzana.jpg';
import trailer from './../../../../../../assets/gif/bottons/trailer.jpg';
//function Navbar_nft(props) {
  

export default function Navbar_Market(props) {
  const { theme, handleTheme } = useContext(ThemeContext);
     const { handleNavClick } = props;



  const handleClick = (event, route) => {
    event.preventDefault();
    handleNavClick(route);
  }

 

  return (
    <nav className={theme}>
      <div className={`navbar-container ${theme}`}>
        <ul className="navbar">
          <li className="navbar-item">  
            <a href="/marketballos" onClick={(event) => handleClick(event, '/marketballos')}>
              <img src={caballo} alt="boton"  className="botton-navbar" />
            </a>
          </li>
          <li className="navbar-item">
            <a href="food" onClick={(event) => handleClick(event, 'food')}>       
              <img src={manzana} alt="boton"  className="botton-navbar" />
            </a>
          </li>
          <li className="navbar-item">
            <a href="/implementos" onClick={(event) => handleClick(event, '/implementos')}>
              <img src={trailer} alt="boton"  className="botton-navbar" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );


}




