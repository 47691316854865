import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import "./Implementos.css";


const ImplementoSelector = ({
  utilityNfts,
  implementosConsumidos,
  implementosPuntos,
  implementostatus,
  selectedItem,
  showItemsImplementos,
  handleSelectChange,
  handleAplicarItem1Click,
  setSelectedItem,
  setShowItemsImplementos,
  handleClickAgregarImplemento
}) => {
  const { t } = useTranslation();



const imagenImplementosUrLs = {
  10: '/image/utility_nfts/imp/bit.png',
  11: '/image/utility_nfts/imp/blinkers.png',
  12: '/image/utility_nfts/imp/corrective-sleeves.png',
  13: '/image/utility_nfts/imp/horsebells.png',
  14: '/image/utility_nfts/imp/martingale.png',
  15: '/image/utility_nfts/imp/noseband.png',
  16: '/image/utility_nfts/imp/race-plates.png',
  17: '/image/utility_nfts/imp/saddles.png',
};



  if (!showItemsImplementos) return null;

  return (
  <div>
   <div className="img-container-info">
  {Object.values(implementosConsumidos).map((numero, index) => (
    <div key={index} className="image-wrapper">
      <img src={imagenImplementosUrLs[numero[0]]} alt={`Imagen ${numero[0]}`} />
      <p className="usos">{numero[1] > 0 && `${numero[1]}`}</p>

    </div>
  
  ))
 }
{implementosPuntos && typeof implementosPuntos === 'number' && implementosPuntos > 0 ? (
  <h2 className="ptos_implementos" style={{ color: '#FF7F00' }}>
    +{implementosPuntos}🏇
    <span className="ptos_implemento">Pts</span>
  </h2>
) : null}


</div>
    {Object.keys(implementosConsumidos).length < 4 && implementostatus==="free" &&(
      <div>
        <select value={selectedItem?.name} onChange={handleSelectChange}>
          <option value="">{t('info.Seleccionar')}</option>
          {utilityNfts.map(item => (
            <option key={item.id} value={item.name}>
              {item.name} - {item.id}
            </option>
          ))}
        </select>
        <button onClick={handleAplicarItem1Click}>{t('info.aplicarItem')}</button>
      </div>
    )}
  </div>

  );
};






ImplementoSelector.propTypes = {
  utilityNfts: PropTypes.array.isRequired,
  implementosConsumidos: PropTypes.array.isRequired,
  implementosPuntos: PropTypes.number.isRequired,
  implementostatus: PropTypes.string.isRequired,
  selectedItem: PropTypes.object,
  showItemsImplementos: PropTypes.bool.isRequired,

  handleSelectChange: PropTypes.func.isRequired,
  handleAplicarItem1Click: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setShowItemsImplementos: PropTypes.func.isRequired,
  handleClickAgregarImplemento: PropTypes.func.isRequired
};

export default ImplementoSelector;
