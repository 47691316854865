import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,

} from "react-router-dom";


import App from './App';
import MainPre from "./components/main/Main_pre";
import MainDash from "./components/main/Main_Dash.jsx";
import MainAuction from "./components/main/Main_auc.jsx";
import MainMarket  from "./components/main/Market.jsx";
// import MainStatistic  from "./components/main/Main_Statistic.jsx";
import Main_race from"./components/main/Main_race.jsx";


import ErrorPage from "./components/error/errorPage.jsx";




const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage />,
  children: [
        {
    path: "/dashboard",
    element: <MainDash/>,
  
  },
     {
    path: "/",
    element: <MainPre/>,
  
  }, 

  // {
  //   path: "/statistics",
  //   element: <MainStatistic/>,
  
  // },
  {
    path: "/market",
    element: <MainMarket/>,
  
  },
  {
    path: "/races",
    element: <Main_race/>,
  
  },
    ],

  },
 
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
