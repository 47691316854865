import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CopyLinkButton = () => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const user=localStorage.getItem("user")

  const copyToClipboard = () => {
    const linkToCopy = `https://www.blockhorseracing.com/?referred=${user}`; // Reemplaza esto con tu enlace real
    navigator.clipboard.writeText(linkToCopy).then(() => {
      setCopied(true);
    });
  };

  return (
    <div>
      <button onClick={copyToClipboard}>
        {copied ? `${t("refer.enlaceCopy")}` : `${t("refer.enlace")}`}
      </button>
    </div>
  );
};

export default CopyLinkButton;
