import React from "react";
import './cofreclaim.css'
import { useTranslation } from 'react-i18next';

const images = {
  1: ['https://www.blockhorseracing.com/image/patrocinio/patro1.png'],
  2: ['https://www.blockhorseracing.com/image/patrocinio/patro2.png'],
  3: ['https://www.blockhorseracing.com/image/patrocinio/patro3.png'],
  4: ['https://www.blockhorseracing.com/image/utility_nfts/imp/saddles.png'],
  5: ['https://www.blockhorseracing.com/image/utility_nfts/imp/saddles.png','https://www.blockhorseracing.com/image/utility_nfts/imp/saddles.png'],
  6: ['https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png','https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png'],
  7: ['https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png','https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png','https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png','https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png'],
  8: ['https://www.blockhorseracing.com/image/magic_nfts/recover/vitamins.png','https://www.blockhorseracing.com/image/magic_nfts/recover/vitamins.png'],
  9: ['https://www.blockhorseracing.com/image/magic_nfts/recover/medical_visit.png'],
  10:['https://www.blockhorseracing.com/image/magic_nfts/recover/medical_visit.png','https://www.blockhorseracing.com/image/magic_nfts/recover/medical_visit.png'],
  11:['https://www.blockhorseracing.com/image/magic_nfts/recover/resurrection.png'],
  
  12: ['https://www.blockhorseracing.com/image/patrocinio/patro12.png'],
  13: ['https://www.blockhorseracing.com/image/patrocinio/patro13.png'],
  14: ['https://www.blockhorseracing.com/image/patrocinio/patro14.png'],
  15: ['https://www.blockhorseracing.com/image/patrocinio/patro15.png'],
  16: ['https://www.blockhorseracing.com/image/patrocinio/patro16.png'],
  17: ['https://www.blockhorseracing.com/image/patrocinio/patro17.png'],
  18: ['https://www.blockhorseracing.com/image/patrocinio/patro18.png'],
  19:['https://www.blockhorseracing.com/image/magic_nfts/trampa/buzzer.png'],
  20:['https://www.blockhorseracing.com/image/magic_nfts/trampa/buzzer.png','https://www.blockhorseracing.com/image/magic_nfts/trampa/buzzer.png'],
  21:['https://www.blockhorseracing.com/image/magic_nfts/trampa/magic.png'],
  22:['https://www.blockhorseracing.com/image/magic_nfts/trampa/doped.png'],
  23:['https://www.blockhorseracing.com/image/especial_nfts/commonmagickey.png'],
  24:['https://www.blockhorseracing.com/image/especial_nfts/raremagickey.png'],
  25:['https://www.blockhorseracing.com/image/especial_nfts/epicmagickey.png'],
  26:['https://www.blockhorseracing.com/image/especial_nfts/commonmagicchest.png'],
  27:['https://www.blockhorseracing.com/image/especial_nfts/raremagicchest.png'],
  28:['https://www.blockhorseracing.com/image/especial_nfts/epicmagicchest.png'],
  29: ['https://www.blockhorseracing.com/image/patrocinio/patro29.png'],
  30: ['https://www.blockhorseracing.com/image/patrocinio/patro30.png'],
  31:['https://www.blockhorseracing.com/image/especial_nfts/epickey.png'],
  32:['https://www.blockhorseracing.com/image/especial_nfts/commonchest.png'],
  33:['https://www.blockhorseracing.com/image/especial_nfts/rarechest.png'],
  34:['https://www.blockhorseracing.com/image/especial_nfts/epicchest.png'],
  35: ['https://www.blockhorseracing.com/image/patrocinio/patro35.png'],
  36: ['https://www.blockhorseracing.com/image/patrocinio/patro36.png'],
  37: ['https://www.blockhorseracing.com/image/patrocinio/patro37.png'],
  38: ['https://www.blockhorseracing.com/image/patrocinio/patro38.png'],
  39: ['https://www.blockhorseracing.com/image/patrocinio/patro39.png'],
  40: ['https://www.blockhorseracing.com/image/patrocinio/patro40.png'],
  41: ['https://www.blockhorseracing.com/image/patrocinio/patro41.png'],
  
  42:['https://www.blockhorseracing.com/image/magic_nfts/bree/common_breeding.png'],
  43:['https://www.blockhorseracing.com/image/magic_nfts/bree/uncommon_breeding.png'],
  44:['https://www.blockhorseracing.com/image/magic_nfts/bree/rare_breeding.png'],
  45:['https://www.blockhorseracing.com/image/magic_nfts/bree/spectral_breeding.png'],
  46:['https://www.blockhorseracing.com/image/magic_nfts/bree/epic_breeding.png'],
  47:['https://www.blockhorseracing.com/image/magic_nfts/bree/mythical_breeding.png'],
  48: ['https://www.blockhorseracing.com/image/patrocinio/patro48.png'],
 
  };







function getImages(valor) {
  return images[valor] || [];
      
}

function Divs({ cofresPatroProcesando }) {
  const { t } = useTranslation();
  return (
    <>
      {Object.keys(cofresPatroProcesando).map((divId) => {
         
        const valor = cofresPatroProcesando[divId];

        const images = getImages(valor);
        const numeroCofre = parseInt(divId) + 1;

        return (<>
          <div key={divId} className="modales-conten">
            <h3>{t('cofres.cofres')} {numeroCofre -1}  </h3>
            </div>
            <div key={divId} className="modales-conten">
            {images.map((rutaImagen) => (
              <img key={rutaImagen} src={rutaImagen} alt="" />
            ))}
          </div>
          </>
        );
      })}
    </>
  );
}

function ModalPatro({ isOpenP, onCloseP, cofresPatroProcesando }) {
  console.log('Modal renderizado',cofresPatroProcesando);
  console.log("Y",isOpenP);
  
  if (!isOpenP) return null;

  return (
    <div className="modales">
      <div className="modales-content">
        <button onClick={onCloseP}><h1>X</h1></button>
        <Divs cofresPatroProcesando={cofresPatroProcesando} />
      </div>
    </div>
  );
}

export default ModalPatro;



