import React from 'react'

import './cardOne.css'
import LoginButton from'../../../../header/components/LoginButton.jsx'

function card(props) {
  return (
    <div className="wrapp">
     <div className="container">
      <Card
        img={props.imagen}
        className="card__image"
        title="King Title"
        description={props.description}
        login={props.login}
         
      />
        


    </div></div>
  );
}

function Card(props) {
  return (
   <div className="card">
    <div className="box">
      <div className="content">
        <h2>{props.title}</h2>
        <img src={props.img} alt="Kings"  />
        <p>{props.description}</p>
        
       
        <input type="hidden" name="merchant" value="ihalangels"/>
       <input type="hidden" name="merchant_name" value="ihalangels"/>
       <input type="hidden" name="merchant_image" value={props.merchant_image}/>
       <input type="hidden" name="merchant_email" value="ihalangel@gmail.com"/>
  <input type="hidden" name="merchant_memo" value={props.merchant_memo}/>
  <input type="hidden" name="base_currency" value={props.base_currency}/>
  <input type="hidden" name="pay_currency" value={props.pay_currency}/>
  <input type="hidden" name="amount" value={props.amount}/>
  <input type="hidden" name="item_name" value={props.item_name}/>
  <input type="hidden" name="item_image" value={props.item_image}/>
  <input type="hidden" name="item_tax" value={props.item_tax}/>
  <input type="hidden" name="quantity" value={props.quantity}/>
  <input type="hidden" name="description" value={props.description}/>
  <input type="hidden" name="notify_url"  value="https://www.sistemasoftware.com/payhive"/>
  <input type="hidden" name="return_url"  value="https://www.sistemasoftware.com/"/>
  <input type="hidden" name="cancel_url"  value="https://www.sistemasoftware.com/"/>
  <input type="hidden" name="cancel_url_name" value="Cancelar Ballos Buy"/>
  <input type="hidden" name="background"  value="medium"/>
  <hr/>
  
 <p>{props.login}</p>
  <LoginButton/>
  
 
      </div>
    </div>
    </div>
  );
}

export default card
