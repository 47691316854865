import React, { useState, useEffect } from "react";
import './components/utility_nfts.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import iconNegocios from'./../../assets/home-broker-dealer-icon.svg';
import iconKey from'./../../assets/components/icons/keyb.png';
import Modal  from './components/modals/cofreclaimagic.jsx';
import { linkApp } from "./../generals/configuracion/variablesPublicas.jsx";

const handlebuyKeyMagic = async (data,amounte) => {
let memo=`Magic-Chest key Magico`
let toUsername="bhr-chest"
let username=localStorage.getItem("user");
let amount=amounte




if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount, memo, 'BHR', (response) => {
    console.log("RES KeyC",response);
    axios.post(`/keychaintrx_chest`, response) 
      .then(res => {
     })
      .catch(error => {
        console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} else {
  alert('You do not have hive keychain installed');
}

};



const handlebuyBHRT = async (data,amounte) => {
let memo=`Magic-Chest Magico BHRT`
let toUsername="bhr-chest"
let username=localStorage.getItem("user");
let amount=amounte




if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount, memo, 'BHRT', (response) => {
    console.log("RES KeyC",response);
    axios.post(`/keychainchestbhrt`, response) 
      .then(res => {
     })
      .catch(error => {
        console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} else {
  alert('You do not have hive keychain installed');
}

};











function Cofresmagicos() {
   const [cofresmProcesandon, setCofresmProcesandon] = useState({});
  const [cofresmDisponibles, setCofresmDisponibles] = useState(null);
  const [llavesmDisponibles, setLlavesmDisponibles] = useState(null);
  const [cofresmAReclamar, setCofresmAReclamar] = useState(null);
  
  const [statuscofres, setStatuscofres] = useState(null);
  const { t } = useTranslation();

useEffect(() => {
  async function fetchData() {
    let username = localStorage.getItem("user");
    const response = await axios.get(`${linkApp}/payhive?user_cofres_m=${username}`);
    console.log("console responseSSSS", response.data)
    setCofresmDisponibles(response.data.body.cofres_m_disponibles);
    setLlavesmDisponibles(response.data.body.llaves_m_disponibles);
    setCofresmAReclamar(response.data.body.cofres_m_a_reclamar);
    setCofresmProcesandon(response.data.body.cofres_m_procesando);
    setStatuscofres(response.data.body.status);
  }
  fetchData();
}, []);






return (   
  <div className="wrapp">
<div class="cofres-disponibles">

  <button type="submit" class="btn-1" data-text={t('cofres.negociacion')}>
    <p>{cofresmDisponibles}</p>
    <img src={iconNegocios} alt="Key" className="icono" />
  
  </button>
  <button type="submit" class="btn-2" data-text={t('cofres.llaves')}>
   <p>{llavesmDisponibles}</p>
    <img src={iconKey} alt="key" className="icono" style={{width: "25px", height: "25px"}}  /> 
   
  </button>
  <button type="submit" class="btn-3" data-text={t('cofres.disponibles')}>
  <p>{cofresmAReclamar}</p>
    <img src="image/cofres/comunM.jpg" alt='fefe' className="icono" style={{width: "25px", height: "25px"}} />
    
  </button>
   {  cofresmProcesandon >= 1 && (
        <button type="submit" class="btn-3" data-text={t('cofres.disponibles')}>
          <p>{cofresmProcesandon}</p>
          <img src="image/cofres/comunM.jpg" alt='Comun' className="icono" style={{width: "25px", height: "25px"}} />
        </button>
      )}
</div>
  





      <div className="container">
      <Card
        link={t('wp.cofresMagicos')}
        img="image/cofres/comunM.jpg"
        className="cardPs__image"
       
        description="Common chest"
    
        amount_bhrt="1250.000"
        amount_bhr="0.100"
        item_name="Common chest"
        item_image="https://www.blockhorseracing.com/image/cofres/comunM.jpg"
        quantity="1"
    
        cofresareclamar={cofresmAReclamar}
        reclamo="1"
          />



      <Card
        link={t('wp.cofresMagicos')}
        img="image/cofres/rareM.jpg"
        className="nidea"
        description="Rare chest"
        amount_bhrt="3750.000"
        amount_bhr="0.300"
        item_name="Rare Chest"
        item_image="https://www.blockhorseracing.com/image/cofres/rareM.jpg"
        quantity="1"
        cofresareclamar={cofresmAReclamar}
        reclamo="3" 
      />

      <Card
        link={t('wp.cofresMagicos')}
        img="image/cofres/epicM.jpg"
        className="cardPs__image"
      
        description="Epic Chest"
   
        amount_bhrt="6250.000"
        amount_bhr="0.500"
        item_name="Epic Chest"
        item_image="https://www.blockhorseracing.com/image/cofres/epicM.jpg"
        quantity="1"
    
        cofresareclamar={cofresmAReclamar}
        reclamo="5"
      />





    
      
   </div></div>
  );
}


function Card(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [cofresmProcesando, setCofresmProcesando] = useState({});
     const { t } = useTranslation();



const onClose = () => {
        setIsOpen(!isOpen);
  };

function handleClaiMagic(reclamo, cofresmAReclamar) {
  let username=localStorage.getItem("user");
 // setCofresmProcesando({1:1,2:2,3:3,4:4,5:5,6:6,7:7,8:8,9:9,10:10,11:11,12:12,13:13,14:14,15:15,16:16,17:17,18:18,19:19,20:20,21:21,22:22,23:23,24:24,25:25,26:26});
 //  setIsOpen(!isOpen);

  if (cofresmAReclamar >= reclamo) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_cofres: { user_claim: username, cofres: reclamo }})
    };
    fetch(`./chest_claimagic?user_cofres`, requestOptions)
      .then(response => response.json())
      .then(data => {console.log(data)
      if(data.error){
        alert(data.error);
      }else{
      setCofresmProcesando(data.body.cofres_m_obtenidos);
        setIsOpen(!isOpen);}
        })
      .catch(error => console.log(error));
  } else {
   alert(`${t("cofres.AlertaNoTienes")} ${reclamo} ${t("cofres.cofres")} `);
  }
}



   return (
    <>
  <div className="card">
    <div className="box-nft">
      <div className="content">
      <h5>  Magic Chest</h5> <a href={props.link} style={{ background: 'gold', borderRadius: '5px', color: 'black', padding: '5px', display: 'inline-block', float: 'right' }} title="More Info" target="_blank">
?
</a>
        <h2>{props.title}</h2>
        <img src={props.img} alt="Horses" className="cajas" />
        <p>{props.description}</p>
      
    
  <button type="submit">
  <img src={iconNegocios} alt={props.name} className="icono" style={{width: "25px", height: "25px"}}   onClick={() => handlebuyBHRT(props.item_name, props.amount_bhrt)
  }/>
</button>

 <button type="submit">
<img src={iconKey} alt={props.name} className="icono" style={{width: "25px", height: "25px"}}  onClick={() => handlebuyKeyMagic(props.item_name, props.amount_bhr)
  }/> 
</button>
 <button type="submit">
<img src="image/cofres/comun.jpg" alt={props.name} className="icono" style={{width: "25px", height: "25px"}} onClick={() => handleClaiMagic(props.reclamo,props.cofresareclamar)
  }/> 


</button>
               


 
      </div>

    </div>
      
    </div>
    <Modal isOpen={isOpen} onClose={onClose} cofresProcesando={cofresmProcesando} /> 
   </>  
  );
}

export default Cofresmagicos