import { useContext , useEffect } from "react"; 
import ThemeContext from "../context/ThemeContext";
import './main.css'
import Home from "./componets/home/Home.jsx"
import Presales from "./componets/presales/Presales.jsx"
import { useTranslation } from 'react-i18next';
import RaceScreen from './componets/dashboard/components/race_open_bets/RaceScreen.jsx'
const MainContext = () => {

  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const auth = localStorage.getItem('auth')
  const user = localStorage.getItem("user")

useEffect(() => {
  // Función para obtener los parámetros de la URL
  const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      referred: params.get('referred') || '', // Obtener el valor de 'referred' de la URL o establecerlo como cadena vacía por defecto
    };
  };

  // Obtener los parámetros de la URL cuando se carga la página
  const urlParams = getUrlParams();

  // Guardar 'referred' en el localStorage
  localStorage.setItem('referred', urlParams.referred);
}, []);


  return (
    <main className={theme}>
      <div className="containermain">
        {auth ? <p>{t('hello')} {user} </p> : <p className={theme}>{t('welcome')}</p>}
        <RaceScreen/>
        {auth ? <Presales/> : <Home/>}
        
      </div>
    </main>
  );
};

export default MainContext;
