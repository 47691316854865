// import React from 'react';

// import Icon from './../../../../../../generals/svgiconos/svgiconosenreact.jsx';
// import { useTranslation } from 'react-i18next';
// import './tarjetacaballocss/SaludBar.css'; // Asegúrate de tener este archivo CSS

// const SaludBar = ({ animo }) => {
//    const { t } = useTranslation();

// const getColor = (animo) => {
//     if (animo >= 100) return '#00ff00'; // Verde
//     if (animo >= 70) return '#ffff00';  // Amarillo
//     if (animo >= 50) return '#ffaa00';  // Anaranjado
//     if (animo >= 40) return '#ff0000';  // Rojo
//     return '#800080'; // Morado
//   };  
//   return (
//     <div className="salud">
//       <div className="barra-container">
//         <div
//           className="barra"
//           style={{ width: `${animo}%`, backgroundColor: getColor(animo) }}
//         >
//           <Icon name="animo" width="2rem" height="2rem" className="svg" />
//           <p>&nbsp;{animo}%</p>
//           <span>{t('info.salud')}</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SaludBar;







// import React from 'react';
// import Icon from './../../../../../../generals/svgiconos/svgiconosenreact.jsx';
// import { useTranslation } from 'react-i18next';
// import './tarjetacaballocss/SaludBar.css'; // Asegúrate de tener este archivo CSS

// const SaludBar = ({ animo }) => {
//   const { t } = useTranslation();

//   const getColor = (animo) => {
//     if (animo >= 100) return '#00ff00'; // Verde
//     if (animo >= 70) return '#ffff00';  // Amarillo
//     if (animo >= 50) return '#ffaa00';  // Anaranjado
//     if (animo >= 40) return '#ff0000';  // Rojo
//     return '#800080'; // Morado
//   };

//   return (
//     <div className="salud">
//       <div className="barra-container">
//         <div className="barra" style={{ width: `${animo}%`, backgroundColor: getColor(animo) }}>
//           <Icon name="animo" className="svg" />
//           <p>&nbsp;{animo}%</p>
//           <span>{t('info.salud')}</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SaludBar;





import React from 'react';
import Icon from './../../../../../../generals/svgiconos/svgiconosenreact.jsx';
import { useTranslation } from 'react-i18next';
import './tarjetacaballocss/SaludBar.css';

const SaludBar = ({ animo }) => {
  const { t } = useTranslation();

  const getColor = (animo) => {
    if (animo >= 100) return '#00ff00'; // Verde
    if (animo >= 70) return '#ffff00';  // Amarillo
    if (animo >= 50) return '#ffaa00';  // Anaranjado
    if (animo >= 40) return '#ff0000';  // Rojo
    return '#800080'; // Morado
  };

  return (
    <div className="salud">
      <div className="barra-container">
        <div className="barra" style={{ width: '100%' }}>
          <div style={{ width: `${animo}%`, backgroundColor: getColor(animo), display: 'flex', alignItems: 'center', padding: '0.5rem' }}>
            <Icon name="animo" className="svg" />
            <p>&nbsp;{animo}%</p>
            <span>{t('info.salud')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaludBar;
