
import React, { useState, useEffect } from "react";
 import './UtilityGroupModal.css'
import hivelogo from'./../../../../assets/logoblockhorseracing/logoHive.png'; 
 import SelectedItems from "./SelectedItems.jsx";
 import { useTranslation } from 'react-i18next';

function UtilityGroupModal({ groupName, items, onAddToCart, selectedItems, handleBuy, handleRemoveFromCart,volver }) {
  
    const { t } = useTranslation();
  const [selectedToken, setSelectedToken] = useState('SWAP.HIVE');
  const [price, setPrice] = useState('');




  const handleAddToCart = (id, price,symbol,name) => {
    onAddToCart(id, price,symbol,name);
  };

  const unselectedItems = items.filter((item) => {
    return !selectedItems.find((selectedItem) => selectedItem.id === item.nftId);
  });







const handlePriceChange = (event) => {
  const inputRegex = /^\d*\.?\d*$/; // Expresión regular para validar números y punto decimal
  const inputValue = event.target.value;

  if (inputValue === '' || inputRegex.test(inputValue)) {
    setPrice(inputValue);
  }
};




const handleSellOrder = async (token, precio,nftId) => {
  const nftSymbol = 'BHREQT';
  const nfts = [String(nftId)];// El ID del NFT que se quiere vender
  console.log("ID DEL nft a Vender",nfts)
  console.log(typeof(precio))
  console.log(token)
  const price = parseFloat(precio).toFixed(3); // El precio al que se quiere vender el NFT
  const priceSymbol = token;
  const fee = 750;
  let username=localStorage.getItem("user");




if (window.hive_keychain) {
  const keychain = window.hive_keychain;
    const customJson = [{
      contractName: 'nftmarket',
      contractAction: 'sell',
      contractPayload: {
        symbol: nftSymbol,
        nfts,
        price,
        priceSymbol,
        fee,
      },
      memo: "Sold on BHR Market",
  
    }];

  keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Transfer NFTs', response => {
    console.log('Response from Hive Keychain:', response);
    // axios.post('./keychaintrx_chest', response)
    //   .then(res => {
    //     console.log('Response from server:', res);
    //   })
    //   .catch(error => {
    //     console.log('Error:', error);
    //   });
  });
} else {
  alert('You do not have hive keychain installed');
}


};






return (
  <div className="utility-group-modalo" >
  <div>
  <div className="titulo-modalo">
     
      <h2 style={{ color: 'goldenrod', paddingLeft: '5em' }}>Total Items: {unselectedItems.length}</h2>


     
    </div>
    <div className="titulo-modalo">
        <button onClick={() => volver()}>
          <h1>⬅️{t('mercado.atras')}</h1>
        </button>

     
    </div>


</div>
    <div className="items-container">
      {unselectedItems.map((item) => (
        <div key={item.identidad} className="item-market">
          <div>
            <img
              src={`image/utility_nfts/${groupName}.png`}
              alt="imgi"
              className="ico"
              style={{ width: '4.5em', height: '4.5em' }}
            />
          </div>
          <div>
            <h4>{item.name} #{item.identidad}</h4>
            <span className="market">
              {item.symbol === "SWAP.HIVE" ? (
                <>
                  <img src={hivelogo} alt="Token" className="coin" />
                </>
              ) : (
                item.symbol
              )}
            </span>
          </div>
          <input
            type="text"
            value={price}
            onChange={handlePriceChange}
            placeholder="Price"
            className="input-price"
          />
          <button onClick={() => handleSellOrder(selectedToken, price, item.identidad)}>
            {t('mercado.vender')}🛒
          </button>
        </div>
      ))}
    </div>
  </div>
);


}
export default UtilityGroupModal;




