// App.js
import React from 'react';
import CardVentaEspecial from './CardVentaEspecial';

const Especial = () => {
  const handleBuy = (item) => {
    console.log('Item bought:', item);
    // Aquí puedes manejar la compra, como actualizar un estado global, enviar una petición a un servidor, etc.
  };

  return (
    <div>
      <h1>Limited Sale</h1>
      <CardVentaEspecial 
        imgSrc="https://via.placeholder.com/150" 
        itemName="Special Pack" 
        onBuy={handleBuy} 
      />
    </div>
  );
};

export default Especial;
