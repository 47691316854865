




import React from 'react';
import { useTranslation } from 'react-i18next';

export const TrainingModule = ({
  showItemsEntrenamiento,
  entrenamientoRecien,
  entrenamientoDisponible,
  entrenamientoGratis,
  lastEntrenamiento,
  iconCelebrate,
  iconCorriendoAndoL,
  entrenamientoNfts,
  selectedItem,
  handleSelectChangeEntrenamientoItem,
  mint,
  handleAplicarItemEntrenamiento,
  handleClickEntrenar,
}) => {
  const { t } = useTranslation();

  console.log(lastEntrenamiento);

  // Contenido básico que se muestra siempre


  // Contenido adicional según el estado de entrenamientoGratis
  let additionalContent;
  let basicContent;
  switch (entrenamientoGratis) {
    case 1:
      
     basicContent = (
    <div key='' className="image-wrappering">
      <h4 className="entrenar">{t('info.entrena')}:&nbsp;{entrenamientoDisponible}Pts</h4>
      <h4 className="entrenar">{t('info.comision')}:&nbsp;{entrenamientoDisponible / 10}BHR</h4>
      <img src={iconCorriendoAndoL} className="entrenamiento" alt={`Imagen`} />
      <button onClick={handleClickEntrenar} className="boton-entrenar-modulo">{t('info.entrena')}</button>
      <h4>{t('info.free')}</h4>
    </div>
  );
      break;
    case 2:
      additionalContent = <h4>{t('info.entrenamientoDone')}</h4>;
     basicContent = (
    <div key='' className="image-wrappering">
      <h4 className="entrenar">Training:&nbsp;{lastEntrenamiento[0].habilidad}</h4>
      <h4 className="entrenar">Points:&nbsp;{(lastEntrenamiento[0].nuevosPuntos - lastEntrenamiento[0].puntosAnteriores).toFixed(5)}</h4>
      <img src={iconCelebrate} className="entrenamiento" alt={`Imagen`} />
    <h4>{t('info.entrenamientoDone')}</h4>
    </div>
  );
      break;
    case 5:
      
       basicContent = (
    <div key='' className="image-wrappering">
      <h4 className="entrenar">{t('info.entrenamientOnProgress')}</h4>
      <img src={iconCorriendoAndoL} className="entrenamiento" alt={`Imagen`} />
     
    </div>
  );
      break;
    case 0:
     basicContent = (
    <div key='' className="image-wrappering">
      <h4 className="entrenar">{t('info.entrena')}:&nbsp;{entrenamientoDisponible}Pts</h4>
      <h4 className="entrenar">{t('info.comision')}:&nbsp;{entrenamientoDisponible / 10}BHR</h4>
      <img src={iconCorriendoAndoL} className="entrenamiento" alt={`Imagen`} />
      <button onClick={handleClickEntrenar} className="boton-entrenar-modulo">{t('info.entrena')}</button>
    </div>
  );
      break;
    default:
      additionalContent = null;
  }

  return (
    <>
      {showItemsEntrenamiento && (
        <div className="img-container-info">
          {entrenamientoRecien ? (
            <div className="image-wrappering">
                   <h4 className="entrenar">{t('info.entrenamientOnProgress')}</h4>
            
              <img src={iconCorriendoAndoL} className="entrenamiento" alt={`Imagen`} />
            </div>
          ) : (
            <>
              {basicContent}
            </>
          )}
          <div className="image-wrappering">
            <select value={selectedItem?.name} onChange={handleSelectChangeEntrenamientoItem}>
              <option value="">{t('info.Seleccionar')}</option>
              {entrenamientoNfts.map(item => (
                <option key={item.id} value={item.name}>
                  {item.name} - {item.id}
                </option>
              ))}
            </select>
            <div>
              <button onClick={(event) => handleAplicarItemEntrenamiento(event)}>{t('info.aplicarItem')}</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainingModule;
