import React, { useState, useEffect } from "react";
import axios from 'axios';


import iconNegocios from'./../../../../assets/home-broker-dealer-icon.svg'
import iconCarrera from'./../../../../assets/horse-riding-icon.svg'
import infoballo from'./../../../../assets/horseshoe-icon.svg'
import ModalBallo from"./../../components/models/InfoballosModalP.jsx"
import ModalRace from"./../../components/models/RaceBalloModal.jsx"
import ModalNegoti from"./../../components/models/NegotiationModal.jsx"
// import  "./../../components/models/infoballoModal.css"
import EquineImage from'./../../../generals/herramientas/imagenesCaballos.jsx'; 


import Loader from"./../../../generals/loders/Loader.jsx"
//Impor de iconos SVG para infoballo
import salucita from"./../../../../assets/components/svg/salud/heart-svgrepo-com.svg"
import temperatura from"./../../../../assets/components/svg/temperamentp/calmness-svgrepo-com.svg"
import { useTranslation } from 'react-i18next';
import './../../components/cardballos.css'



 //key, Imagen, name,sex,salud,resiste,addResi,Resistencia,addResistencia 


let initialValue=false


function traducirTipo(tipo) {
  switch (tipo) {
    case 'F':
      return 'Ready';
    case 'R':
      return 'Resting';
    case 'S':
      return 'Waiting Sell';
    case 'I':
      return 'Registered';
    case 'P':
      return 'Pending registration';
    default:
      return 'Unknown';
  }
}

export default function CaballosEnWallet({...props}) {
    
    const [isOpenInf, setIsOpenInf] = useState(initialValue);
   const [selectedId, setSelectedId] = useState(null);
     const { t } = useTranslation();
   const alimentosCount = props.alimentos.length;

     // Define emojis
  const greenApple = "🍏";
  const redApple = "🍎";

  // Choose emoji based on the number of elements in the alimentos array
  const appleEmoji = alimentosCount >= 3 ? redApple : greenApple;
  //console.log("propsporpsoppsopspops",props)
  console.log("propsporpsoppsopspopsKEY",props.key)
  console.log("propsporpsoppsopspopsTIPO",props.tipo)


  const openModalInf = (id) => { setIsOpenInf(true);
    setSelectedId(id);

   // console.log("ABIERTO CABALLO EN MODAL INFO",id)
  }

  const closeModalInf = () => setIsOpenInf(false);



  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);


   const [isOpenNeg, setIsOpenNeg] = useState(initialValue);

  const openModalNeg = () => setIsOpenNeg(true);

  const closeModalNeg = () => setIsOpenNeg(false);



   return (
    <div className="card">
  {props.identidad == "???" || props.identidad === [] ? (
  <>
  <div className="card">
    <div className="box">
      <div className="content">
        <h2>{props.key}AQUI VOY</h2>
         <div className="cajo" style={{backgroundImage: `url("https://ipfs.io/ipfs/bafybeibydhcgj6ibcmdx2mo3vkktlo4chxuhyec3gqah3ojkxqvw5xh7ye")`}}>
        {/*  <img src={props.Imagen} alt="img" className="cajas" />  
                    <EquineImage
          mainImageSrc={props.Imagen}
          backupImageSrc={`/image/equinos/000.png`}
          isHovered={false}
        
        />

                  <EquineImage
          mainImageSrc={`/image/equinos/${props.identidad}`}
          backupImageSrc={`/image/equinos/000.png`}
          isHovered={false}
        
        />

        */}
         </div>
        <p><h4>{t('Caballos.parece')}</h4></p>

     
           <a href={`/`}
      style={{
        color: '#FF0000',
        textDecoration: 'none',

      }}
    >
      
     
      <span className="arrow arrow1">{'>'}</span>
       <span className="arrow arrow2">{'>'}</span>
        <span className="arrow arrow3">{'> '} &nbsp; </span>
       <span className="arrow arrow">{t('Caballos.ve_por_uno')}</span> 
         <span className="arrow arrow1"> &nbsp;{' <'}</span>
       <span className="arrow arrow2">{'<'}</span>
        <span className="arrow arrow3">{'<'}</span>
    </a>
      
    </div>
  </div>
 </div>



</>
  
) : 


    <div className="box">
    
      <div className="content">

        <h2>{props.key}</h2>
    <div className="caja" style={{backgroundImage: `url("/image/backgroundballos/fondoballo.png")`}}>
    
  {/* <img src={props.IPFS} alt="img" className="cajas" /> */}
       <EquineImage
          mainImageSrc={`/image/equinos/${props.identidad}.png`}
          backupImageSrc={props.IPFS}
          isHovered={false}
        
        />

      </div><span>{traducirTipo(props.status)}</span>
         {alimentosCount > 0 && (
        <span role="img" aria-label={`${props.alimentos_ptos} Pts`}>
          {appleEmoji}
        </span>
      )}
 
        <p>{props.name}</p>
        <p><span>Mint : </span>{props.identidad}   </p>
         <h2>{props.key}</h2>
    
    
        {/*  <img src={iconNegocios} alt={props.name} className="icono" onClick={openModalNeg} /> 
         <img src={iconNegocios} alt={props.name} className="icono-caballos" title="Negotiate with your NFT" onClick={openModalNeg}/>
      
*/}

       <img src={iconCarrera} alt={props.name} className="icono-caballos" onClick={openModal} title="Find races for your horse"/>
      
      
  
        <img src={infoballo} alt={props.name} className="icono-caballos"  onClick={() => openModalInf(props.identidad)} title="NFT information"/>

  
      </div>
    </div> }
         {isOpenInf && (  <ModalBallo isOpen={isOpenInf} closeModalInf={closeModalInf}   propsi={props}  selectedId={selectedId}    onClose={() => setIsOpenInf(false)}   >

         <button className="modal-close-info" onClick={closeModalInf}>
        X
        </button>


    </ModalBallo> )}



   <ModalRace isOpen={isOpen} closeModal={closeModal}  propsi={props}>
      
    </ModalRace>

   {/*
    <ModalNegoti isOpenNeg={isOpenNeg} closeModalNeg={closeModalNeg}  propsi={props}>
   
      
    </ModalNegoti>*/}
    </div>

  );
}




    


































