import { useContext } from "react";



import './header.css'
import Navbar from './components/Navbar.jsx'
import Initbar from './components/Initbar.jsx'
import "./components/navbar.css"
import ThemeContext from "../context/ThemeContext";



const HeaderContext = () => {
 const { theme } = useContext(ThemeContext);
 

  return (
  
    <header className={theme}>
    <Initbar theme={theme}/>
    <Navbar theme={theme}/>


    </header>
  );
};

export default HeaderContext;
