import React from "react";
import './cofreclaim.css'
import { useTranslation } from 'react-i18next';

const images = {
  1: ['https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  2: ['https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  3: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png'],
  4: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png'],
  5: ['https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  6: ['https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  7: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  8: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  9: ['https://www.blockhorseracing.com/image/magic_nfts/recover/parasiticides.png'],
  10:['https://www.blockhorseracing.com/image/magic_nfts/add/add_10_races.png'],
  11:['https://www.blockhorseracing.com/image/magic_nfts/recover/vitamins.png'],
  12:['https://www.blockhorseracing.com/image/magic_nfts/add/add_25_races.png'],
  13:['https://www.blockhorseracing.com/image/utility_nfts/imp/saddles.png'],
  14:['https://www.blockhorseracing.com/image/magic_nfts/trampa/buzzer.png'],
  15:['https://www.blockhorseracing.com/image/magic_nfts/recover/medical_visit.png'],
  16:['https://www.blockhorseracing.com/image/magic_nfts/add/add_50_carreras.png'],
  17:['https://www.blockhorseracing.com/image/magic_nfts/trampa/magic.png'],
  18:['https://www.blockhorseracing.com/image/magic_nfts/trampa/doped.png'],
  19:['https://www.blockhorseracing.com/image/magic_nfts/recover/resurrection.png'],
  20:['https://www.blockhorseracing.com/image/magic_nfts/bree/common_breeding.png'],
  21:['https://www.blockhorseracing.com/image/magic_nfts/bree/uncommon_breeding.png'],
  22:['https://www.blockhorseracing.com/image/magic_nfts/bree/rare_breeding.png'],
  23:['https://www.blockhorseracing.com/image/magic_nfts/bree/spectral_breeding.png'],
  24:['https://www.blockhorseracing.com/image/magic_nfts/bree/epic_breeding.png'],
  25:['https://www.blockhorseracing.com/image/magic_nfts/bree/mythical_breeding.png'],
  26:['https://www.blockhorseracing.com/image/magic_nfts/bree/legendary_breeding.png'],


 
};


function getImages(valor) {
  return images[valor] || [];
      
}

function Divs({ cofresProcesando }) {
  const { t } = useTranslation();
  return (
    <>
      {Object.keys(cofresProcesando).map((divId) => {
         
        const valor = cofresProcesando[divId];

        const images = getImages(valor);
        const numeroCofre = parseInt(divId) + 1;

        return (<>
          <div key={divId} className="modales-conten">
            <h3>{t('cofres.cofres')} {numeroCofre -1}  </h3>
            </div>
            <div key={divId} className="modales-conten">
            {images.map((rutaImagen) => (
              <img key={rutaImagen} src={rutaImagen} alt="" />
            ))}
          </div>
          </>
        );
      })}
    </>
  );
}

function Modal({ isOpen, onClose, cofresProcesando }) {
  console.log('Modal renderizado');
  console.log("Y",isOpen);
  
  if (!isOpen) return null;

  return (
    <div className="modales">
      <div className="modales-content">
        <button onClick={onClose}><h1>X</h1></button>
        <Divs cofresProcesando={cofresProcesando} />
      </div>
    </div>
  );
}

export default Modal;



