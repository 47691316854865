import React, { useState, useEffect } from "react";
import './components/utility_nfts.css';
import './css/cofresBases.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import iconNegocios from'./../../assets/home-broker-dealer-icon.svg';
import iconKey from'./../../assets/components/icons/keyb.png';
import Modal  from './components/modals/cofreclaim.jsx';
import { linkApp } from "./../generals/configuracion/variablesPublicas.jsx";
import CofresPatro from"./cofres_patrocinio.jsx"

const handleFetch = async (data,amounte) => {
let memo=`EQ-Chest key`
let toUsername="bhr-chest"
let username=localStorage.getItem("user");
let amount=amounte




if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount, memo, 'BHR', (response) => {
    console.log("RES KeyC",response);
    axios.post(`${linkApp}/keychaintrx_chest`, response) 
      .then(res => {
     })
      .catch(error => {
        console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} else {
  alert('You do not have hive keychain installed');
}

};











function Cofres() {
  const [cofresProcesandon, setCofresProcesandon] = useState({});
  const [cofresDisponibles, setCofresDisponibles] = useState(null);
  const [llavesDisponibles, setLlavesDisponibles] = useState(null);
  const [cofresAReclamar, setCofresAReclamar] = useState(null);
  const [CofresPatroDisponibles, setCofresPatroDisponibles] = useState(null);
  
  const [statuscofres, setStatuscofres] = useState(null);
  const { t } = useTranslation();

useEffect(() => {
  async function fetchData() {
    let username = localStorage.getItem("user");
    const response = await axios.get(`${linkApp}/payhive?user_cofres=${username}`);
    console.log("console responseSSSS", response.data)
    setCofresDisponibles(response.data.body.cofres_disponibles);
    setLlavesDisponibles(response.data.body.llaves_disponibles);
    setCofresPatroDisponibles(response.data.body.cofres_p_disponibles)
    setCofresAReclamar(response.data.body.cofres_a_reclamar);
    setCofresProcesandon(response.data.body.cofres_procesando);
    setStatuscofres(response.data.body.status);
  }
  fetchData();
}, []);









return (   
  <div className="wrapp_inventario_cofres">
     {CofresPatroDisponibles > 0 && (
    <CofresPatro cofres_p_disponibles={CofresPatroDisponibles} />
  )}
<div class="cofres-inventario_disponibles">
  <button type="submit" class="btn-1" data-text={t('cofres.negociacion')}>
     <p>{cofresDisponibles}</p>
    <img src={iconNegocios} alt="Key" className="icono" style={{width: "25px", height: "25px"}}/>
  
  </button>
  <button type="submit" class="btn-2" data-text={t('cofres.llaves')}>
   <p>{llavesDisponibles}</p>
    <img src={iconKey} alt="key" className="icono" style={{width: "25px", height: "25px"}}  /> 
   
  </button>
  <button type="submit" class="btn-3" data-text={t('cofres.disponibles')}>
  <p>{cofresAReclamar}</p>
    <img src="image/cofres/comun.jpg" alt='fefe' className="icono" style={{width: "25px", height: "25px"}} />
    
  </button>
   {  cofresProcesandon >= 1 && (
        <button type="submit" class="btn-3" data-text={t('cofres.disponibles')}>
          <p>{cofresProcesandon}</p>
          <img src="image/cofres/comun.jpg" alt='fefe' className="icono" style={{width: "25px", height: "25px"}} />
        </button>
      )}
</div>
  





      <div className="container-inventario-cofres-base">
  
      <Card
       link={t('wp.cofres')}
        img="image/cofres/comun.jpg"
        className="cardPs__image"
        title=""
        description="Common chest"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase made by HIVEBUYER"
        base_currency="USD"
        pay_currency="BUDS,SOULS,HUESO,SWAP.HIVE,SWAP.HBD,HIVE,HBD"
        amount="0.25"
        amount_bhrt="3500.000"
        amount_bhr="0.375"
        item_name="Common chest"
        item_image="https://www.blockhorseracing.com/image/cofres/comun.jpg"
        quantity="1"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
        cofresareclamar={cofresAReclamar}
        reclamo="1"
          />



      <Card
       link={t('wp.cofres')}
        img="image/cofres/epic.jpg"
        className="nidea"
        title=""
        description="Rare chest"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="BUDS,SOULS,HUESO,SWAP.HIVE,SWAP.HBD,HIVE,HBD"
        amount="0.75"
        amount_bhrt="9000.000"
        amount_bhr="1.125"
        item_name="Rare Chest"
        item_image="https://www.blockhorseracing.com/image/cofres/epic.jpg"
        quantity="1"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
        cofresareclamar={cofresAReclamar}
        reclamo="3" 
      />

      <Card
       link={t('wp.cofres')}
        img="image/cofres/legen.jpg"
        className="cardPs__image"
        title=""
        description="Epic Chest"
        merchant_image="https://www.blockhorseracing.com/image/logo/logoBlock.png"
        memo="Purchase Made By HIVEBUYER"
        base_currency="USD"
        pay_currency="BUDS,SOULS,HUESO,SWAP.HIVE,SWAP.HBD,HIVE,HBD"
        amount="1.25"
        amount_bhrt="15000.000"
        amount_bhr="1.875"
        item_name="Epic Chest"
        item_image="https://www.blockhorseracing.com/image/cofres/legen.jpg"
        quantity="1"
        notify_url="https://www.blockhorseracing.com/payhive"
        return_url="https://www.blockhorseracing.com/"
        cancel_url="https://www.blockhorseracing.com/"
        cancel_url_name="Decline Buy"
        background="medium"
        cofresareclamar={cofresAReclamar}
        reclamo="5"
      />









    
      
   </div></div>
  );
}


function Card(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [cofresProcesando, setCofresProcesando] = useState({});
    const { t } = useTranslation();


const onClose = () => {
        setIsOpen(!isOpen);
  };

function handleClaim(reclamo, cofresAReclamar) {
  let username=localStorage.getItem("user");
  if (cofresAReclamar >= reclamo) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_cofres: { user_claim: username, cofres: reclamo }})
    };
    fetch(`${linkApp}/chest_claim?user_cofres`, requestOptions)
      .then(response => response.json())
      .then(data => {console.log(data)
      if(data.error){
        alert(data.error);
      }else{
      setCofresProcesando(data.body.cofres_obtenidos);
        setIsOpen(!isOpen);}
        })
      .catch(error => console.log(error));
  } else {
    alert(`${t("cofres.AlertaNoTienes")} ${reclamo} ${t("cofres.cofres")} `);
  }
}




const handlebuyBHRT = async (data,amounte) => {
  console.log(data)
let memo=`Base-Chest BHRT`
let toUsername="bhr-chest"
let username=localStorage.getItem("user");
let amount=amounte




if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount, memo, 'BHRT', (response) => {
    console.log("RES KeyC",response);
    axios.post(`${linkApp}/keychainchestbhrt`, response) 
      .then(res => {
     })
      .catch(error => {
        console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} else {
  alert('You do not have hive keychain installed');
}

};



   return (
    <>
  <div className="card_cofres_inventario">
 
  <div className="box-nft_cofres_inventario">
  <div className="content_cofres_inventario">

  <h2>{props.title}</h2>
 <a href={props.link} style={{ background: 'gold', borderRadius: '5px', color: 'black', padding: '5px', display: 'inline-block', float: 'right' }} title="More Info" target="_blank">
?
</a>


  <img src={props.img} alt="Horses" className="cajas_cofres_inventario" />
        <p>{props.description}</p>
        <form method="post" action="https://hivepay.io/pay/">
        <input type="hidden" name="merchant" value="bhr-chest"/>
       <input type="hidden" name="merchant_name" value="bhr-chest"/>
       <input type="hidden" name="merchant_image" value={props.merchant_image}/>
       <input type="hidden" name="merchant_email" value="ihalangel@gmail.com"/>
  <input type="hidden" name="merchant_memo" value={props.merchant_memo}/>
  <input type="hidden" name="base_currency" value={props.base_currency}/>
  <input type="hidden" name="pay_currency" value={props.pay_currency}/>
  <input type="hidden" name="amount" value={props.amount}/>
  <input type="hidden" name="discount_token" value="SWAP.HIVE,HIVE"/>
  <input type="hidden" name="discount_amount" value="10"/>
  <input type="hidden" name="item_name" value={props.item_name}/>
  <input type="hidden" name="item_image" value={props.item_image}/>
  <input type="hidden" name="item_tax" value={props.item_tax}/>
  <input type="hidden" name="quantity" value={props.quantity}/>
  <input type="hidden" name="description" value={props.description}/>
  <input type="hidden" name="notify_url"  value="https://www.blockhorseracing.com/payhive" />
  <input type="hidden" name="return_url"  value="https://www.blockhorseracing.com/"/>
  <input type="hidden" name="cancel_url"  value="https://www.blockhorseracing.com/"/>
  <input type="hidden" name="cancel_url_name" value="BHR"/>
  <input type="hidden" name="background"  value="medium"/>
   {/*<button type="submit">
  <img src={iconNegocios} alt={props.name} className="icono" style={{width: "25px", height: "25px"}}/>
</button> */}
   </form>

<button type="submit">
  <img src={iconNegocios} alt={props.name} className="icono" style={{width: "25px", height: "25px"}}   onClick={() => handlebuyBHRT(props.item_name, props.amount_bhrt)
  }/>
</button>

 <button type="submit">
<img src={iconKey} alt={props.name} className="icono" style={{width: "25px", height: "25px"}}  onClick={() => handleFetch(props.item_name, props.amount_bhr)
  }/> 
</button>
 <button type="submit">
<img src="image/cofres/comun.jpg" alt={props.name} className="icono" style={{width: "25px", height: "25px"}} onClick={() => handleClaim(props.reclamo,props.cofresareclamar)
  }/> 


</button>
               


 
      </div>

    </div>
      
    
 
      

    
      
    </div>

    
    <Modal isOpen={isOpen} onClose={onClose} cofresProcesando={cofresProcesando} /> 
   </>  
  );
}

export default Cofres