import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export function changeLanguage(lang) {
  i18n.changeLanguage(lang);
}

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    es: {
      translation: {
        'hello': '¡Hola!',
        'welcome': 'Bienvenid@!',
        'model.login.typeName': 'Ingresa usuario Hive',
        'navbar.presale': 'Alfa-Venta',
        'navbar.dashboard': 'Panel',
        'navbar.resultados': 'Resultados🏆',
        'navbar.statistics':'Estadísticas',
        'navbar.market': 'Mercado',
        'inventario.inventario':'Inventario',
        'info.salud': 'Salud',
        'info.velocidad': 'Velocidad',
        'info.resistencia': 'Resistencia',
        'info.agilidad': 'Agilidad',
        'info.Carreras-Ganadas': 'Carreras ganadas',
        'info.Carreras-Segundos':'Segundos',
        'info.carreras-corridas': 'Carreras corridas',
        'changeName.costo':`Se cobrará en BHR por registro de nuevo nombre.Cada cambio de nombre aumentará en costo,Costo Actual: 0,01`,
        'changeName.change': 'Renombrar',
        'changeName.typeName': 'Nuevo Nombre',
        'info.resultados': 'Resultados',
        'info.alimentar':' 🍏 Alimentar',
        'info.entrenar':'📈Entrenar',
        'info.entrena':'Entrenar',
        'info.free':'Gratis',
        'info.comision':'Tarifa',
        'info.Seleccionar':'Selecciona',
        'info.entrenamientoDone':'Entrenamiento exitoso',
        'info.entrenamientOnProgress':'Entrenamiento en curso',
  
        'info.aplicarItem':'Aplicar Item',
        'info.entrenar-ganaste':'Sumaste',
        'info.implementos':'🏇 Implementos',
        'info.recuperar':'Recover',
        'Caballos.parece':'Parece que aun no tienes un caballo',
        'Caballos.ve_por_uno':'¡Ve por uno!',
        'Presale.compra_aqui':'¡Compra aquí tu NFT, mientras aún quedan!',
        'race.carreras-disponibles':'Mostrar Carreras',
        'cofres.AlertaNoTienes':'No tienes lo necesario para abrir',
        'cofres.negociacion':'Cofres comprados',
        'cofres.cofres':'Cofres',
        'cofres.llave':'Llaves',
        'cofres.cofresM':'Cofre Magico',
        'cofres.llaveM':'Llave Magica',
        'cofres.llaves':'Llaves disponibles',
        'cofres.disponibles':'Cofres a reclamar',
        'cofreE.cofreE':'Cofres Especiales',
        'cofreE.claim':'Abrir',
        'temporada.title':'Próxima temporada de alimentación',
        'footer.asociados':'Colaboradores Especiales:',
        'racein.filterByDate' :'🔍Por Tiempo', 
        'racein.filterByRaceId':'🔍Por ID',
        'racein.Oficiales':'🔍Carreras Oficiales',
        'racein.OficialEspera':'Espera, muy pronto sentirás la emoción de las carreras oficiales🏆. ¡Prepárate!',
        
        'racein.Comunes':'🔍Carreras Comunes',
        'resultados.lugar':'Lugar',
        'resultados.mint':'Mint',
        'resultados.total':'Total',
        'resultados.rareza':'Rareza',
        'resultados.sexo':'Sexo',
        'resultados.nombre':'Nombre',
        'resultados.propietario':'Propietario',
        'resultados.agilidad':'Agilidad',
        'resultados.resistencia':'Resistencia',
        'resultados.add':'Add',
        'resultados.velocidad':'Velocidad',
        'resultados.estadisticas':'Atributos',
        'resultados.equino':'Equino',
        'resultados.total1':'Total1',
        'resultados.salud':'Salud',
        'resultados.implementos':'Implementos',
        'resultados.divisor':'Divisor',
        'resultados.mostrar':'Mostrar Resultados',
        'resultados.nohaydato':'Sin resultados al momento',
         'tablainfoCarrera.carraraypuesto':'Carrera/Lugar',
         'login.enterreferido':'Introduce cuenta referente',
        'login.typeReferredName':'Cuenta referente',
        'login.welcome':'Bienvenid@',
        'refer.enlace':'Enlace de referido',
        'refer.enlaceCopy':'Enlace copiado',
        'racein.carrera':'Carreras',
        'racein.siguiente':'Siguiente',
        'racein.anterior':'Anterior',
        'tablaCarrera.info':'Info',
        'tablaCarrera.premios':'Premios',
        'tablaCarrera.salida':'Salida',
        'tablaCarrera.registrados':'Registrados', 
        'mercado.vender':'Vender',
        'mercado.mercado':'Mercado',
        'mercado.atras':'Atrás',
        

        'wallet.depositar':'Depositar',
        'wallet.retirar':'Retirar',
        'wallet.minado':'Minado',
        'wallet.ConfirmarRetiroBHR':'¿Estás seguro de que deseas retirar todo tu saldo en tokens BHR?',
        'wallet.ConfirmarRetiroBHRT':'¿Estás seguro de que deseas retirar todo tu saldo en tokens BHRT?',
        'wallet.retiroMinimoBHRT':'El retiro minimo debe ser superior a 10 BHRT',
        'wallet.retiroMinimoBHR':'El retiro minimo debe ser superior a 0,01 BHR',
        'wallet.claim':'cobrar',
        'wallet.profit':'Ganancia',
        'wallet.cargando':'Cargando...', 
        
        'mantenimiento.forzado':'Estamos bajo mantenimiento, Regresaremos Muy pronto con pistas en buen estado 😉',
        
        'wp.cofres':'https://docs.blockhorseracing.com/nfts/cofres', 
        'wp.cofresMagicos':'https://docs.blockhorseracing.com/nfts/cofres-magicos', 
        'wp.cofresEspeciales':'https://docs.blockhorseracing.com/nfts/cofres-especiales',
        
        'apuestas.TSupport':'Total Apoyo',
        'apuestas.Tustickets':"Your Tickets:",
        'apuestas.Payout':'Ganas: ',
        'apuestas.Pago':'Pago :',
        'apuestas.Apoyo':'Apoyo Recibido :',
        'apuestas.Per':'Por Cada : ', 
        'apuestas.Support':'Apoyar',
        'apuestas.ESupport':'Gana:',
        'pPixel.llevame':'Ir a la carrera',
        'screen.RaceOpem':'abiertas a patrocinio',
           
     } },
    en: {
      translation: {
        'hello': 'Hello!',
        'welcome': 'Welcome!',
        'model.login.typeName':'Type your username',
        'navbar.presale': 'Alpha-Sale',
        'navbar.dashboard': 'Dashboard',
        'navbar.resultados': 'Races🏆',
        'navbar.statistics':'Statistics',
        'navbar.market': 'Market',
        'inventario.inventario':'Inventory',
        'info.salud': 'Health',
        'info.velocidad': 'Speed',
        'info.resistencia': 'Endurance ',
        'info.agilidad': 'Agility ',
        'info.Carreras-Ganadas': 'Races won',
        'info.Carreras-Segundos':'Second',
        'info.carreras-corridas': 'Races run',
        'changeName.costo': 'You will be charged in BHR for registering a new name. Each name change will increase its cost. Current Cost: 0.01',
        'changeName.change': 'Rename',
        'changeName.typeName': 'type name', 'info.resultados': 'Results',
        'info.alimentar':'🍏 Feed',
        'info.entrenar':'📈Train',
        'info.entrena':'Train',
        'info.free':'Free',
        'info.comision':'Fees',
        'info.Seleccionar':'Select',
        'info.entrenamientoDone':'Training completed',
        'info.entrenamientOnProgress':'Training in progress',
        'info.aplicarItem':'Apply item',
        'info.entrenar-ganaste':'Your Add',
        'info.implementos':'🏇Tacks',
        'info.recuperar':'Recover',
        'race.carreras-disponibles':'Show Races',
        'Caballos.parece':"It seems like you don't have a horse yet",
        'Caballos.ve_por_uno':'Go get one!',
        'Presale.compra_aqui':'Buy your NFT here, while supplies last',
        'cofres.AlertaNoTienes':"You don't have what's needed to open",
        'cofres.cofres':'Chest',
        'cofres.cofresM':'Magict Chest',
        'cofres.llave':'Keys',
        'cofres.llaveM':'Magic Keys',
        'cofres.negociacion':'Purchased chests',
        'cofres.llaves':'Available keys',
        'cofres.disponibles':'Chests to claim',
        'cofreE.cofreE':'Special Chest',
        'cofreE.claim':'Claim',
        'temporada.title':'Upcoming feeding season',
        'footer.asociados':'Special collaborators :',
        'resultados.lugar':'Place',
        'racein.filterByDate' :'🔍By Date', 
        'racein.filterByRaceId':'🔍By ID',
        'racein.Oficiales':'🔍Official races',
        'racein.OficialEspera':'Wait, very soon you will feel the excitement of the official races🏆. Get ready!',
        
        'racein.Comunes':'🔍Common races',        
        'resultados.mint':'Mint',
        'resultados.total':'Total',
        'resultados.rareza':'Rarity',
        'resultados.sexo':'Gender',
        'resultados.nombre':'Name',
        'resultados.propietario':'Owner',
        'resultados.agilidad':'Agility',
        'resultados.resistencia':'Endurance',
        'resultados.add':'Add',
        'resultados.velocidad':'Speed',
        'resultados.estadisticas':'Stats',
        'resultados.equino':'Equine',
        'resultados.total1':'Total1',
        'resultados.salud':'Health',
        'resultados.implementos':'Tacks',
        'resultados.divisor':'Divisor',
        'resultados.multiplicador':'Multiplier', 
        'resultados.mostrar':'Display Results',
        'resultados.nohaydato':'Without results at the moment',
        'tablainfoCarrera.carraraypuesto':'Race/Place',
        'login.enterreferido':'Enter referrer',
        'login.typeReferredName':'Referrer acount',
        'login.welcome':'Welcome',
        'refer.enlace':'referral link',
        'refer.enlaceCopy':'link copied',
        'racein.carrera':'Races', 
        'racein.siguiente':'Next',
        'racein.anterior':'Back', 
        'tablaCarrera.info':'Info',
        'tablaCarrera.premios':'Prizes',
        'tablaCarrera.salida':'Start',
        'tablaCarrera.registrados':'Registered',
        'mercado.vender':'Sell',
        'mercado.mercado':'Market',
        'mercado.atras':'Back',
        'wallet.depositar':'Deposit',
        'wallet.retirar':'Withdraw',
        'wallet.minado':'Mining',
        'wallet.ConfirmarRetiroBHR':'Are you sure you want to withdraw your entire balance in BHR tokens?',
        'wallet.ConfirmarRetiroBHRT':'Are you sure you want to withdraw your entire balance in BHRT tokens?',
        'wallet.retiroMinimoBHRT':'The minimum withdrawal must be higher than 10 BHRT',
        'wallet.retiroMinimoBHR':'The minimum withdrawal must be higher than 0.01 BHR',
        'wallet.claim':'claim',
        'wallet.profit':'Profit',
        'wallet.cargando':'Loading...',
        'mantenimiento.forzado':'We are under maintenance. We will be back very soon with tracks in good condition 😉',
        'wp.cofres':'https://docs-en.blockhorseracing.com/nfts/chests', 
        'wp.cofresMagicos':'https://docs-en.blockhorseracing.com/nfts/magical-chests', 
        'wp.cofresEspeciales':'https://docs-en.blockhorseracing.com/nfts/special-chests',
        'apuestas.TSupport':'Total Support :',
        'apuestas.Apoyo':'Support received :',
        'apuestas.Tustickets':"Your Tickets:",
        'apuestas.Payout':'Payout :',
        'apuestas.Pago':'Payout :',
        'apuestas.Per':'Per :',
        'apuestas.Support':'Support',
        'apuestas.ESupport':'Earn:',  
        'pPixel.llevame':'Go to the race',
        'screen.RaceOpem':'Open Race Sponsorships',
      }
    }
  }
});

export default i18n;

