import React, { useState } from 'react';
import wallete from './../../../../assets/gif/bottons/wallet.gif';
import './components/Wallets/Wallet.css';
import FetchWallet from "./components/Wallets/components/fetchWallet.jsx"

const Wallet = () => {
  const [showTable, setShowTable] = useState(false);

  const handleDivClick = () => {
    setShowTable(!showTable);
  };

  return (
    <div className="wallet" >

      {showTable ? (
        <table>
       <img
  src={wallete}
  alt="boton"
  className="botton-wallet"
  onClick={handleDivClick}
  style={{
    position: 'relative',
    cursor: 'pointer',
    float: 'right',
    margin: '-10px 0px 0px 0px', // Cambia el margen para mover el botón hacia arriba
    zIndex: 999,
  }}
/>
 
          <tbody>


            <tr>
              <td>

                <FetchWallet />
                {/* Aquí puedes ajustar el valor 100 por el valor necesario para tu lógica de envío */}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <img src={wallete} alt="boton" className="botton-wallet" onClick={handleDivClick} style={{ cursor: 'pointer' }}/>
      )}
      
    </div>
  );
};

export default Wallet;


