import React, { useState , useEffect } from 'react';
import iconCorriendoAndoL from'./../../../assets/gif/running-horse-horse-racing.gif';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './loginfom.css';
import { linkApp } from './../../generals/configuracion/variablesPublicas.jsx';




const initialForm = {
  name: "",
  referred: ""
};


const LoginForm = () => {
  const { t } = useTranslation();
  const [referido, setReferido] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [form, setForm] = useState(initialForm);

 
useEffect(() => {
  // Obtener el valor de "referred" del almacenamiento local
  const storedReferred = localStorage.getItem("referred");
 

  // Verificar si hay un valor almacenado para "referred" y actualizar el estado si es necesario
  if (storedReferred) {
    setForm({
      ...form,
      referred: storedReferred
    });
 
  setReferido(false)
  }
}, [form.referred]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleBlur = (e) => {
    handleChange(e);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    console.log("EEEEEEE" ,e)
    e.preventDefault();
    const { name,referred } = form;

  console.log("EEEEEEE" ,name,"RRRR",referred)
    try {
      const response = await axios.post(`${linkApp}/user`, {
        username: name ,
        referred: referred
      });

      const userData = response.data;
      const referente=response.data.body[0].referente
   console.log(userData)
   console.log(referente)
      if (userData && referente.length>1) {
        if (!Object.prototype.hasOwnProperty.call(window, "hive_keychain")) {
          setModalMessage("Please install Hive Keychain.");
          setModalOpen(true);
          return;
        }

        window.hive_keychain.requestSignBuffer(
          name,
          "Login to BHR",
          "Posting",
          async (response) => {
            if (response.success === true) {
              localStorage.setItem('user', name);
              localStorage.setItem('auth', true);
              window.location.reload(false);
            } else {
              setModalMessage("Error to confirm account.");
              setModalOpen(true);
            }
          }
        );
      } else {
        setReferido(false);
      }
    } catch (error) {
      setModalMessage("Error communicating with the server.");
       setReferido(false);
      setModalOpen(true);
    }
  };

  return (
    <div className="container-login">
      <h2>{t('login.welcome')}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder={t('model.login.typeName')}
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.name}
          required
        />

        {!referido && (<>
          <h2>{t('login.enterreferido')}</h2>
          <input
            type="text"
            name="referred"
            placeholder={t('login.typeReferredName')}
            onBlur={handleBlur}
            onChange={handleChange}
            value={form.referred}
            required
          /></>
        )}

        <input type="submit" value="Go" />
      </form>
      <img src={iconCorriendoAndoL} alt="algo aqui" className="icono" />

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>{modalMessage}</p>
            <button onClick={handleModalClose}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;

