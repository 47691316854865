import React, {  useState } from 'react';
import axios from 'axios';
import { linkApp } from "./../../../../../../generals/configuracion/variablesPublicas.jsx";
import { useTranslation } from 'react-i18next';


function Apuestas({ equino, fetchApuestas }) {
  const [Tickets, setTickets] = useState(1);
  const { equineId, name, raceId, apuestas } = equino;
// Initialize the user state with localStorage or false
const [user, setUser] = useState(localStorage.getItem("user") || false);
 const { t } = useTranslation();
   const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  
  let apuestasH = apuestas && apuestas.apuestas ? apuestas.apuestas : [];






const handleShowAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);

    // Ocultará la alerta después de un tiempo (por ejemplo, 3 segundos)
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };


const comprobarFirma = async (usuario) => {
  const mensaje ="Approve transaction";
  // Comprueba si Hive Keychain está disponible en el objeto global "window".
  if (!Object.prototype.hasOwnProperty.call(window, "hive_keychain")) {
    console.error("Please install Hive Keychain.");
    return false;
  }

  return new Promise((resolve) => {
    // Solicita la firma utilizando Hive Keychain.
    window.hive_keychain.requestSignBuffer(
      usuario,
      mensaje, // Mensaje que se va a firmar
      "Posting",
      (response) => {
        if (response.success === true) {
          resolve(true); // El usuario pudo firmar el mensaje.
        } else {
          console.error("Error al firmar el mensaje.");
          resolve(false); // El usuario no pudo firmar el mensaje.
        }
      }
    );
  });
};


  const realizarApuesta = async () => {
    try {
      // Obtener el usuario del almacenamiento local
      const user = localStorage.getItem("user");

      // Comprobar si el usuario puede firmar
      const usuarioPuedeFirmar = await comprobarFirma(user);

      if (!usuarioPuedeFirmar) {
        // Mostrar un mensaje de error o tomar alguna acción apropiada si el usuario no puede firmar.
        console.error('El usuario no puede firmar mensajes.');
        alert('Error: Key')
        return;
      }

      // Continuar con la lógica de realizar la apuesta
      const montoApuesta = 0.01;
      const montoAPagar = Tickets * montoApuesta;
      const apuesta = {
        equinoId: equineId,
        nombreEquino: name,
        cantidadTickets: Tickets,
        race: raceId,
        usuario: user,
      };

      const response = await axios.post(`${linkApp}/bets`, apuesta);


       if (response.data ) {
    setAlertMessage(response.data.body);
    setShowAlert(true);

      fetchApuestas(); // Llamas a la función para cargar las apuestas nuevamente.
    }
      // Manejar la respuesta como sea necesario.
    } catch (error) {
      console.error('Error al realizar la apuesta:', error);
    }
  }

  // Calcular la suma de cantidadTickets por equinoId
   
  const sumaTicketsPorEquino = apuestasH.reduce((accumulator, apuesta) => {
  if (apuesta.equinoId === equineId ) {
    return accumulator + apuesta.cantidadTickets;
  }
  return accumulator;
}, 0);


  const sumaTicketsPorEquino_apostador = apuestasH.reduce((accumulator, apuesta) => {
  if (apuesta.equinoId === equineId && apuesta.usuario==user) {
    return accumulator + apuesta.cantidadTickets;
  }
  return accumulator;
}, 0);



  const porcentajePote = (apuestas.Total_Pote * 0.8);

 const sumaTicketsPorEquinoValido = sumaTicketsPorEquino * 100 || 0;



  const cantidadAPagar = sumaTicketsPorEquinoValido > 0 ? porcentajePote / sumaTicketsPorEquinoValido : porcentajePote;


  return (
    <div >
      <p>{t('apuestas.TSupport')} {sumaTicketsPorEquino.toFixed(2)}</p>
       {sumaTicketsPorEquino_apostador >= 0.01 && (
  <div className="alert-success">
    {t('apuestas.Tustickets')} {sumaTicketsPorEquino_apostador.toFixed(2)}
  </div>
)}
      <label htmlFor="numero">{t('apuestas.Payout')} X{(cantidadAPagar *100).toFixed(1)}</label><br />
      <label htmlFor="numero">{t('apuestas.Per')} 0.01</label><br />
      <select
        id="numero"
        value={Tickets}
        onChange={(e) => setTickets(parseInt(e.target.value))}
      >
        <option value={1}>1 Tkt</option>
        <option value={2}>2 Tkt</option>
        <option value={5}>5 Tkt</option>
        <option value={10}>10 Tkt</option>
        <option value={15}>15 Tkt</option>
        <option value={25}>25 Tkt</option>
         
      </select>

      <button className="apoya" onClick={realizarApuesta}>{t('apuestas.Support')}</button>
      {showAlert && <div className="alert">{alertMessage}</div>}
    </div>
  );
}

export default Apuestas;
