import { useContext } from "react";

        
import ThemeContext from "../context/ThemeContext";
import './main.css'
import MarketIn  from "./componets/market/MarketIn.jsx"
import DashOut from".//componets/dashboard/Dashboard_Out.jsx"
import { useTranslation } from 'react-i18next';



const MainContext = () => {

const { theme } = useContext(ThemeContext);



const auth=localStorage.getItem('auth')

const user=localStorage.getItem("user")
  const { t } = useTranslation();




  return (
    <main className={theme}>
    <div className="containermain">
       {auth ? <p>{t('hello')} {user}</p> : <p className={theme}>{t('welcome')}</p>}
      
     { auth ? <MarketIn/> :  <DashOut/>   }

     { <p></p>}
     </div>
    
   </main>

  );
};

export default MainContext;