import React from 'react'
import Card from './../home/components/card.jsx'
import '../presales/presales.css'
import iconCarrera from '../../../../assets/horse-head-icon.svg'

function Dashboard_out() {
	return (
		<div>
	    <Card description="Please Login" login="" imagen={iconCarrera}/>
	    </div>
	)
}


export default Dashboard_out




