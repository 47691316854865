import React, { useState } from "react";
 import UtilityGroupModal from "./UtilityGroupModal.jsx";
import "./UtilityGroupModal.css";
 import SelectedItems from "./SelectedItems.jsx";

let imagen="./image/"

function NoNFTModal({ nfts, onBuy }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [isGroupSelected, setIsGroupSelected] = useState(false);

  const openModal = (groupName) => {
    setIsOpen(true);
    setSelectedGroup(groupName);
    setIsGroupSelected(true); 
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedGroup("");
    setIsGroupSelected(false);
};


  const handleAddToCart = (id, price, symbol,name) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.filter((item) => item.id !== id);
      return [...updatedItems, { id, price, symbol, name }];
    });
  };

  const handleRemoveFromCart = (id) => {
    setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleBuy = () => {
    onBuy(selectedItems);
    setSelectedItems([]);
    closeModal();
  };

  const groupNames = Object.keys(nfts);

  return (
    <>
 
  
 
 <div className={`inventario-container ${isGroupSelected ? 'hidden' : ''}`}>

  <div className={`button-container ${isGroupSelected ? 'hidden' : ''}`}>
    {groupNames.map((groupName) => (
      <button
        key={groupName + 43}
        onClick={() => openModal(groupName)}
        data-group={groupName}
      >
        <img src={`/image/equinos/90001.png`} alt="img" className="cajas" />
        {groupName} 
      </button>
    ))}
  </div>
</div>



 <div className="cart-container">
 
      {isOpen && selectedGroup && isGroupSelected && (

        <UtilityGroupModal

          groupName={selectedGroup}
          items={nfts[selectedGroup]}
          onAddToCart={handleAddToCart}
          selectedItems={selectedItems}
          handleBuy={handleBuy}
          handleRemoveFromCart={handleRemoveFromCart}
          volver={closeModal}
        />
      )}




    </div>
    </>
  );
}



export default NoNFTModal;
