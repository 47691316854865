import React, { useEffect, useState } from "react";
import "./RacePositionTable.css"; // Importa el archivo CSS

const RacePositionTable = ({ horses, positions, currentStage }) => {
  const [totalPoints, setTotalPoints] = useState([]);

  // Actualizar los puntos totales en cada etapa
  useEffect(() => {
    const updateTotalPoints = () => {
      const newTotalPoints = Array.from({ length: horses.length }, () => 0);
      for (let stage = 0; stage <= currentStage; stage++) {
        const stagePositions = positions[stage] || [];
        for (let i = 0; i < stagePositions.length; i++) {
          const points = parseFloat(stagePositions[i]);
          if (!isNaN(points)) { // Verificar si es un número válido
            newTotalPoints[i] += points;
          }
        }
      }
      setTotalPoints(newTotalPoints);
    };

    updateTotalPoints();
  }, [positions, horses, currentStage]);



  const sortedHorses = totalPoints.map((points, index) => ({
  points,
  horseName: horses[index] ? horses[index].name : `Caballo ${index }`,
})).sort((a, b) => b.points - a.points).slice(0,5)


  return (
    <div>
      <table className="race-table-position">
        <thead>
          <tr>
            <th>Posición</th>
            <th>Nombre</th>
            
          </tr>
        </thead>
        <tbody>
          {sortedHorses.map(({ points, horseName }, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{horseName}</td>
            
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RacePositionTable;
