import React, { useEffect, useState } from 'react';
import './modalwallet.css';
import logos   from  '../../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';
import logoBHR from '../../../../../../../../assets/logoblockhorseracing/logoBlock.png';
import axios    from 'axios';
import { useTranslation } from 'react-i18next';
import { linkApp } from './../../../../../../../generals/configuracion/variablesPublicas.jsx';

const ModalWalletBHR = ({ children, isOpen, closeModal, propsi }) => {
  const handleModalContainerClickn = (e) => e.stopPropagation();
    const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

 const { t } = useTranslation();




const comprobarFirma = async (usuario) => {
  const mensaje ="Approve transaction";
  // Comprueba si Hive Keychain está disponible en el objeto global "window".
  if (!Object.prototype.hasOwnProperty.call(window, "hive_keychain")) {
    console.error("Please install Hive Keychain.");
    return false;
  }

  return new Promise((resolve) => {
    // Solicita la firma utilizando Hive Keychain.
    window.hive_keychain.requestSignBuffer(
      usuario,
      mensaje, // Mensaje que se va a firmar
      "Posting",
      (response) => {
        if (response.success === true) {
          resolve(true); // El usuario pudo firmar el mensaje.
        } else {
          console.error("Missing signature");
          resolve(false); // El usuario no pudo firmar el mensaje.
        }
      }
    );
  });
};


const handleClaimGananciaConsueloBHR = async (data) => {
  // Display a confirmation dialog
  const confirmAction = window.confirm(`${t('wallet.ConfirmarRetiroBHR')}`);

  // Check if the user confirmed
  if (confirmAction) {
    const username = localStorage.getItem("user");
    const requerimiento = { usuario: username, ganancia_apuestas_consuelo_bhr: Number(data) };

    if (Number(data) >= 0.001) {
      // Send the signed transaction to the server using Axios
      axios.post(`${linkApp}/claim_ganancia`, requerimiento)
        .then(res => {
          // Show the response in an alert
          alert(`Server response: ${JSON.stringify(res.data.body)}`);
        })
        .catch(error => {
          // Handle any errors that occur during the API call
          console.error("Error in Axios POST request:", error);
        });
    } else {
      // Show an alert for minimum withdrawal amount
      alert(`${t('wallet.retiroMinimoBHR')}`);
    }
  } else {
    // Action canceled
    console.log("Action canceled by the user.");
  }
};



const handleClaimCofresoLlavesBHR = async (...data) => {
  //   setAlertMessage("no available");
  // setShowAlert(true);
  // return
    try {
      // Obtener el usuario del almacenamiento local
      const user = localStorage.getItem("user");

      // Comprobar si el usuario puede firmar
      const usuarioPuedeFirmar = await comprobarFirma(user);

      if (!usuarioPuedeFirmar) {
        // Mostrar un mensaje de error o tomar alguna acción apropiada si el usuario no puede firmar.
        console.error('El usuario no puede firmar mensajes.');
        alert('Error: Key')
        return;
      }
  
      // Continuar con la lógica de realizar la apuesta
      const requerimiento = { usuario: user, balance: Number(data[0]), canje_apuestas_consuelo_bhr: Number(data[1]), articulo: data[2] };
     

      const response = await axios.post(`${linkApp}/claim_ganancia`, requerimiento);
     if (response.data ) {
      console.log("response",response.data)
  console.log("response body",response.data.body.data.body)
    setAlertMessage(response.data.body.data.body);
    setShowAlert(true);
 }
    } catch (error) {
      console.error('Error al realizar la apuesta:', error);
    }


};



  

  return (
    <article className={`modal-walletBHRT ${isOpen && "is-open"}`} onClick={closeModal}>
      <div className="modal-walletBHR-container" onClick={handleModalContainerClickn}>
        <button className="modal-walletBHRT-close" onClick={closeModal}>X</button>
        <div className="content-container">
          <div className="content-balanceBH">
<h1>Balance: {propsi}<img src={logoBHR} alt="Token"  className="coin-wallet" style={{
  width: "1.2em",
  height: "1.2em",
  padding: "0em 0em 0em 0em",
  margin: "0.2em 0em 0em 0em" // Esto agrega un padding-top de 1em
}} /></h1>
          </div>
            {showAlert && <div className="alert">{alertMessage}</div>}
          <div className="content-details-BHRT">
            <div className="botones-wallet-canje-BHRT">
         <button type="submit"  className="button-wallet-canje" onClick={() => handleClaimCofresoLlavesBHR(propsi,"0.010","cofreEBHR")}>
         <spam>0,01s<img src={logoBHR} alt="Token"  className="coin-wallet" style={{
  width: "1.2em",
  height: "1.2em",
  padding: "0em 0em 0em 0em",
  margin: "0.2em 0em 0em 0em" // Esto agrega un padding-top de 1em
}} /></spam> 
                <span>{t('cofreE.cofreE')}</span>
                <img src="image/cofres/cofre_especial.gif" alt='fefe' className="Cajas" style={{ width: "80%", height: "80%" }} />
                
              </button>
     
               <button type="submit" className="button-wallet-canje"  onClick={() => handleClaimCofresoLlavesBHR(propsi,"0.040","llaveMBHR")}>
                <spam>0,04<img src={logoBHR} alt="Token"  className="coin-wallet" style={{
  width: "1.2em",
  height: "1.2em",
  padding: "0em 0em 0em 0em",
  margin: "0.2em 0em 0em 0em" // Esto agrega un padding-top de 1em
}} /></spam>
                <span>{t('cofres.llaveM')}</span>
                <img src="image/cofres/comunKeyM.png" alt='keys' className="Cajas" style={{ width: "80%", height: "80%" }} />
              </button>
         
                <button type="submit" className="button-wallet-canje"  onClick={() => handleClaimCofresoLlavesBHR(propsi,"0.120","llaveBHR")}>
                <spam>0,12<img src={logoBHR} alt="Token"  className="coin-wallet" style={{
  width: "1.2em",
  height: "1.2em",
  padding: "0em 0em 0em 0em",
  margin: "0.2em 0em 0em 0em" // Esto agrega un padding-top de 1em
}} /></spam>
                <span>{t('cofres.llave')}</span>
                <img src="image/cofres/comunKey.png" alt='fefe' className="Cajas" style={{ width: "80%", height: "80%" }} />
              </button>
              {/* Agrega los botones adicionales aquí */}

              
            </div>
          </div>
          
          <button className="claim-button" onClick={() => handleClaimGananciaConsueloBHR(propsi)}>Claim Balance</button>
        </div>
      </div>
    </article>
  );
};

export default ModalWalletBHR;
