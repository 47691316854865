import React from 'react';
import SaludBar from './SaludBar';
import EquineDetails from './EquineDetails';
import TrapecioInfo from './TrapecioInfo';
import './tarjetacaballocss/CardInfo.css'; // Asegúrate de tener este archivo CSS


const CardInfo = ({ equinoData, propsi }) => {
  return (
    <div className="card-info">
      <div className="content"> 
        <SaludBar animo={equinoData.animo} />
        <EquineDetails
          tipo={propsi.tipo}
          generacion={propsi.generacion}
          IPFS={propsi.IPFS}
          identidad={propsi.identidad}
          name={propsi.name}
        />
        <TrapecioInfo
          velocidad={propsi.velocidad}
          velocidad_add={equinoData.velocidad_add}
          resistencia={propsi.resistencia}
          resistencia_add={equinoData.resistencia_add}
          agilidad={propsi.agilidad}
        />
        <div className="sexo">
          <p>&nbsp;{propsi.sex}</p>
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
