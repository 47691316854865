import React from "react";
import './UtilityGroupModal.css'
import hivelogo from'./../../../../../../assets/logoblockhorseracing/logoHive.png'; 
import { useTranslation } from 'react-i18next';

function UtilityGroupModal({ groupName, items, onAddToCart, selectedItems, handleBuy, handleRemoveFromCart,volver }) {
  

  const { t } = useTranslation();



  const handleAddToCart = (id, price,symbol,name) => {
    onAddToCart(id, price,symbol,name);
  };

  const unselectedItems = items.filter((item) => {
    return !selectedItems.find((selectedItem) => selectedItem.id === item.nftId);
  });

 return (
  <div className="utility-group-modal">
  <div className="imagen-modal-market">

        <button onClick={() => volver()}>
          <h1>⬅️{t('mercado.atras')}</h1>
        </button>
    <h3>{groupName}</h3>
     
     <img src={`image/utility_nfts/${groupName}.png`} alt="img" className="utility-nft-image" />
   
   
   
   </div>

    {unselectedItems.map((item) => (
      <div key={item.identidad} className="item-market">
        <span  style={{ color: 'gold' }}>#{item.nftId}</span>

    <span className="market">
  {typeof item.price === 'number' ? (
    item.price % 1 === 0 ? (
      item.price.toFixed(0)
    ) : (
      item.price.toFixed(3).replace(/\.?0*$/, '') // Remover ceros finales después del punto
    )
  ) : (
    parseFloat(item.price).toFixed(3).replace(/\.?0*$/, '')
  )}{' '}
  {item.symbol === "SWAP.HIVE" ? (
  <>
    
    <img src={hivelogo} alt="Token" className="coin" />
  </>
) : (
  item.symbol
)}

</span>
        <button onClick={() => handleAddToCart(item.nftId, item.price,item.symbol,item.name)}>
          +🛒
        </button>
      </div>
    ))}
  </div>
);
}
export default UtilityGroupModal;




