import React from 'react';
import "./prize_race.css"
import { useTranslation } from 'react-i18next';








function calcularPremio(item) {
  // console.log("ITEM",item)


  const registradosLength = item.registrados.length || 0;
  console.log(registradosLength)
  console.log("costo_registro", item.costo_registro,"pot",item.Pote)
  if (registradosLength < 8) {
    return item.costo_registro * 8 + item.Pote;

  } else {
    return item.premiostotal_1;
  }
}

function eliminarDecimales(cantidad) {
  console.log("cantidad", cantidad);
  
  if (typeof cantidad === 'undefined' || cantidad === null) {
    return cantidad; // Si la cantidad es undefined o null, devolverla sin cambios
  }
  
  // Convertir cantidad a string para asegurar que las operaciones posteriores no generen errores
  const cantidadStr = cantidad.toString();
  const cantidadNum = parseFloat(cantidadStr);

  if (isNaN(cantidadNum)) {
    return cantidad; // Si la cantidad no es un número válido, devolverla sin cambios
  }

  const decimales = cantidadStr.split('.')[1]; // Obtener los decimales como una cadena

  if (decimales && decimales === '000') {
    return cantidadNum.toFixed(0); // Si los decimales son '000', redondear el número a un entero
  } else if (decimales && decimales.length > 5) {
    return Math.round(cantidadNum); // Si los decimales tienen más de 5 dígitos, redondear el número a un entero
  } else {
    return cantidadNum; // Si los decimales tienen 5 o menos dígitos, devolver la cantidad original como número
  }
}




function Premio({ item }) {

    const { t } = useTranslation();
  return (
    <>
      {item && (
        // <div className="cell">
        <>
          <table className="Premios">
            <thead>
              <tr>
               <th style={{ border: 'none' , background: 'none'}}></th>

                <th colspan="4">{t("tablaCarrera.premios")}</th>
                
              </tr>  
              <tr>
                <th>P</th>
                <th>{item.symbol_1}</th>
                <th>{item.symbol_2}</th>
                <th>{item.symbol_3}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_1[0])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_2[0])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_3[0])}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_1[1])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_2[1])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_3[1])}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_1[2])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_2[2])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_3[2])}</td>
              </tr>
               <tr>
                <td>4</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_1[3])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_2[3])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_3[3])}</td>
              </tr>
               <tr>
                <td>5</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_1[4])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_2[4])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_3[4])}</td>
              </tr>
               <tr>
                <td>6</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_1[5])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_2[5])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_3[5])}</td>
              </tr>
               <tr>
                <td>7</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_1[6])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_2[6])}</td>
                <td>{eliminarDecimales(item.Puestos_Pagos_3[6])}</td>
              </tr>
            
              {/* Agrega más filas según sea necesario */}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

export default Premio;
