import React from 'react';
import Icon from './../../../../../../generals/svgiconos/svgiconosenreact.jsx';

const ResultadosTable = ({ carrerasGanadas, carrerasSecundon, totalCarreras, t, mostrarResultados }) => {
    return (
        <div className="races_runs-Table">
            <table className="table-info-b-resultados">
                <thead className="races_runs-Heading">
                    <tr>
                        <th className="races_runs-Cell-info" id="Rwinners">
                            <Icon name="lugar1_copa" width="2rem" height="2rem" className="svg" />
                            <span>{t('info.Carreras-Ganadas')}</span>
                        </th>
                        <th className="races_runs-Cell-info" id="Rwinners">
                            <Icon name="lugar2_copa" width="2rem" height="2rem" className="svg" />
                            <span>{t('info.Carreras-Segundos')}</span>
                        </th>
                        <th className="races_runs-Cell-info" id="Rtotals">
                            <Icon name="total_corridas" width="2rem" height="2rem" className="svg" />
                            <span>{t('info.carreras-corridas')}</span>
                        </th>
                        <th className="races_runs-Cell-info" onClick={mostrarResultados}>
                            <Icon name="libreta_carreras" width="1.2rem" height="1.5rem" className="svg" />
                            <span>{t('info.resultados')}</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="races_runs-Row">
                    <tr>
                        <td className="races_runs-Cell-info">
                            <p>&nbsp;{Number(carrerasGanadas)}</p>
                        </td>
                        <td className="races_runs-Cell-info">
                            <p>&nbsp;{Number(carrerasSecundon)}</p>
                        </td>
                        <td className="races_runs-Cell-info">
                            {totalCarreras ? <p>{Number(totalCarreras)}</p> : null}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ResultadosTable;
