import React from 'react';

import EquineImage from './EquineImage'; 
import  {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import Resultados from './Resultados.jsx';
import hivelogo from '../../../../../../../assets/logoblockhorseracing/logoHive.png';

const ActuacionesTable = ({ actuaciones_base, fetchApuestas }) => {

 const [showTable, setShowTable] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
   const { t } = useTranslation();
  const{actuaciones, apuestas}=actuaciones_base


     if (!actuaciones) {
    return <p>No hay actuaciones</p> 
  }




     const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
}




  const handleShowTable = () => {
    setShowTable(true);
  };

  if (!showTable) {
    return (
      <button onClick={handleShowTable}>{t('resultados.mostrar')}</button>
    );
  }


  return (
  <table className="resultados">
            <thead>
              <tr>
                <th><h2 title={t('resultados.lugar')}>🏅</h2></th>
                <th><h2 title={t('resultados.mint')}>🐴</h2></th>
                <th><h2 title={t('resultados.total')}>💯</h2></th>
                <th><h2 title={t('resultados.add')}>:</h2></th>
                      <th style={{ display: 'flex', alignItems: 'center' }}>
  💰Pool: {(apuestas.Total_Pote).toFixed(2)}
  <img src={hivelogo} alt="Token" className="icon" style={{ width: '5px', height: '25px', marginLeft: '0em' }} />
</th>
                </tr>
            </thead>
            <tbody>
              {actuaciones
                .sort((a, b) => b.Total - a.Total) // Ordenar de mayor a menor por Total
                .map((actuacion, index) => (
                  <tr key={actuacion._id}>
                  
                    <td> 

  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  {index + 1}
  <h1 style={{  color: "gold" }}>
  🎌
</h1></div>
 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  {parseInt(actuacion.Total)}<h4 style={{  color: "gold" }}>&nbsp;Pts</h4> </div>
 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    👑
  </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',bottom:"0.5rem" }}>
  {actuacion.account }
  </div>

                     </td>
   
                    <td>   
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  {actuacion.sexo === 'M' ? '♂️' : '♀️'} <span style={{ fontSize: '0.7rem' }}>&nbsp;&nbsp;{actuacion.tipo}&nbsp;&nbsp;</span>
 {actuacion.equineId}
</div>
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/*      <img
                                  src={`/image/equinos/${eq.equineId}.png`}
                                  alt="Img"
                                  className={`icono-races ${isHovered ? 'hovered' : ''}`}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                /> */}

                 <EquineImage
          mainImageSrc={`./image/equinos/${actuacion.equineId}.png`}
          backupImageSrc={`./image/equinos/000.png`}
          isHovered={false}
        
        />
    </div>
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <h5>{actuacion.name}</h5> </div>

 </td>


 <td>
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  
  <span style={{ fontSize: '0.7rem' }} >{t('resultados.agilidad')}</span>&nbsp; {actuacion.agilidad}
  </div>

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <span style={{ fontSize: '0.7rem' }}>{t('resultados.resistencia')}</span>&nbsp;
  {actuacion.resistencia + parseInt(actuacion.resistencia_add)}
</div>
     
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <span style={{ fontSize: '0.7rem' }}>{t('resultados.velocidad')}</span>&nbsp;
  {actuacion.velocidad + parseInt(actuacion.velocidad_add)}
</div>

     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       <span style={{ fontSize: '0.7rem' }}>Random:</span>/{actuacion.divisor}*{actuacion.multiplicador} 
  </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        T:&nbsp;<span style={{ fontSize: '1rem' }}>{parseInt(actuacion.Total_1)}</span> &nbsp; Pts
      </div>
</td>
 

                    <td>
                      
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span style={{ fontSize: '0.7rem' }}> {t('resultados.salud')} </span> ❤️&nbsp;&nbsp;{parseInt(actuacion.Total_animo)}/<span>{parseInt(actuacion.animo)}</span>
  </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     <span style={{ fontSize: '0.7rem' }}>{t('resultados.implementos')}   🏇</span>&nbsp;&nbsp;{parseInt(actuacion.Total_implemento)}/<span>{parseInt(actuacion.implemento)}</span>
  </div>

 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , padding: '1rem' }}>
  T:&nbsp;<span style={{ fontSize: '1rem' }}>{parseInt(actuacion.Total_animo)  + parseInt(actuacion.Total_implemento)}</span>Pts
</div>


                    </td>
                 
                    <td>
             

             {/* */}
              <Resultados equino={{ equineId: actuacion.equineId, name: actuacion.name, raceId: actuacion.raceId, apuestas: apuestas }} fetchApuestas={fetchApuestas} />
            
            </td>
                    
                    
                  </tr>
                ))}
            </tbody>
          </table>
  );
};

export default ActuacionesTable;
