import React, { useState } from "react";
import "./racetrack.css"; // Estilo principal de la simulación
import RaceLane from "./component/RaceLane";


const RaceTrack = (visual) => {
  const [raceInProgress, setRaceInProgress] = useState(false);
  const [raceResult, setRaceResult] = useState([]);

  const startRace = () => {
    setRaceInProgress(true);
  };

  const restartRace = () => {
    setRaceInProgress(false);
    setRaceResult([]);
  };

  return (
    <div className="race-track">
      <h1>Block Horse Racing</h1>
      
      {/* Componente para mostrar la pista de la carrera */}
      <RaceLane horses={visual.horses} raceInProgress={raceInProgress} raceResult={raceResult} positions={visual.visual} startRace={startRace} restartRace={restartRace} />
     
      {!raceInProgress && raceResult.length === 0 && (
        
         <a href="#" onClick={startRace} >🏁</a>
      )}

      {raceResult.length > 0 && (
        <p>Resultado de la carrera: {raceResult.join(", ")}</p>
      )}

      {raceInProgress && (
       <a href="#" onClick={restartRace} >🔄</a>

      )}
    </div>
  );
};

export default RaceTrack;
