import React, { useState } from 'react';

const EquineImage = ({ mainImageSrc }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    console.log("EEEEEEEEEEEEEEEXXXXXXINOS")
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img
        src={mainImageSrc}
        alt="Img"
        className={`equino-imagen ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

export default EquineImage;



// import React, { useState } from 'react';

// const EquineImage = ({ mainImageSrc, backupImageSrc }) => {
//   const [imageError, setImageError] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);

//   const handleImageError = () => {
//     setImageError(true);
//   };

 
//   const handleMouseEnter = () => {
//     setIsHovered(true);
//     console.log("EEEEEEEEEEEEEEEXXXXXXINOS")
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
// }
//   return (
//     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//       {imageError ? (
//         <img
//           src={backupImageSrc}
//           alt="Error"
//           className={`equino-imagen ${isHovered ? 'hovered' : ''}`}
//         />
//       ) : (
//         <img
//           src={mainImageSrc}
//           alt="Img"
//           className={`equino-imagen ${isHovered ? 'hovered' : ''}`}
//           onError={handleImageError}
//             onMouseEnter={handleMouseEnter}
//           onMouseLeave={handleMouseLeave}
//         />
//       )}
//     </div>
//   );
// };

// export default EquineImage;
