import React, { useState } from "react";
import UtilityGroupModal from "./UtilityGroupModal2.jsx";
import "./Utility_Modal.css";
import SelectedItems from "./SelectedMyItems.jsx";

let imagen="./image/"

function UtilityModal({ nfts, onBuy }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  const openModal = (groupName) => {
    setIsOpen(true);
    setSelectedGroup(groupName);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedGroup("");
  };

  const handleAddToCart = (id, price, symbol,name) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.filter((item) => item.id !== id);
      return [...updatedItems, { id, price, symbol, name }];
    });
  };

  const handleRemoveFromCart = (id) => {
    setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleBuy = () => {
    onBuy(selectedItems);
    setSelectedItems([]);
    closeModal();
  };

  const groupNames = Object.keys(nfts);

  return (
    <>
 
  
<div className="cart-container">
  <SelectedItems
    selectedItems={selectedItems}
    handleRemoveFromCart={handleRemoveFromCart}
    handleBuy={handleBuy}
  />
  <div className="button-container">
 
  {groupNames.map((groupName) => (
    <button
      key={groupName}
      onClick={() => openModal(groupName)}
      data-group={groupName}
    >
  <img src={`image/utility_nfts/${groupName}.png`} alt="img" className="cajas" />
        {groupName}
    </button>
  ))}
</div>
</div>

 



 <div className="cart-containera">
 
      {isOpen && selectedGroup && (

        <UtilityGroupModal

          groupName={selectedGroup}
          items={nfts[selectedGroup]}
          onAddToCart={handleAddToCart}
          selectedItems={selectedItems}
          handleBuy={handleBuy}
          handleRemoveFromCart={handleRemoveFromCart}
          volver={closeModal}
        />
      )}


    </div>
    </>
  );
}



export default UtilityModal;
