import React, { useState } from 'react';
import axios from 'axios';
import { linkApp } from './../../../../../generals/configuracion/variablesPublicas.jsx';
import { useTranslation } from 'react-i18next';
const NameForm = (props) => {
  const [newName, setNewName] = useState('');
  const { t } = useTranslation();
// Función de validación del nombre
const validateName = (name) => {
  // Verificar si el nombre tiene más de 20 caracteres
  if (name.length > 20) {
    return 'El nombre no puede tener más de 20 caracteres';
  }

  // Reemplazar espacios en blanco con '_'
  const formattedName = name.replace(/\s+/g, '_');

  // Verificar si el nombre contiene caracteres especiales
  const regex = /^[a-zA-Z0-9_]+$/;
  if (!regex.test(formattedName)) {
    return 'El nombre no puede contener caracteres especiales';
  }

  // Si pasa todas las validaciones, devolver el nombre formateado
  return formattedName;
};




  const handleSubmit = async (event) => {
    event.preventDefault();
let amount="0.010"
let toUsername="bhr-team"
let memo ="Mint: "+ props.props + " Name will change to:" + newName;

  const validatedName = validateName(newName);

  // Verificar si el nombre no cumple con las condiciones
  if (typeof validatedName === 'string') {
    // alert(validatedName);
    //return;
  }


let username=localStorage.getItem("user");
    try {
        // Realizar la solicitud POST a /equine con el nuevo nombre
if (window.hive_keychain) {
  const keychain = window.hive_keychain;
        keychain.requestSendToken(username, toUsername, amount, memo, "BHR", (response) => {
        console.log("RES KeyC",response);
        axios.post(`${linkApp}/equino`,{response: response, newname: newName }) 
        .then(res => {  
         console.log("Desde App",res)
            
             // console.log("Desde App data",res.data)
             // alert(res.data)
             
         // setIsLoading_regis(false);
         // setMostrarBoton_Inscripcion(false) 
         // handlee(propsi)
     })
      .catch(error => {
        console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} 

else {
  alert('You do not have hive keychain installed');
}


      // const response = await axios.post(`${linkApp}/equino`, { newname: newName });

      // // Si la solicitud es exitosa, llamar a la función onNameChange
      // if (response.status === 200) {
      //   props.onNameChange(newName);
      //   setNewName('');
      //   alert('Nombre cambiado exitosamente.');
      // }
    } catch (error) {
      // Manejar errores de la solicitud
      console.error('Error al enviar el nombre:', error);
      alert('Hubo un error al intentar cambiar el nombre.');
    }
  };

  const handleCostAlert = () => {
    // Mostrar un mensaje sobre el costo del cambio de nombre
    const costMessage = t("changeName.costo");
    alert(costMessage);

  };

  return (
    <div >
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder={t('changeName.typeName')}
          value={newName}
          onChange={(e) => {
      const formattedValue = e.target.value.replace(/[^a-zA-Z0-9_]/g, '');
      setNewName(formattedValue.slice(0, 20)); // Limita la entrada a 20 caracteres
    }}
        />
        <button type="submit" onClick={handleCostAlert}>{t('changeName.change')}</button>
      </form>
    </div>
  );
};

export default NameForm;
