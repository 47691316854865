import  React, { useState }from 'react';
import Alert from './AlertTabla.jsx';
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';

import TimeAPI from './../auxiliar/reloj.jsx';
 import axios from 'axios';
 import { useTranslation } from 'react-i18next';
// import { parse, isValid, isBefore } from "date-fns";

import Premio from './componentes_tables/prize_race.jsx'

import'./tablaDatos.css'



const traducciones = {
  'D0': 'Debutante',
  'G1': 'Ganadores de 1',
  'G2': 'Ganadores de 2',
  'G3': 'Ganadores de 3',
  'G4': 'Ganadores de 4',
  'G5': 'Ganadores de 5',
  'L0':'Libre',
  'P0': 'Perdedores',
  'C1': 'Ganadores de 1*',
  'C2': 'Ganadores de 2*',
  'C3': 'Ganadores de 3*',
  'C4': 'Ganadores de 4*',
  'C5': 'Ganadores de 5* o mas',
  'P3': 'Perdedores de 3*',
  'P4': 'Perdedores de 4*',
  'P5': 'Perdedores de 5 o mas',
};

function traducirTipo(tipo) {
  return traducciones[tipo] || '';
}







function minutosFaltantes(tiempoDesalida, timeActual) {
  const tiempoActual = new Date(timeActual);
  const tiempoDesalidaDate = new Date(tiempoDesalida);
  const diferencia = tiempoDesalidaDate - tiempoActual;
  const minutosFaltantes = Math.ceil(diferencia / (1000 * 60));

  if (minutosFaltantes <= 0) {
    return 0;
  } else {
    return minutosFaltantes;
  }
}
setInterval(minutosFaltantes, 1000);



// Iniciar la actualización de los minutos restantes cada segundo

export default function TablaCarreras(props) {
  const [mostrarDiv, setMostrarDiv] = useState(false);
  const [mostrarDivInfoRace, setMostrarDivInfoRace] = useState(false);
  const [mostrarBoton_Inscripcion, setMostrarBoton_Inscripcion] = useState(true);
  const {timeActual, setTimeActual} = TimeAPI();
  const { data, propsi ,handlee, fecha} = props;


 let user=localStorage.getItem("user");

    const reversedData = [...data].reverse();
  const [isLoading_regis, setIsLoading_regis] = useState(false);
  console.log("ACA LOS PROPS",props)
  console.log("ACA LOS Reverse",reversedData)
  //console.log("FECHAS TERMITA",fecha)
const { t } = useTranslation();

  //let timeActual=0
  ///fecha.timeActual;

function calcularPremio(item) {
  //console.log("ITEM",item)

  const registradosLength = item.registrados.length || 0;
  console.log(registradosLength)
  console.log("costo_registro", item.costo_registro,"pot",item.Pote)
  if (registradosLength < 8) {
    return item.costo_registro * 8 + item.Pote;

  } else {
    return item.premiostotal_1;
  }
}

  console.log("identidad TablaCarr",propsi.identidad)




const handleFetch = async (data,pro) => {
   setIsLoading_regis(true);
   
  console.log(data);
console.log("Cuenta Pagar",data.CuentaPool)
let memo=`EQ- entering a ${pro.name} Mint ${pro.identidad} in race ${data.raceid}`
let toUsername=data.CuentaPool
let username=localStorage.getItem("user");
const x = 1; // Cantidad de equinos permitidos por cuentas

let amount=data.costo_registro;
let symbol=data.symbol_1;

 axios.get(`${linkApp}/equino?equineId=${pro.identidad}`)
      .then(response => {
//console.log("ESTO ES LO Q HAY",response.data.body)
let estado=response.data.body[0].estado
let status=response.data.body[0].status
//console.log("Estado CUCU Pagar",estado)


if((estado==="Ready" || !(estado) )&&(status==="F")){
axios.get(`${linkApp}/race?raceid=${data.raceid}`).then(respuesta=> {
  // Verificar si hay datos y si existe el primer elemento en el array
  let array_owner_inscritos = respuesta?.data?.body[0]?.registrados_owners || [];
  
  console.log("respuesta_owners", array_owner_inscritos);



const vecesEncontrado = array_owner_inscritos.filter(owner => owner === username).length;

if (vecesEncontrado > x) {
  console.log(`El usuario ${username} está registrado más de ${x} veces.`);

alert(`El usuario ${username} está registrado más de ${x} veces.`)

} 
else {

if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount.toFixed(3), memo, symbol, (response) => {
    console.log("RES KeyC",response);
    axios.post(`${linkApp}/keychaintrx`, response) 
      .then(res => {  
         setIsLoading_regis(false);
         setMostrarBoton_Inscripcion(false) 
         handlee(propsi)
     })
      .catch(error => {
        console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} 
else {
  alert('You do not have hive keychain installed');
}

  console.log(`El usuario ${username} está registrado ${vecesEncontrado} veces.`);


}
})


}  
else {

  alert('It seems like your horse is already registered for the race or needs rest');
  return <Alert message="It seems like you're already registered for the race or need Rest" />;

}})

      



};

  return (
<div className="tableinscripcion" id="tablaInscripcion">
  {reversedData.map(item => (
    <div key={item.raceid} className="rowInscripcion">
      {/* Información */}
      <div className="cell">
      
        <div>
            <p><h4>{item.raceid}           <input
  type="button"
  name="background"
  className="registro"
  value="?"
  style={{ backgroundColor: mostrarDivInfoRace ? 'gold' : 'gold' }}

    onClick={(e) => {
    e.stopPropagation(); // Detener la propagación del evento
setMostrarDivInfoRace(!mostrarDivInfoRace)
  }}
/></h4></p>

                    <div>
            <p>{t('tablaCarrera.registrados')}: {item.registrados.length}</p>
        

                       {mostrarDivInfoRace && (
  
<div>
<table>

  <tbody>
   <tr>
        
    {Array.isArray(item.registrados_owners) && item.registrados_owners.map((owner, index) => (
     
   <td> <span>{owner}</span> </td>
     
    ))}
   

      </tr>
  </tbody>
</table>
<div>
    <p><h5>{item.name}</h5></p>
    <p>Invited:
      <h5>{item.tipo
        .split(',')
        .map((t) => traducirTipo(t))
        .join(', ')}
    </h5></p>


</div></div>

)}
            </div>
          </div>
        
        
      </div>
      {/* Premios */}
      <div className="cell">
     
        {item && <Premio item={item} />}
      </div>
      {/* Salida */}
      <div className="cell">
        <p>{t('tablaCarrera.salida')} :
        
          {minutosFaltantes(item.tiempodesalida, timeActual) >= 60
            ? `${Math.floor(minutosFaltantes(item.tiempodesalida, timeActual) / 60)} hrs`
            : `${minutosFaltantes(item.tiempodesalida, timeActual)} min`}
        </p>
        <p>fee: {item.costo_registro}-{item.symbol_1} </p>
        {mostrarBoton_Inscripcion && item.registrados_owners.filter(registrado => registrado === user).length < 2 && (
          <input
            type="submit"
            name="background"
            className="registro"
            value={
              item.registrados.length >= item.maxParticipantes
                ? "Max reached"
                : minutosFaltantes(item.tiempodesalida, timeActual) >= 3
                  ? "entry"
                  : `Too late (${minutosFaltantes(item.tiempodesalida, timeActual)} min)`
            }
            disabled={
              minutosFaltantes(item.tiempodesalida, timeActual) < 3 ||
              minutosFaltantes(item.tiempodesalida, timeActual) === 0
            }
            onClick={() =>
              item.registrados.length >= item.maxParticipantes
                ? null
                : minutosFaltantes(item.tiempodesalida, timeActual) >= 3
                  ? handleFetch(item, propsi)
                  : null
            }
          />
        )}
      </div>
    </div>
  ))}
</div>




  );
}


