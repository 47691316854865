import { useRouteError } from "react-router-dom";
import"./errorPage.css"
import Loader from "./../generals/loders/Loader.jsx"
import chico from"./../../assets/gif/3c4ff75af56e0e12e0f70eeb08d54b8d.gif"
export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="mainbox">
    <div className="err">4</div>
    <i className="far fa-question-circle fa-spin"><Loader/></i>
    <div className="err2">4</div>
      <div className=""><img src={chico} alt="algo aqui" className="icono" /></div>
    <div className="msg">Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?<p>Let's go <a href="./">home</a> and try from there.</p></div>
      </div>

  );
}