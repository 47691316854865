import { createContext, useState } from "react";

const LanguageContext = createContext();

const initialLanguage = "en";
const translations = {
  es: {
    headerTitle: "Mi aplicación CON Context API",
    headerSubtitle: "Mi cabecera",
    headerLight: "Claro",
    headerDark: "Oscuro",
    buttonLogin: "Iniciar Sesión",
    buttonLogout: "Cerrar Sesión",
    mainWelcome: "Bienvenid@",
    mainHello: "Hola ",
    mainContent: "Hora de Correr, texto con contexto!",
    footerTitle: "Asociados :",
  },
  en: {
    headerTitle: "My application with Context API",
    headerSubtitle: "My header", 
    headerLight: "Light",
    headerDark: "Dark",
    buttonLogin: "Login",
    buttonLogout: "Logout",
    mainWelcome: "Welcome Guest",
    mainHello: "Hello ",
    mainContent: "My main content",
    footerTitle: "Partners",
    BotonNAVEGABLE: "",
  },
};


const translationInfoballo = {
  es: {
    headerTitle: "Mi aplicación CON Context API",
    headerSubtitle: "Mi cabecera",
    headerLight: "Claro",
    headerDark: "Oscuro",
    buttonLogin: "Iniciar Sesión",
    buttonLogout: "Cerrar Sesión",
    mainWelcome: "Bienvenid@",
    mainHello: "Hola ",
    mainContent: "Hora de Correr, texto con contexto!",
    footerTitle: "Asociados :",
  },
  en: {
    headerTitle: "My application with Context API",
    headerSubtitle: "My header",
    headerLight: "Light",
    headerDark: "Dark",
    buttonLogin: "Login",
    buttonLogout: "Logout",
    mainWelcome: "Welcome Guest",
    mainHello: "Hello ",
    mainContent: "My main content",
    footerTitle: "Partners",
    BotonNAVEGABLE: "",
  },
};



const translationCardBallo = {
  es: {
    headerTitle: "Mi aplicación CON Context API",
    headerSubtitle: "Mi cabecera",
    headerLight: "Claro",
    headerDark: "Oscuro",
    buttonLogin: "Iniciar Sesión",
    buttonLogout: "Cerrar Sesión",
    mainWelcome: "Bienvenid@",
    mainHello: "Hola ",
    mainContent: "Hora de Correr, texto con contexto!",
    footerTitle: "Asociados :",
  },
  en: {
    headerTitle: "My application with Context API",
    headerSubtitle: "My header",
    headerLight: "Light",
    headerDark: "Dark",
    buttonLogin: "Login",
    buttonLogout: "Logout",
    mainWelcome: "Welcome Guest",
    mainHello: "Hello ",
    mainContent: "My main content",
    footerTitle: "Partners",
    BotonNAVEGABLE: "",
  },
};



const translationNegociate = {
  es: {
    headerTitle: "Mi aplicación CON Context API",
    headerSubtitle: "Mi cabecera",
    headerLight: "Claro",
    headerDark: "Oscuro",
    buttonLogin: "Iniciar Sesión",
    buttonLogout: "Cerrar Sesión",
    mainWelcome: "Bienvenid@",
    mainHello: "Hola ",
    mainContent: "Hora de Correr, texto con contexto!",
    footerTitle: "Asociados :",
  },
  en: {
    headerTitle: "My application with Context API",
    headerSubtitle: "My header",
    headerLight: "Light",
    headerDark: "Dark",
    buttonLogin: "Login",
    buttonLogout: "Logout",
    mainWelcome: "Welcome Guest",
    mainHello: "Hello ",
    mainContent: "My main content",
    footerTitle: "Partners",
    BotonNAVEGABLE: "",
  },
};



const translationsAuctions = {
  es: {
    headerTitle: "Mi aplicación CON Context API",
    headerSubtitle: "Mi cabecera",
    headerLight: "Claro",
    headerDark: "Oscuro",
    buttonLogin: "Iniciar Sesión",
    buttonLogout: "Cerrar Sesión",
    mainWelcome: "Bienvenid@",
    mainHello: "Hola ",
    mainContent: "Hora de Correr, texto con contexto!",
    footerTitle: "Asociados :",
  },
  en: {
    headerTitle: "My application with Context API",
    headerSubtitle: "My header",
    headerLight: "Light",
    headerDark: "Dark",
    buttonLogin: "Login",
    buttonLogout: "Logout",
    mainWelcome: "Welcome Guest",
    mainHello: "Hello ",
    mainContent: "My main content",
    footerTitle: "Partners",
    BotonNAVEGABLE: "",
  },
};





const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(initialLanguage);
  const [texts, setTexts] = useState(translations[language]);
 const [textosinfo,setTextosinfo] = useState(translationInfoballo[language]);
 const [textoscard,setTextoscard] = useState(translationCardBallo[language]);
 const [textosnegociate,setTextosnegociate] = useState(translationNegociate[language]);
  const [textosAuctions,setTextosauctions] = useState(translationsAuctions[language]);
  const handleLanguage = (e) => {
  
    if (language === "en") {
      setLanguage("en");
      setTexts(translations.en);
    } else {
      setLanguage("en");
      setTexts(translations.es);
    }
  };

  const data = { texts, handleLanguage };

  return (
    <LanguageContext.Provider value={data}>{children}</LanguageContext.Provider>
  );
};

export { LanguageProvider };
export default LanguageContext;
