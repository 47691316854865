import React, { useState , useContext } from "react";
// import { useContext } from "react";

        
import ThemeContext from "../context/ThemeContext";
import './main.css'
import BotonRefer  from "./componets/dashboard/refer_enlace.jsx"
import DashIn  from "./componets/dashboard/Dashboard.jsx"
import Mantenimiento  from "./componets/dashboard/Mantenimiento.jsx"
import DashOut from"./componets/dashboard/Dashboard_Out.jsx"
import { useTranslation } from 'react-i18next';


import { useSistemasActivos } from './../context/SistemasActivosContext.jsx'
const MainContext = () => {

const { theme } = useContext(ThemeContext);




const auth=localStorage.getItem('auth')

const user=localStorage.getItem("user")
  const { t } = useTranslation();

  const sistemasActivos = useSistemasActivos();

  return (
    <main className={theme}>
         <div className="containermain" style={{ paddingTop: '0.6em' }}>
      <>
        {auth ? <><p>{t('hello')} {user}</p><BotonRefer/></> : <p className={theme}>{t('welcome')}</p>}

        {sistemasActivos && sistemasActivos.alimentar_caballo === "active" ? (
          auth ? <DashIn/> : <DashOut/>
        ) : (
          <Mantenimiento/>
        )}
      </>
    </div>
  

  
   </main>

  );
};

export default MainContext;