import React, { useState, useEffect } from "react";
import axios from 'axios';
import './food.css'
import Loader from "./../../../../generals/loders/Loader.jsx";
import UtilityModal from "./food/Utility_Modal";
import MyUtilityModal from "./food/MyUtility_Modal";

export default function Food() {
  const [Utility_Nfts, setUtility_Nfts] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const [MyUtility_Nfts, setMyUtility_Nfts] = useState([]);
  const [isMyLoaded, setisMyLoaded] = useState(false);
  const [showUtilityModal, setShowUtilityModal] = useState(true);
  const [showMyUtilityModal, setShowMyUtilityModal] = useState(false);

useEffect(() => {
  const getUtility_Nfts = async (url) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    let query = {};

    //query.account = localStorage.getItem("user");
     query['grouping.type']="food";


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nftmarket',
          table: "BHREQTsellBook",
          query: query,
          limit: 600,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

    try {
      const response = await axios.post(url, body, config);
      const data = response.data[0].result;
console.log("DATA DE MARKET",data)
      let groupedUtilityNfts = {};

      data.forEach((data) => {
        let identidad = data._id;
        let nft_id = data.nftId;
        let groupingName = data.grouping.name;
        let groupingTipo = data.grouping.type;
        let price = data.price;
        let account = data.account;
        let symbol = data.priceSymbol;
        let fee = data.fee;

        let Utility_Nft = {
          identidad: identidad,
          nftId: nft_id,
          account: account,
          name: groupingName,
          tipo: groupingTipo,
          price: price,
          symbol: symbol,
          fee: fee
        };

        if (groupingName in groupedUtilityNfts) {
          groupedUtilityNfts[groupingName].push(Utility_Nft);
        } else {
          groupedUtilityNfts[groupingName] = [Utility_Nft];
        }
      });

      setUtility_Nfts(groupedUtilityNfts);
    } catch (error) {
      console.log("error fetching", error);
    } finally {
      setisLoaded(true);
    }
  };

  getUtility_Nfts("https://engine.rishipanthee.com/contracts");
}, []);





useEffect(() => {
  const getMyUtility_Nfts = async (url) => {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    let query = {};

    query.account = localStorage.getItem("user");
     query['grouping.type']="food";


    let body = JSON.stringify([
      {
        method: "find",
        jsonrpc: "2.0",
        params: {
          contract: 'nftmarket',
          table: "BHREQTsellBook",
          query: query,
          limit: 200,
          offset: 0,
          indexes: [],
        },
        id: 1,
      },
    ]);

    try {
      const response = await axios.post(url, body, config);
      const data = response.data[0].result;
console.log("DATA DE MARKET",data)
      let groupedUtilityNfts = {};

      data.forEach((data) => {
        let identidad = data._id;
        let nft_id = data.nftId;
        let groupingName = data.grouping.name;
        let groupingTipo = data.grouping.type;
        let price = data.price;
        let account = data.account;
        let symbol = data.priceSymbol;
        let fee = data.fee;

        let MyUtility_Nft = {
          identidad: identidad,
          nftId: nft_id,
          account: account,
          name: groupingName,
          tipo: groupingTipo,
          price: price,
          symbol: symbol,
          fee: fee
        };

        if (groupingName in groupedUtilityNfts) {
          groupedUtilityNfts[groupingName].push(MyUtility_Nft);
        } else {
          groupedUtilityNfts[groupingName] = [MyUtility_Nft];
        }
      });

      setMyUtility_Nfts(groupedUtilityNfts);
    } catch (error) {
      console.log("error fetching", error);
    } finally {
      setisMyLoaded(true);
    }
  };

  getMyUtility_Nfts("https://engine.rishipanthee.com/contracts");
}, []);


  const handleBuyOrder = (selectedItems) => {
    console.log("Buy Order:", selectedItems);

  let username=localStorage.getItem("user");
  // Obtener el array de IDs
  const ids = selectedItems.map((item) => item.id);
console.log("IIDS",ids);

  // Calcular el total de los precios sumados
  const total =selectedItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
console.log("totales",total);

  // Obtener el símbolo
  const symbol = selectedItems.length > 0 ? selectedItems[0].symbol : '';

console.log("symbol",symbol);



    // Lógica para realizar la compra de los elementos seleccionados
if (window.hive_keychain) {
  const keychain = window.hive_keychain;

    const customJson = [
  {
    "contractName": "nftmarket",
    "contractAction": "buy",
    "contractPayload": {
      "symbol": "BHREQT",
      "nfts": ids,
      "expPrice": total.toFixed(3),
      "expPriceSymbol": symbol,
      "marketAccount": "bhr-market"
    }
  }
];

  keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Buy NFTs', response => {
    console.log('Response from Hive Keychain:', response);
    // axios.post('./keychaintrx_chest', response)
    //   .then(res => {
    //     console.log('Response from server:', res);
    //   })
    //   .catch(error => {
    //     console.log('Error:', error);
    //   });
  });
} else {
  alert('You do not have hive keychain installed');
}

 
  };






    const handleCancelOrder = (selectedItems) => {
    console.log("Buy Order:", selectedItems);

  let username=localStorage.getItem("user");
  // Obtener el array de IDs
  const ids = selectedItems.map((item) => item.id);
console.log("IIDS",ids);

  // Calcular el total de los precios sumados
  const total =selectedItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
console.log("totales",total);

  // Obtener el símbolo
  const symbol = selectedItems.length > 0 ? selectedItems[0].symbol : '';

console.log("symbol",symbol);



    // Lógica para realizar la compra de los elementos seleccionados
if (window.hive_keychain) {
  const keychain = window.hive_keychain;

    const customJson = [
  {
    "contractName": "nftmarket",
    "contractAction": "cancel",
    "contractPayload": {
      "symbol": "BHREQT",
      "nfts": ids,
   
    }
  }
];

  keychain.requestCustomJson(username, 'ssc-mainnet-hive', 'Active', JSON.stringify(customJson), 'Cancel Sell', response => {
    console.log('Response from Hive Keychain:', response);
    // axios.post('./keychaintrx_chest', response)
    //   .then(res => {
    //     console.log('Response from server:', res);
    //   })
    //   .catch(error => {
    //     console.log('Error:', error);
    //   });
  });
} else {
  alert('You do not have hive keychain installed');
}

 
  };

  




//   return (
//     <>
//       <div className="wrapp">
//         <div className="container">
        
//   {Utility_Nfts.length === 0 && isLoaded === false ? (
//   <Loader />
// ) : (
//   <UtilityModal nfts={Utility_Nfts} onBuy={handleBuyOrder} />
// )}

//   {MyUtility_Nfts.length === 0 && isLoaded === false ? (
//  <></>
// ) : (
//   <MyUtilityModal nfts={MyUtility_Nfts} onBuy={handleCancelOrder} />
// )}
//         </div>
//       </div>
//     </>
//   );
// }





  const handleMarketClick = () => {
    setShowUtilityModal(true);
    setShowMyUtilityModal(false);
  };

  const handleMyItemsClick = () => {
    setShowMyUtilityModal(true);
    setShowUtilityModal(false);
  };

  return (
    <div className="wrapp">
      <div className="container-market-alimentos">
        {Utility_Nfts.length === 0 && isLoaded === false ? (
          <Loader />
        ) : (
          <button onClick={handleMarketClick} className="Nav-Market"><h1>Market</h1></button>
        )}

        {MyUtility_Nfts.length === 0 && isLoaded === false ? (
          <></>
        ) : (
          <button onClick={handleMyItemsClick} className="Nav-Market"><h1>Your Items</h1></button>
        )}

        {showUtilityModal && (
          <UtilityModal nfts={Utility_Nfts} onBuy={handleBuyOrder} />
        )}

        {showMyUtilityModal && (
          <MyUtilityModal nfts={MyUtility_Nfts} onBuy={handleCancelOrder} />
        )}
      </div>
    </div>
  );
}

