import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import "./temporada.css"


function CountdownClock() {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
   const { t } = useTranslation();

  useEffect(() => {
    axios.get(`/temporada`)
      .then(response => {
        const targetDate = Date.parse(response.data.temporada[0].currentTimeBlock);
        const timeLeftInSeconds = Math.floor((targetDate - Date.now()) / 1000);
        setTimeLeft(timeLeftInSeconds);
      })
      .catch(error => {
        console.error('Error al obtener la fecha:', error);
      });
  }, []);

  function updateTimeLeft() {
    const newTimeLeft = timeLeft - 1;

    if (newTimeLeft < 0) {
      clearInterval(intervalId);
      return;
    }

    setTimeLeft(newTimeLeft);

    const newDays = Math.floor(newTimeLeft / 86400); // 86400 segundos en un día
    setDays(newDays);

    const newHours = Math.floor((newTimeLeft % 86400) / 3600);
    setHours(newHours);

    const newMinutes = Math.floor((newTimeLeft % 3600) / 60);
    setMinutes(newMinutes);

    const newSeconds = newTimeLeft % 60;
    setSeconds(newSeconds);
  }

  useEffect(() => {
    const id = setInterval(updateTimeLeft, 1000);
    setIntervalId(id);

    return () => {
      clearInterval(id);
    };
  }, [timeLeft]);

  return (
    <div className="countdown">
    <div>
    <h3>{t('temporada.title')}</h3>
    <div className="countdown">
      <div className="countdown-item">
        <span className="countdown-value" id="days">{days}</span>
        <span className="countdown-label">Days</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-value" id="hours">{hours}</span>
        <span className="countdown-label">Hours</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-value" id="minutes">{minutes}</span>
        <span className="countdown-label">Minutes</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-value" id="seconds">{seconds}</span>
        <span className="countdown-label">Seconds</span>
      </div>  </div>
      </div>
    </div>
  );
}

export default CountdownClock;

