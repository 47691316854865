import React from 'react';
import Icon from './../../../../../../generals/svgiconos/svgiconosenreact.jsx';
import { useTranslation } from 'react-i18next';
import './tarjetacaballocss/Trapecio.css'; // Asegúrate de tener este archivo CSS

const TrapecioInfo = ({ velocidad, velocidad_add, resistencia, resistencia_add, agilidad }) => {
   const { t } = useTranslation();
  
  return (
    <div className="trapecio_info">
      <div className="spamer">
        <Icon name="velocidad" width="2rem" height="2rem" className="svg"/>
        <span>{t('info.velocidad')}</span>
        <p>&nbsp;{velocidad} + &nbsp;{Number(velocidad_add).toFixed(3)}&nbsp;&nbsp;</p>
      </div>
      <div className="spamer">
        <Icon name="resistencia" width="2rem" height="2rem" className="svg" />
        <span>{t('info.resistencia')}</span>
        <p>&nbsp;{resistencia} + &nbsp;{Number(resistencia_add).toFixed(3)}&nbsp;&nbsp;</p>
      </div>
      <div className="spamer">
        <Icon name="agilidad" width="2rem" height="2rem" />  
        <span>{t('info.agilidad')}</span>
        <p>&nbsp;{agilidad}</p>
      </div>
    </div>
  );
};

export default TrapecioInfo;





// # ¡Conviértete en un Dueño de Caballos de Carreras y Gana Grandes Premios!
// [Enlace a la versión en Español](https://docs.blockhorseracing.com/)

// **Bienvenido a BlockHorseRacing, el juego emocionante de carreras de caballos basado en la tecnología blockchain de Hive!** 
// ¡Sumérgete en el mundo apasionante de las carreras de caballos y siente la emoción de poseer o patrocinar estas magníficas
//  criaturas! 

// [¡Empieza Ahora!](#)

// ## Beneficios para los Dueños de Caballos
// - **Participación en Carreras Regulares y Oficiales:** Emociónate con cada carrera.
// - **Premios por Clasificaciones:** Gana recompensas increíbles.
// - **Cría de NFTs Únicos:** Crea caballos únicos y poderosos.
// - **Comisiones por Patrocinio:** Aumenta tus ingresos.
// - **Minería Diaria de Tokens:** Obtén tokens diariamente.
// - **Acceso a Eventos Especiales:** Participa en promociones exclusivas.
// - **Mercados de NFTs y Tokens:** Comercio dinámico de activos.
// - **¡Y muchas más oportunidades emocionantes!**

// ## Beneficios para los Patrocinadores
// - **Ganancias por Patrocinio:** Gana con cada boleto vendido.
// - **Recompensas Directas en Tokens:** Recibe tokens y criptomonedas.
// - **Premios Adicionales:** Gana aun cuando tu favorito no llegue en primer lugar.
// - **Acceso a Eventos Exclusivos:** Participa en eventos únicos.
// - **Mercados de NFTs y Tokens:** Comercio activo y rentable.
// - **¡Y muchas más oportunidades de promoción y participación!**

// [Únete a la Carrera](#)

// ## Testimonios de Jugadores
// *"¡BHR me ha permitido combinar mi amor por los caballos con la emoción de las carreras y los beneficios del blockchain!"* - Usuario Satisfecho

// ## Visualiza la Acción
// ![Carreras de Caballos](path_to_image_or_video)

// ### ¡Únete hoy y comienza tu viaje en el emocionante mundo de las carreras de caballos en la blockchain Hive! ¡Nos vemos en la línea de salida!

// [¡Regístrate Ahora y Recibe un Caballo NFT Gratis!](#)
