import React from 'react';

const SelectedItems = ({ selectedItems, handleRemoveFromCart, handleBuy }) => {
  const totalPrice = selectedItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
  console.log("SELCT",selectedItems)
  return (
    <div className="selected-items">
    
      <h4 style={{ color: "white" }}>My Items</h4>
         {selectedItems.map((item) => (
        <div key={item.id}>
          <span>{item.name}/</span>
          <span>{typeof item.price === 'number' ? item.price.toFixed(3) : parseFloat(item.price).toFixed(3)}</span>
          <button onClick={() => handleRemoveFromCart(item.id)}>Remove</button>
        </div>
      ))}
     {selectedItems.length > 0 && (
  <p>Total Price: {totalPrice.toFixed(3)}{selectedItems[0].symbol}</p>
)}
      <button onClick={handleBuy} className="custom-button">Cancel Sell</button>

    </div>
  );
};

export default SelectedItems;

