import { useModal } from "../../hooks/useModals.jsx";

import Modal from "../../generals/modals/Modal.jsx";
import LoginForm from"./LoginForm.jsx"
import "./ButtonLogin.css"
const user=localStorage.getItem("user")
const Modals = () => {
const [isOpenModal1, openModal1, closeModal1] = useModal(false);

const handleLogout = (e)=>{
localStorage.removeItem("user");
localStorage.removeItem("auth");
window.location.reload(false);
 }

  return (
    <div className="button" htmlFor="toggle">
     <label className="button" htmlFor="toggle">
   {user ? (
     <div>
        <input id="toggle" type="checkbox"  defaultChecked onClick={handleLogout}/>
        <span className="slider"></span>
     </div>
      ) : (
          <div>
        
        <input id="toggle" type="checkbox" onClick={openModal1}/>
        <span className="slider"></span>
  </div>
      )}
  

   <Modal isOpen={isOpenModal1} closeModal={closeModal1}>
        <LoginForm/>
      </Modal>
  </label>
  
   </div>

  )
}

export default Modals;
