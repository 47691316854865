import React from 'react';

import { useContext } from 'react';
// import{NavLink} from'react-router-dom'
import { useTranslation } from 'react-i18next';

import './nav_bar_nft.css';
// import LanguageContext from '../../../../../context/LanguageContext';
import ThemeContext from '../../../../../context/ThemeContext';
// import Icon from './../../../../../generals/svgiconos/svgiconosenreact.jsx';
import caballo from './../../../../../../assets/gif/bottons/caballo.jpg';
import manzana from './../../../../../../assets/gif/bottons/manzana.jpg';
import trailer from './../../../../../../assets/gif/bottons/trailer.jpg';
import Cofresespeciales from"./../../../../../fetch_nft/cofresespeciales.jsx"


export default function Navbar_nft(props) {
  const { theme, handleTheme } = useContext(ThemeContext);
  const { handleNavClick } = props;
 const { t } = useTranslation();
  const handleClick = (event, route) => {
    event.preventDefault();
    handleNavClick(route);
  }

  return (
    <nav className={theme}>
      <div className={`navbar-container ${theme}`}>
      <Cofresespeciales/>
        <ul className="navbar">
        <p>{t('inventario.inventario')}</p>
          <li className="navbar-item">  
            <a href="/caballos" onClick={(event) => handleClick(event, '/caballos')}>
              <img src={caballo} alt="boton"  className="botton-navbar active" />
            </a>
          </li>
          <li className="navbar-item">
            <a href="/nft_u" onClick={(event) => handleClick(event, '/nft_u')}>       
              <img src={manzana} alt="boton"  className="botton-navbar active" />
            </a>
          </li>
          <li className="navbar-item">
            <a href="/nft_e" onClick={(event) => handleClick(event, '/nft_e')}>
              <img src={trailer} alt="boton"  className="botton-navbar active" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
