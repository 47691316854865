// Saludo.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Mante from '../../../../assets/gif/mantenimiento/mante.gif';
import './Mantenimiento.css';

const Saludo = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="pancarta-container">
        <h1 className="pancarta-text">{t('mantenimiento.forzado')}</h1>
      </div>
      <img src={Mante} alt="Imagen de ejemplo" />
    </div>
  );
};

export default Saludo;
