import { useContext } from "react";

import { useTranslation } from 'react-i18next';
import ThemeContext from "../context/ThemeContext";
import "./fotter.css"
import BHRLogo from"./../../assets/logoblockhorseracing/logo2ra2s.png"

const FooterContext = () => {
  const { theme } = useContext(ThemeContext);
   const { t } = useTranslation();


  return (
    <footer className={theme}>
    <div className="container-footer">
    <a href="#" target="_blank"><img className="logo"  src={BHRLogo} alt="Kings" /></a>

    </div> </footer>
  );
};

export default FooterContext;
