import React, { useState, useEffect } from 'react';
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';
import './racesin.css';
import './racesin2.css';
import Loader from './../../../generals/loders/Loader.jsx';
import ModalinFoRace from './components/modal/ModalinforRace.jsx';
import { useTranslation } from 'react-i18next';

const RacesComponent = () => {
  const [isOpenInf, setIsOpenInf] = useState(false);
  const [races, setRaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apuestasUser, setApuestasUser] = useState({});
  const [selectedRace, setSelectedRace] = useState({ raceid: '', name: '' });
  const [filterByDate, setFilterByDate] = useState(true); // Estado para alternar entre filtrar por fecha y filtrar por ID de carrera
  const [filterRacetype, setFilterRacetype] = useState(true); // Estado para alternar entre filtrar por fecha y filtrar por ID de carrera

  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Actualiza el tiempo cada minuto

    return () => clearInterval(interval);
  }, []);

  // Restar la hora actual al tiempo de salida de la carrera para obtener el tiempo restante
  const calculateTimeRemaining = (startTime) => {
    const start = new Date(startTime);
    const difference = start.getTime() - currentTime.getTime();
    const minutesRemaining = Math.floor(difference / (1000 * 60)); // Obtener minutos restantes

    return minutesRemaining >= 0 ? `🕒 ${minutesRemaining} min` : '';
  };

  const openModalInf = (raceid, name) => { 
    setIsOpenInf(true);
    setSelectedRace({ raceid, name });
  };

  const closeModalInf = () => setIsOpenInf(false);

  const toggleFilter = () => {
    setFilterByDate(!filterByDate);
    setLoading(true); // Volvemos a cargar las carreras al cambiar el filtro
    fetchRaces(); // Volvemos a cargar las carreras al cambiar el filtro
  };

 const toggleAlertaSoon = () => {
   // setFilterRacetype(!filterRacetype);
    // Mostrar alerta personalizada
    const alertDiv = document.createElement('div');
    alertDiv.className = 'custom-alert';
    alertDiv.textContent = t('racein.OficialEspera'); 
    document.body.appendChild(alertDiv);
    // Eliminar la alerta después de unos segundos
    setTimeout(() => {
        document.body.removeChild(alertDiv);
    }, 3000);
};
  const openModalFromURL = () => {
    const fragment = decodeURIComponent(window.location.hash);
    if (fragment) {
      const params = new URLSearchParams(fragment.substring(1));
      const raceId = params.get('raceId');
      const name = params.get('name');
      setSelectedRace({ raceid: raceId, name: name });
      setIsOpenInf(true);
    }
  };

  const fetchRaces = async () => {
    try {
      let url = `${linkApp}/race?page=1`;

      const response = await fetch(url);
      const data = await response.json();

      let sortedRaces;
      if (filterByDate) {
        sortedRaces = data.body.sort((a, b) => b.raceid - a.raceid);
      } else {
        sortedRaces = data.body.sort((a, b) => new Date(b.tiempodesalida) - new Date(a.tiempodesalida));
      }
      
      setRaces(sortedRaces);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener las carreras:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRaces();
    openModalFromURL();
    window.addEventListener('hashchange', openModalFromURL); // Para manejar cambios en el hash de la URL
    return () => window.removeEventListener('hashchange', openModalFromURL);
  }, [filterByDate]); // Volvemos a cargar las carreras al cambiar el filtro

  useEffect(() => {
    const fetchApuestas = async () => {
      let account = localStorage.getItem("user");
      try {
        const response = await fetch(`${linkApp}/bets?usuario=${account}`);
        const data = await response.json();

        if (data.error) {
          // console.log("DATAERROR", data.error);
        } else {
          // console.log("DATOS DE APUESTAS", data.body); // Agregamos este console.log
          setApuestasUser(data.body);
        }
      } catch (error) {
        console.log('Error al cargar las Apuestas.');
      }
    };

    fetchApuestas();
    // Programar la próxima llamada a fetchApuestas después de 30 segundos
    const fetchInterval = setInterval(fetchApuestas, 320000);

    // Devolvemos una función de limpieza para detener el intervalo cuando el componente se desmonta
    return () => clearInterval(fetchInterval);
  }, []);

  function getStatusColor(status) {
    switch (status) {
      case 'abierta':
        return 'gold'; // Color para el estado 'abierta'
      case 'en_proceso':
        return 'red'; // Color para el estado 'en_proceso'
      // Agrega más casos para otros estados si es necesario
      default:
        return 'black'; // Color predeterminado
    }
  }

  return (
    <div className="carreras-container">
      <div className="race-menu-nav">
        <h1 className="pancarta-text">{t('racein.carrera')}</h1>
      </div>
      <div className="race-menu-nav">
        <button onClick={toggleFilter} className="filtro-race-button">
          {filterByDate ? t('racein.filterByDate') : t('racein.filterByRaceId')}
        </button>
        <button onClick={toggleAlertaSoon} className="filtro-race-button">
          {filterRacetype ? t('racein.Oficiales') : t('racein.Comunes')}
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <ul>
          {Array.isArray(races) && races.length > 0 ? (
            races.map((race) => {
              const raceId = race.raceid;
              const isRaceInUser = Object.values(apuestasUser).some(item => item.race === raceId);
              return (
                <li key={race._id} onClick={() => openModalInf(race.raceid, race.name)} className={` ${isRaceInUser ? 'race-highlight' : 'race-baselight'}`}>
                  <h2><span className="raceid">{race.raceid}</span> <h6><span className="status" style={{ color: getStatusColor(race.status_superior) }}>
                  {calculateTimeRemaining(race.tiempodesalida)}</span></h6></h2>
                  <span className="name">{race.name}</span>
                  <span className="status" style={{ color: getStatusColor(race.status_superior) }}>
                    <h2>{race.registrados.length}/{race.maxParticipantes}  <span className="raceid" style={{ color: isRaceInUser ? 'darkgoldenrod' : 'inherit' }}>{isRaceInUser ? '$' : ''}</span></h2>
                    {race.status_superior === 'abierta' ? 'Open' : race.status_superior === 'en_proceso' ? 'In Process' : race.status_superior}
                  </span>
                  <span className="stado">
                    {race.status_superior === 'abierta' ? (
                      <div className="circle green"></div>
                    ) : race.status_superior === 'en_proceso' ? (
                      <div className="circle red"></div>
                    ) : (
                      <div className="semaforo-circle black"></div>
                    )}
                  </span>
                </li>
              );
            })
          ) : (
            <li>{t('resultados.nohaydato')}</li>
          )}
        </ul>
      )}
      {isOpenInf && (
        <ModalinFoRace isOpen={isOpenInf} closeModalInf={closeModalInf} raceId={selectedRace} />
      )}
    </div>
  );
};

export default RacesComponent;
