import React from "react";
import './cofreclaim.css'
import { useTranslation } from 'react-i18next';

const images = {
  1: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png'],
  2: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  3: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  4: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  5: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  6: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png','https://www.blockhorseracing.com/image/utility_nfts/carrot.png'],
  7: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png','https://www.blockhorseracing.com/image/utility_nfts/aple.png'],
  8: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/time.png','https://www.blockhorseracing.com/image/utility_nfts/time.png','https://www.blockhorseracing.com/image/utility_nfts/time.png','https://www.blockhorseracing.com/image/utility_nfts/time.png','https://www.blockhorseracing.com/image/utility_nfts/time.png','https://www.blockhorseracing.com/image/utility_nfts/time.png'],
  9: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/bit.png'],
  10: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/bit.png'],
  11: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/blinkers.png'],
  12: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/corrective-sleeves.png'],
  13: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/horsebells.png'],
  14: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/martingale.png'],
  15: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/noseband.png'],
  16: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/race-plates.png'],
  17: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/imp/saddles.png'],
  18: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png'],
  19: ['https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png','https://www.blockhorseracing.com/image/utility_nfts/hay.png'],
  20: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png'],
  21: ['https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png','https://www.blockhorseracing.com/image/utility_nfts/alfa.png']
 
};

function getImages(valor) {
  return images[valor] || [];
}

function Divs({ cofresProcesando }) {
   const { t } = useTranslation();
  return (
    <>
      {Object.keys(cofresProcesando).map((divId) => {
         
        const valor = cofresProcesando[divId];

        const images = getImages(valor);
        const numeroCofre = parseInt(divId) + 1;

        return (<>
          <div key={divId} className="modales-conten">
            <h3>{t('cofres.cofres')}  {numeroCofre -1}  </h3>
            </div>
            <div key={divId} className="modales-conten">
            {images.map((rutaImagen) => (
              <img key={rutaImagen} src={rutaImagen} alt="" />
            ))}
          </div>
          </>
        );
      })}
    </>
  );
}

function Modal({ isOpen, onClose, cofresProcesando }) {
  console.log('Modal renderizado');
  console.log("Y",isOpen);
  
  if (!isOpen) return null;

  return (
    <div className="modales">
      <div className="modales-content">
        <button onClick={onClose}><h1>X</h1></button>
        <Divs cofresProcesando={cofresProcesando} />
      </div>
    </div>
  );
}

export default Modal;



