import React from 'react';
import './Ultimas6.css';

const UltimasSeisTabla = ({ ultimasSeis }) => (
  <div id="races_runs-ultimasSeisTabla">
    <table>
      <thead>
        <tr>
          <th>Race</th>
          <th>Place</th>
          <th>Hash</th>
          <th>First prize</th>
          <th>Second prize</th>
        </tr>
      </thead>
      <tbody>
        {ultimasSeis.map((carrera) => (
          <tr key={carrera.id}>
            <td>{carrera.race}</td>
            <td>{carrera.Lugar}</td>
            <td>
              <a href={`https://hiveblocks.com/tx/${carrera.trx_race}`} target="_blank" rel="noopener noreferrer">
                hash
              </a>
            </td>
            <td>
              <h2>{carrera.premio}{carrera.symbol}</h2>
              <a href={`https://hiveblocks.com/tx/${carrera.trx_Premio1}`} target="_blank" rel="noopener noreferrer">
                hash
              </a>
            </td>
            <td>
              <h2>{carrera.premio2}{carrera.symbol_2}</h2>
              <a href={`https://hiveblocks.com/tx/${carrera.trx_Premio2}`} target="_blank" rel="noopener noreferrer">
                hash
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default UltimasSeisTabla;
